import React, { useEffect, useState } from "react";
import { NoRecordsFound } from "../../../../../../components";
import {
    addTimeZone,
    addTimeZoneToFullDate,
    addTimeZoneToFullDate2,
    isItSpecified
} from "../../../../../../components/Utility";
import { useLocalization } from "../../../../../../hooks/Utils/useLocalization";
import useAuth from "../../../../../../hooks/Log/useAuth";
import { useParams } from "react-router-dom";
import { GetMemberName } from "../../../../../../services/Member/MemberService";

const MemberNoteTable = ({ data }) => {
    const strings = useLocalization();
    const { employee } = useAuth();
    const { memberId } = useParams() || false;

    const [memberName, setMemberName] = useState("");

    const getMemberName = async (memberId) => {
        const result = await GetMemberName(memberId);

        if (result.status === 200) {
            setMemberName(memberName => result.content);
        }
    }

    const processTextWithTime = (text) => {
        if (text) {
            text = text.replace(/#oldTime#(.*?)#oldTime#/g, (_, time) => addTimeZone(time, employee.TimeZone));
            text = text.replace(/#newTime#(.*?)#newTime#/g, (_, time) => addTimeZone(time, employee.TimeZone));

            return text;
        } else {
            return null;
        }
    }

    useEffect(() => {
        getMemberName(memberId);
    }, [memberId]);

    return (
        <table className="min-w-full leading-normal text-header">
            <thead>
                <tr>
                    <th className="th"><p className="min-w-max">{strings.table.student_note}</p></th>
                    <th className="th"><p className="min-w-max">{strings.table.performing_person}</p></th>
                    <th className="th"><p className="min-w-max">{strings.table.operation_date}</p></th>
                </tr>
            </thead>
            <tbody>
                {data.length > 0 ? (
                    data.map((form, index) => (
                        <tr key={index} className="bg-white hover:bg-[#f0f0f0]">
                            <td className="td h-[60px] align-middle">
                                <p
                                    className="max-w-max"
                                    dangerouslySetInnerHTML={{ __html: isItSpecified(processTextWithTime(form.formNote), strings.errors.it_is_not_specified) }}
                                />
                            </td>
                            <td className="td h-[60px] align-middle">
                                <p className="min-w-max">{!form.employeeName ? memberName : form.employeeName}</p>
                            </td>
                            <td className="td h-[60px] align-middle">
                                <p className="min-w-max">{addTimeZoneToFullDate2(form.formDate, employee.TimeZone)}</p>
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr className="bg-white hover:bg-[#f0f0f0]">
                        <td className="td" colSpan={3}>
                            <NoRecordsFound />
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    );
};

export default MemberNoteTable;
