import { PageTitle } from "../../components";
import { useLocalization } from "../../hooks/Utils/useLocalization";
import React from "react";
import useAuth from '../../hooks/Log/useAuth';
import NotAuthorized from "../../components/NotAuthorized";

const AppCommentDashboard = () => {

    const strings = useLocalization();
    const { checkPermission, getPermission, employeeTypeId } = useAuth();
    const roles = getPermission();
    const permisson = checkPermission(roles, employeeTypeId);

    return (
        <>
            <PageTitle title={strings.sidebar.product_report.app_comment_dashboard} />

            {permisson ? (
                <div className="flex justify-center mt-5">
                    <iframe title="product" width="1725" height="1725" src="https://lookerstudio.google.com/embed/reporting/52def718-77c8-4408-b92f-0cb7c10e57f3/page/t3C6D" frameBorder="0" allowFullScreen></iframe>
                </div>
            ) : (<NotAuthorized />)}

        </>
    )
}

export default AppCommentDashboard; 