import React, { useEffect, useRef, useState } from 'react';
import { Loading } from '../../../../../../components';
import { GetMemberAiAnalysis, GetMemberWeeklyAiAnalysis } from '../../../../../../services/Task/TaskService';
import { useLocalization } from '../../../../../../hooks/Utils/useLocalization';
import { ServiceErrorMessages } from '../../../../../../consts/Constants';
import { Ai } from '../../../../../../assets/svg';

const TaskAiAnalysis = (props) => {

    const { data } = props;

    const firstVisit = useRef(true);
    const strings = useLocalization();

    const [aiAnalysisData, setAiAnalysisData] = useState("");
    const [aiAnalysisErrorMessage, setAiAnalysisErrorMessage] = useState("");
    const [aiAnalysisLoading, setAiAnalysisLoading] = useState(false);
    const [aiAnalysisTryAgain, setAiAnalysisTryAgain] = useState(false);

    const [weeklyAiAnalysisData, setWeeklyAiAnalysisData] = useState("");
    const [weeklyAiAnalysisErrorMessage, setWeeklyAiAnalysisErrorMessage] = useState("");
    const [weeklyAiAnalysisLoading, setWeeklyAiAnalysisLoading] = useState(false);
    const [weeklyAiAnalysisTryAgain, setWeeklyAiAnalysisTryAgain] = useState(false);

    const getMemberAiAnalysis = async (taskId) => {
        setAiAnalysisLoading(true);
        setAiAnalysisData("");
        setAiAnalysisTryAgain(false);
        setAiAnalysisErrorMessage("");

        const result = await GetMemberAiAnalysis(taskId);

        if (result.status === 200)
            setAiAnalysisData(result.content.replaceAll("\n", "<br>"));
        else {
            if (ServiceErrorMessages.TASK_NOT_FOUND === result.content)
                setAiAnalysisErrorMessage(strings.member.member_detail.tasks.ai_analysis.task_not_found);
            else if (ServiceErrorMessages.TASK_TYPE_DOES_NOT_SUPPORT === result.content)
                setAiAnalysisErrorMessage(strings.member.member_detail.tasks.ai_analysis.task_type_does_not_support);
            else if (ServiceErrorMessages.PROMPT_NOT_FOUND === result.content)
                setAiAnalysisErrorMessage(strings.member.member_detail.tasks.ai_analysis.prompt_not_found);
            else if (ServiceErrorMessages.TERM_ID_IS_REQUIRED === result.content)
                setAiAnalysisErrorMessage(strings.member.member_detail.tasks.ai_analysis.term_id_required);
            else if (ServiceErrorMessages.ANY_CAPTION_NOT_FOUND === result.content)
                setAiAnalysisErrorMessage(strings.member.member_detail.tasks.ai_analysis.any_caption_not_found);
            else if (ServiceErrorMessages.CAPTIONS_NOT_MERGED === result.content)
                setAiAnalysisErrorMessage(strings.member.member_detail.tasks.ai_analysis.captions_not_merged);
            else if (ServiceErrorMessages.AI_ANALYSIS_GAVE_ERROR === result.content) {
                setAiAnalysisErrorMessage(strings.member.member_detail.tasks.ai_analysis.ai_analysis_gave_error);
                setAiAnalysisTryAgain(true);
            } else {
                setAiAnalysisErrorMessage(strings.member.member_detail.tasks.ai_analysis.not_found_error);
                setAiAnalysisTryAgain(true);
            }
        }

        setAiAnalysisLoading(false);
    }

    const getMemberWeeklyAiAnalysis = async (taskId) => {
        setWeeklyAiAnalysisLoading(true);
        setWeeklyAiAnalysisData("");
        setWeeklyAiAnalysisTryAgain(false);
        setWeeklyAiAnalysisErrorMessage("");

        const result = await GetMemberWeeklyAiAnalysis(taskId);

        if (result.status === 200) {
            setWeeklyAiAnalysisData(result.content.replaceAll("\n", "<br>"));
        }
        else {
            if (ServiceErrorMessages.MEMBER_TASK_AI_ANALYSIS_NOT_FOUND === result.content)
                setWeeklyAiAnalysisErrorMessage(strings.member.member_detail.tasks.weekly_ai_analysis.not_found_error);
            else if (ServiceErrorMessages.PROMPT_NOT_FOUND === result.content)
                setWeeklyAiAnalysisErrorMessage(strings.member.member_detail.tasks.ai_analysis.prompt_not_found);
            else if (ServiceErrorMessages.MEMBER_TASK_WEEKLY_AI_RESULTS_NOT_FOUND === result.content)
                setWeeklyAiAnalysisErrorMessage(strings.member.member_detail.tasks.weekly_ai_analysis.ai_results_not_found);
            else if (ServiceErrorMessages.AI_ANALYSIS_RESULT_LIST_NOT_MERGED === result.content)
                setWeeklyAiAnalysisErrorMessage(strings.member.member_detail.tasks.weekly_ai_analysis.ai_results_not_merged);
            else if (ServiceErrorMessages.AI_ANALYSIS_GAVE_ERROR === result.content) {
                setWeeklyAiAnalysisErrorMessage(strings.member.member_detail.tasks.ai_analysis.ai_analysis_gave_error);
                setWeeklyAiAnalysisTryAgain(true);
            } else {
                setWeeklyAiAnalysisErrorMessage(strings.member.member_detail.tasks.ai_analysis.not_found_error);
                setWeeklyAiAnalysisTryAgain(true);
            }
        }
        setWeeklyAiAnalysisLoading(false);
    }

    useEffect(() => {
        //Modal ile açıldığı için olabilir. Bazen servise sürekli istek atıyor. O sebeple ilk ziyaretten sonra isteği durduruyoruz.
        if (firstVisit.current) {
            if (data && data.taskId) {
                data?.showAiAnalysis && getMemberAiAnalysis(data.taskId);
                data?.showWeeklyAiAnalysis && getMemberWeeklyAiAnalysis(data.taskId);
            }
            firstVisit.current = false;
        }

    }, [])

    return (
        <>
            {data?.showAiAnalysis &&
                <div className="w-full">
                    <div className='flex items-center th'>
                        <img src={Ai} alt="ai" className='w-6 h-6' />
                        <p className="ml-2 font-bold">
                            {strings.member.member_detail.tasks.ai_mentoring}
                        </p>
                    </div>
                    <div className='md:h-[145px] md:overflow-y-scroll mt-1'>
                        <div className="py-4 text-center">
                            {aiAnalysisLoading && <Loading />}

                            {(aiAnalysisData.length > 0 && !aiAnalysisLoading)
                                ?
                                <div className="px-5 border-[#ebedf2] bg-white flex flex-col gap-3">
                                    <div className="flex items-center gap-3">
                                        <p
                                            className="max-w-max text-start"
                                            dangerouslySetInnerHTML={{
                                                __html: aiAnalysisData
                                            }}
                                        />
                                    </div>
                                </div>
                                : (
                                    <div className="flex flex-col gap-2 items-center justify-center">
                                        <p className="ml-2 text-center">{aiAnalysisErrorMessage}</p>
                                        {aiAnalysisTryAgain &&
                                            <button
                                                type="button"
                                                className=" flex items-center gap-1 ml-2 border border-dark-gray hover:bg-card h-6 max-w-max px-3 rounded-[10px]"
                                                onClick={() => getMemberAiAnalysis(data?.taskId)}
                                            >
                                                <span className="material-symbols-outlined text-[16px] font-bold">
                                                    sync
                                                </span>
                                                {strings.button.try_again}
                                            </button>
                                        }
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
            }

            {data?.showWeeklyAiAnalysis &&
                <div className="w-full">
                    <div className='flex items-center th'>
                        <img src={Ai} alt="ai" className='w-6 h-6' />
                        <p className="ml-2 font-bold">
                            {strings.member.member_detail.tasks.ai_progress}
                        </p>
                    </div>
                    <div className='md:h-[145px] md:overflow-y-scroll mt-1'>
                        <div className="py-4 text-center">
                            {weeklyAiAnalysisLoading && <Loading />}

                            {(weeklyAiAnalysisData.length > 0 && !weeklyAiAnalysisLoading)
                                ?
                                <div className="px-5 border-[#ebedf2] bg-white flex flex-col gap-3">
                                    <div className="flex items-center gap-3">
                                        <p
                                            className="max-w-max text-start"
                                            dangerouslySetInnerHTML={{
                                                __html: weeklyAiAnalysisData
                                            }}
                                        />
                                    </div>
                                </div>
                                : (
                                    <div className="flex flex-col gap-2 items-center justify-center">
                                        <p className="ml-2 text-center">{weeklyAiAnalysisErrorMessage}</p>
                                        {weeklyAiAnalysisTryAgain &&
                                            <button
                                                type="button"
                                                className=" flex items-center gap-1 ml-2 border border-dark-gray hover:bg-card h-6 max-w-max px-3 rounded-[10px]"
                                                onClick={() => getMemberWeeklyAiAnalysis(data?.taskId)}
                                            >
                                                <span className="material-symbols-outlined text-[16px] font-bold">
                                                    sync
                                                </span>
                                                {strings.button.try_again}
                                            </button>
                                        }
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default TaskAiAnalysis;