import classNames from "classnames";
import parsePhoneNumberFromString from "libphonenumber-js";
import {useEffect, useRef, useState} from "react";
import ReactIntlTelInput from 'react-intl-tel-input-v2';
import {Link, useParams} from "react-router-dom";
import '../../../../../assets/css/intl-tel-input.css';
import {
  DropdownSearch,
  Input,
  LoadingSpinner,
  Modal,
  PageTitle,
  SearchBar,
  showTopAlert
} from '../../../../../components';
import {deleteSpacesInThePhoneNumber, validateEmail, validPhoneNumber} from "../../../../../components/Utility";
import {ReferenceSourceList, ServiceErrorMessages} from "../../../../../consts/Constants";
import useAuth from "../../../../../hooks/Log/useAuth";
import useMemberBrand from "../../../../../hooks/Member/useMemberBrand";
import {useLocalization} from "../../../../../hooks/Utils/useLocalization";
import {url} from "../../../../../routes/utility";
import {IsMemberInPool} from "../../../../../services/Member/MemberPoolService";
import {CreateReferenceService, ReferenceMemberList} from "../../../../../services/Member/MemberReferenceService";
import SelectReferenceModal from "./components/SelectReferenceModal";
import ReferenceInfo from "./ReferenceInfo";
import {NoteCategories, Notes} from "../../../../../consts/Notes";
import useMemberName from "../../../../../hooks/Member/useMemberName";
import useCreateMemberNote from "../../../../../hooks/Log/useCreateMemberNote";
import {useSelector} from "react-redux";

const CreateReference = () => {

  const strings = useLocalization();

  const { memberId } = useParams() || false;
  const { employee } = useAuth();
  const language = useSelector(state => state.localizationStore.language);
  const memberNote = useCreateMemberNote();
  const brandId = useMemberBrand(memberId);
  const memberName = useMemberName(memberId);

  const fullNameRef = useRef();
  const emailRef = useRef();
  const fullNameErrorRef = useRef();
  const emailErrorRef = useRef();
  const phoneNumberErrorRef = useRef();
  const referenceSourceErrorRef = useRef();

  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumberValue, setPhoneNumberValue] = useState({ iso2: 'tr', dialCode: '90', phone: '' });
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('');
  const [addedReference, setAddedReference] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const [isRedirectMemberDetailPage, setIsRedirectMemberDetailPage] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [selectReferenceData, setSelectReferenceData] = useState([]);
  const [selectedReference, setSelectedReference] = useState({});

  const firstReferenceSource = { id: -1, name: strings.member.member_detail.create_reference.select_reference_source };
  const [selectedReferenceSource, setSelectedReferenceSource] = useState(firstReferenceSource);
  const referenceSourceList = [firstReferenceSource ,...ReferenceSourceList.map(ref => ({ id: ref.id, name: ref[language], content: ref.content }))];

  let inputProps = { placeholder: phoneNumberValue.iso2 === 'tr' ? '5XX XXX XX XX' : strings.employee.create_employee.enter_your_phone_number };
  const intlTelOpts = { preferredCountries: ['tr', 'az', 'sa'] };

  const phoneNumberHandle = val => {
    if (val.dialCode !== phoneNumberValue.dialCode) {
      setPhoneNumberValue({ iso2: val.iso2, dialCode: val.dialCode, phone: val.phone });
    } else {
      setPhoneNumberValue(val);
    }
  };

  const isMemberInPool = async (memberId) => {
    const result = await IsMemberInPool(memberId);
    if (result.status === 200) {
      setIsRedirectMemberDetailPage(redirectMemberDetailPage => JSON.parse(result.content));
    }
  }

  const referenceMemberList = async (searchText) => {
    setLoading(loading => true);

    const model = {
      searchText: searchText,
      brandId: brandId,
    }

    const result = await ReferenceMemberList(model);

    if (result.status === 200) {
      let resultContent = JSON.parse(result.content);
      setSelectReferenceData(selectReferenceData => resultContent)
      setModal(true);
      setModalContent({ element: "selectReference", data: resultContent });
    } else {
      showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
    }
    setLoading(loading => false);
  }

  const submitHandle = async e => {
    e.preventDefault()

    let valid = true

    //Hataları sıfırlıyoruz.
    fullNameErrorRef.current.innerText = '';
    emailErrorRef.current.innerText = '';
    phoneNumberErrorRef.current.innerText = '';
    referenceSourceErrorRef.current.innerText = '';

    if (fullName === '') {
      valid = false;
    }

    if (email.length !== 0 && !validateEmail(email)) {
      valid = false;
    }

    if (phoneNumberValue.phone === '' || !validPhoneNumber(formattedPhoneNumber)) {
      valid = false;
    }

    if(selectedReferenceSource.id === -1){
      valid = false;
    }

    if (valid) {

      setIsDisabled(isDisabled => true)

      const model = {
        fullname: fullName,
        email: email,
        phonenumber: formattedPhoneNumber,
        employeeId: employee.EmployeeId,
        referrerMemberId: memberId,
        notChangeReferrer: true,
        referenceSource: selectedReferenceSource.content
      }

      const result = await CreateReferenceService(model);

      if (result.status === 200) {
        let resultContent = JSON.parse(result.content);
        setAddedReference(addedReference => resultContent);

        setIsRedirectMemberDetailPage(isRedirectMemberDetailPage => false);
        isMemberInPool(resultContent.memberId);

        //NOTED
        memberNote.useCreateMemberNote(
            memberId,
            Notes.CREATE_REFERENCE_NOTE
                .replace("{consultantName}", employee?.FullName)
                .replace("{referenceMemberName}", `${fullName} - ${formattedPhoneNumber}`)
                .replace("{refererMemberName}", memberName),
            NoteCategories.STUDENT_PAGE_REFERENCE
        );

        //Ekleme işlemi başarılıysa alanları temizlelmiz gerekiyor
        setFullName(fullName => "");
        setEmail(email => "");
        setPhoneNumberValue({ iso2: 'tr', dialCode: '90', phone: '' });
        setFormattedPhoneNumber(formattedPhoneNumber => "");
        setSelectedReferenceSource(firstReferenceSource);

        fullNameRef.current.value = "";
        emailRef.current.value = "";
        referenceSourceErrorRef.current.value = '';

        showTopAlert(strings.member.member_detail.create_reference.success_message, "success");
      } else {

        if (ServiceErrorMessages.EXISTING_REFERENCE === result.content) {
          showTopAlert(strings.member.member_detail.create_reference.existing_reference_error_message, "error");
        } else if (ServiceErrorMessages.REFERRER_MUST_BE_DIFFERENT_PERSON === result.content) {
          showTopAlert(strings.member.member_detail.create_reference.referrer_must_be_different_person_error_message, "error");
        } else {
          showTopAlert(strings.errors.an_error_occurred, "error");
        }
      }

      setIsDisabled(isDisabled => false)

    } else {
      //Hataları gösteriyoruz.
      fullNameErrorRef.current.innerText = fullName.length === 0 ? strings.errors.full_name_null_error : '';
      emailErrorRef.current.innerText = (email.length !== 0 && !validateEmail(email)) ? strings.errors.email_format_error : '';
      referenceSourceErrorRef.current.innerText = selectedReferenceSource.id === -1 ? strings.errors.reference_source_null_error : '';
      //telefon numarasını boş girerse null error döndürüyor
      //telefon numarası formatını hatalı girerse format error döndürüyor
      phoneNumberErrorRef.current.innerText = phoneNumberValue.phone === '' ? strings.errors.phone_number_null_error
        : (!validPhoneNumber(formattedPhoneNumber)
          ? strings.errors.phone_number_format_error
          : '')
    }
  }

  //input değişiklikleri bu alandan yönetiliyor
  const changeValue = (value) => {
    setSearchText(searchText => value);
  }

  const closeModal = () => {
    setModal(modal => false);
  }

  const parsePhoneNumber = (phoneNumber) => {
    const parsedNumber = parsePhoneNumberFromString(phoneNumber);

    if (!parsedNumber) {
      console.error("Telefon numarası geçersiz");
      return null;
    }

    const country = parsedNumber.country;
    const dialCode = parsedNumber.countryCallingCode;
    const phone = parsedNumber.nationalNumber;

    return {
      iso2: country.toLowerCase(),
      dialCode: dialCode,
      phone: phone
    };
  };

  useEffect(() => {
    const formattedPhoneNumber = deleteSpacesInThePhoneNumber(phoneNumberValue.phone, phoneNumberValue.dialCode);
    phoneNumberValue.phone !== '' && setFormattedPhoneNumber(formattedPhoneNumber);
  }, [phoneNumberValue])

  useEffect(() => {
    if (Object.keys(selectedReference).length > 0) {
      let parsedPhoneNumber = parsePhoneNumber(selectedReference.phoneNumber);
      setPhoneNumberValue(() => ({ iso2: parsedPhoneNumber.iso2, dialCode: parsedPhoneNumber.dialCode, phone: parsedPhoneNumber.phone }));

      setEmail(selectedReference.email || "")
      setFullName(selectedReference.fullName || "")
    }
  }, [selectedReference])

  useEffect(() => {
    brandId !== -1 && searchText !== "" && referenceMemberList(searchText)
  }, [searchText, brandId])

  return (
    <>
      <PageTitle title={strings.member.member_detail.header.content.create_reference} />
      <div className="p-3 flex flex-col lg:flex-row gap-4">
        <ReferenceInfo memberId={memberId} />
        <div className="lg:w-[65%]">
          <div className="max-w-xs lg:max-w-lg mx-auto">
            <SearchBar
              changeValue={changeValue}
              placeHolder={strings.member.member_detail.create_reference.search_text}
              isDisabled={loading}
              isClear={false}
            />
          </div>
          <form onSubmit={submitHandle} className="max-w-xs lg:max-w-lg mx-auto">
            <div className="flex flex-col px-[10px] pb-0">
              <label htmlFor="username">
                {strings.form_info.full_name}{" "}
                <span className="text-red">*</span>
              </label>
              <Input
                ref={fullNameRef}
                id="username"
                type="text"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
              <span
                ref={fullNameErrorRef}
                className="text-danger field-validation-valid px-[10px]"
              ></span>
            </div>
            <div className="mt-5">
              <div className="flex flex-col px-[10px] pb-0">
                <label htmlFor="email">
                  {strings.form_info.email}
                </label>
                <Input
                  ref={emailRef}
                  id="email"
                  type="text"
                  value={email}
                  placeholder="example@example.com"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <span
                  ref={emailErrorRef}
                  className="text-danger field-validation-valid px-[10px]"
                ></span>
              </div>
            </div>
            <div className="mt-5">
              <div className="flex flex-col px-[10px] pb-0">
                <label htmlFor="phone">
                  {strings.form_info.phone_number}{" "}
                  <span className="text-red">*</span>
                </label>
                <ReactIntlTelInput
                  className="form-input w-full md:max-w-full"
                  inputStyle={{ color: "green" }}
                  inputProps={inputProps}
                  intlTelOpts={intlTelOpts}
                  value={phoneNumberValue}
                  containerStyle={{ width: "480px" }}
                  onChange={phoneNumberHandle}
                  type="text"
                />
                <span
                  ref={phoneNumberErrorRef}
                  className="text-danger field-validation-valid px-[10px]"
                ></span>
              </div>
            </div>
            <div className="mt-5">
              <div className="flex flex-col px-[10px] pb-0">
                <label htmlFor="reference-source-list">
                  {strings.member.member_detail.create_reference.select_reference_source_title}{" "}
                  <span className="text-red">*</span>
                </label>
                <DropdownSearch
                    data={referenceSourceList}
                    selectedData={selectedReferenceSource}
                    setSelectedData={setSelectedReferenceSource}
                    classnames="md:max-w-full"
                />
                <span
                    ref={referenceSourceErrorRef}
                    className="text-danger field-validation-valid px-[10px]"
                ></span>
              </div>
            </div>
            <button
                type="submit"
                disabled={isDisabled}
                className={classNames("button search-button gap-3 text-white bg-blue border-blue mt-7 mx-auto px-10 w-[130px]",
                    {'opacity-70 hover:opacity-70 !cursor-wait': isDisabled}
                )}
            >
              {!isDisabled && strings.button.save_upper}
              {isDisabled && <LoadingSpinner/>}
            </button>
            {Object.keys(addedReference).length > 0 && (
                <div className="mt-5">
                  <div className="flex flex-col justify-center items-center px-[10px] pb-0">

                  <span className="text-[14px]">
                    {isRedirectMemberDetailPage && strings.member.member_detail.create_reference.click_for_detail_candidate_pool}
                    {!isRedirectMemberDetailPage && strings.member.member_detail.create_reference.click_for_detail}
                  </span>

                  <Link
                    to={url("member.detail.profile", { memberId: addedReference.memberId })}
                    target="_blank"
                    className="py-3 font-bold text-blue hover:underline focus:underline"
                  >
                    {addedReference.fullname}
                  </Link>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
      {(Object.keys(modalContent).length !== 0 && (
        <Modal modal={modal} setModal={setModal} classes={{ modal: "h-[90vh] max-w-[1440px]", content: `${selectReferenceData.length > 14 ? "overflow-y-scroll" : "overflow-y-hidden"} overflow-x-hidden h-[90%]` }} >
          {modalContent.element === "selectReference" &&
            <SelectReferenceModal
              data={modalContent.data}
              setSelectedReference={setSelectedReference}
              closeModal={closeModal}
            />
          }
        </Modal>
      ))}
    </>
  );
};

export default CreateReference;
