import React, { useEffect, useRef, useState } from 'react';
import { DetailSearchLayout, Dropdown } from '../../../../components';
import { useLocalization } from '../../../../hooks/Utils/useLocalization';
import usePrimeData from '../../../../hooks/Utils/usePrimeData';

const CurrentCardDetailSearch = ({ changeDetail, loading, hasAdminRole }) => {

  const strings = useLocalization();
  const primeData = usePrimeData();

  const firstBrand = { id: -1, name: strings.detail_search_titles.brands};
  const brandList = [firstBrand, ...primeData.brandList];

  const [brand, setBrand] = useState(firstBrand);

  const firstVisit = useRef(true);

  useEffect(() => {
    //Sayfa ilk kez yüklenmiyorsa işlemi yap
    //Servise birden çok gez gitmesini engelliyoruz
    if (!firstVisit.current) {
        changeDetail?.({ brandId: brand?.id });
        firstVisit.current = false;
    }
  }, [brand])

  useEffect(() => {
    //Sayfa yüklendikten sonra false yaparak her değişiklikte
    //servisin ziyaret edilmesini sağlıyoruz 
    firstVisit.current = false;
  }, [])

  return (
    hasAdminRole && (
      <DetailSearchLayout 
        isDisabled={loading}
        children={
          <div className="!flex gap-x-4 gap-y-5 max-md-lg:justify-center flex-wrap relative">
            <Dropdown 
                title={strings.detail_search_titles.brands}
                data={brandList}
                selectedData={brand}
                setSelectedData={setBrand}
                isDisabled={loading}
            />
          </div>
        }    
      />
    )
  )
}

export default CurrentCardDetailSearch;