import { fetchApi } from '../index';

export const EmployeeCreate = async (model) => {

    const url = '/employee/v2/employee/create/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const EmployeeFilter = async (model) => {

    const url = '/employee/v2/employee/employees/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const EmployeeChangeDistrubute = async (willOpen, employeeId) => {

    const url = '/member/v1/member_distribution/change_distribute/';

    const payload = {
        willOpen: willOpen,
        employeeId: employeeId
    };

    return await fetchApi(url, payload);
}

export const EmployeeSendMembersToPool = async (employeeId) => {

    const url = '/member/v1/member_pool/push_employee_all_members_to_pool/';

    const payload = {
        employeeId: employeeId
    };

    return await fetchApi(url, payload);
}

export const EmployeeDetails = async (employeeId) => {

    const url = '/employee/v1/employee/details/';

    const payload = {
        employeeId: employeeId
    };

    return await fetchApi(url, payload);
}

export const EmployeeEdit = async (model) => {

    const url = '/employee/v2/employee/edit/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const GetAllSalesPerson = async (brandId) => {
    const url = '/employee/v1/employee/all/';

    const payload = {
        brandId: brandId
    };

    return await fetchApi(url, payload);
}

export const GetAllProductivityPerson = async (brandId) => {
    const url = '/employee/v1/employee/get_productivity/';

    const payload = {
        brandId: brandId
    };

    return await fetchApi(url, payload);
}

export const GetAllReferencerPerson = async (brandId) => {
    const url = '/employee/v1/employee/get_referencer/';

    const payload = {
        brandId: brandId
    };

    return await fetchApi(url, payload);
}

export const GetAllCorporateSupportPerson = async (brandId) => {
    const url = '/employee/v1/employee/get_corporate_support/';

    const payload = {
        brandId: brandId
    };

    return await fetchApi(url, payload);
}

export const GetAllCorporateSalesPerson = async (brandId) => {
    const url = '/employee/v1/employee/get_corporate_sales/';

    const payload = {
        brandId: brandId
    };

    return await fetchApi(url, payload);
}

export const GetEmployeesWithCorporateEmails = async (brandId) => {
    const url = '/employee/v1/employee/get_employee_list_with_corporate_emails/';

    const payload = {
        brandId: brandId
    };

    return await fetchApi(url, payload);
}

export const GetEmployeeName = async (employeeId) => {

    const url = '/employee/v1/employee/get_employee_name/';

    const payload = {
        employeeId: employeeId
    };

    return await fetchApi(url, payload);
}

export const GetEmployeeListById = async (employeeId) => {

    const url = '/employee/v2/employee/get_employee_list_by_id/';

    const payload = {
        employeeId: employeeId
    };

    return await fetchApi(url, payload);
}

//Verilen employeeId'ye ait aynı takımdaki employee'leri getiren servis.
export const GetSameTeamEmployees = async (employeeId) => {

    const url = '/employee/v1/employee/get_same_team_employees_for_distribute/';

    const payload = {
        employeeId: employeeId
    };

    return await fetchApi(url, payload);
}

//Öğrenci Dağıtma modalı içerisinde status'e göre toplam dağıtılacak öğrenci sayısını getiren servis.
export const GetTotalMemberRecords = async (employeeId) =>{

    const url = '/member/v1/member_distribution/total_member_records/';

    const payload = {
        employeeId: employeeId
    };

    return await fetchApi(url, payload);
}

//Öğrenciler Dağıtma modalında öğrencilerin dağıtımını sağlayan servis.
export const DistributeMembers = async (model) =>{

    const url = '/member/v1/member_distribution/distribution/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const GetEmployeeSenderEmail = async (employeeId) => {

    const url = '/employee/v1/employee/get_employee_sender_email/';

    const payload = {
        employeeId: employeeId
    };

    return await fetchApi(url, payload);
}

export const GetEmployeeCallRecords = async (model) => {

    const url = '/employee/v1/employee_call_record/get_all_call_records_by_member_id/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const GetTranscribesByEmployeeCallRecordIds = async (model) => {

    const url = '/employee/v1/employee_call_record/get_transcribes_by_employee_call_record_ids/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const AnalyzeCallRecords = async (model) => {

    const url = '/employee/v1/employee_call_record/analyze_call_record/';

    const payload = { 
        ...model 
    };

    return await fetchApi(url, payload);
}