import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {showCenteredAlertTwoButtonsWithParameters, showTopAlert} from '../../../../../../../components';
import {SalesTypeEnum, ServiceErrorMessages, TermStatusType} from '../../../../../../../consts/Constants';
import {useLocalization} from '../../../../../../../hooks/Utils/useLocalization';
import {url} from '../../../../../../../routes/utility';
import {DeleteTerm, MoveToSubTeacher} from '../../../../../../../services/Term/TermService';
import useAuth from '../../../../../../../hooks/Log/useAuth';
import {getButtonPermission} from "../../../../../../../rights/Utility";
import classNames from "classnames";

const OperationDropdown = (props) => {

  const {
    setIsClickOutside,
    memberId,
    setModal,
    setModalSize,
    setModalContent,
    selectedTermButtons,
    termId,
    studentNo,
    termSalesType,
    sendRequestToEndPoint,
    memberPoolPermission,
    isAutoDemo
  } = props;
  const strings = useLocalization();
  const { employee } = useAuth();
  const navigate = useNavigate();

  const { employeeTypeId, checkPermission } = useAuth();
  const roles = getButtonPermission({ group: "termOperations", name: "memberTeacherMessage" });
  const memberTeacherMessagePermission = checkPermission(roles, employeeTypeId);

  const salesButtonRoles = getButtonPermission({ group: "termSalesOperations", name: "general" });

  const deleteTerm = async (termId, createUserId) => {

    const result = await DeleteTerm(termId, createUserId);

    if (result.status === 200) {
      showTopAlert(strings.member.member_detail.terms.delete_term_success_message, "success");
      sendRequestToEndPoint();
    } else {
      showTopAlert(strings.errors.an_error_occurred, "error");
    }
  }

  const moveToSubstituteTeacher = async (termId, createUserId) => {

    const result = await MoveToSubTeacher(termId, createUserId);

    if (result.status === 200) {
      showTopAlert(strings.member.member_detail.terms.move_substitute_teacher_success_message, "success");
      sendRequestToEndPoint();
      setTimeout(() => { window.location.reload() }, 2000);
    } else {
      if (result.content === ServiceErrorMessages.ESD_NOT_IN_TIME_INTERVAL) {
        showTopAlert(strings.errors.esd_time_frame_error, "error");
      } else {
        showTopAlert(strings.errors.an_error_occurred, "error");
      }
    }
  }

  return (
    <>
      <ul className="py-1">
        {(memberPoolPermission && selectedTermButtons !== TermStatusType.Pending) && (
          <li
            onClick={() => setIsClickOutside(true)}
            className={`group/dropdown relative ${(!checkPermission(salesButtonRoles, employeeTypeId) || termSalesType.id === SalesTypeEnum.Demo) ? 'hidden' : ''}`}
          >
            <button
              className="flex w-full justify-between items-center py-2 gap-2 px-4 tab-button"
            >
              {strings.member.member_detail.others.sales}
              <span className="material-symbols-outlined text-[15px]">double_arrow</span>
            </button>
            <div className="hidden group-hover/dropdown:flex absolute left-[100%] bg-white bottom-0 min-w-max z-10 rounded origin-bottom animate-fadeIn transition-transform duration-200 shadow-dropdownContent">
              <ul className="py-1">
                <li>
                  <button
                    className={classNames("flex justify-start w-full py-2 px-4 tab-button group relative", {
                      "opacity-70 cursor-not-allowed": selectedTermButtons === TermStatusType.Future
                    })}
                    disabled={selectedTermButtons === TermStatusType.Future}
                    onClick={() => { navigate(url("member.detail.registration", { memberId: memberId }), { state: { registrationType: 3, studentNo: studentNo, termId: termId } }); }}
                  >
                    {strings.member.member_detail.terms.extend}

                    {selectedTermButtons === TermStatusType.Future && (
                      <div className="tooltip-top">
                        {strings.member.member_detail.terms.go_second_registration}
                      </div>
                    )}
                  </button>
                </li>
                {/* <li>
                    <button
                        className="flex justify-start w-full py-2 px-4 tab-button"
                        onClick={() => navigate(url("member.detail.additionalservice", { memberId: memberId }), { state: { termId: termId } })}
                    >
                      {strings.member.member_detail.terms.additional_service}
                    </button>
                  </li> */}
                {SalesTypeEnum.ChangePackage !== termSalesType.id && (
                  <li>
                    <button
                      className="flex justify-start w-full py-2 px-4 tab-button"
                      onClick={() => navigate(url("member.detail.changeterm", { memberId: memberId }), { state: { termId: termId, studentNo: studentNo } })}
                    >
                      {selectedTermButtons === TermStatusType.Future && strings.member.member_detail.terms.future_change_term}
                      {selectedTermButtons !== TermStatusType.Future && strings.member.member_detail.terms.change_term}
                    </button>
                  </li>
                )}
              </ul>
            </div>
          </li>
        )}

        {selectedTermButtons !== TermStatusType.Passive && (
          <li onClick={() => setIsClickOutside(true)} className="group relative">
            <button className="flex w-full justify-between items-center py-2 gap-2 px-4 tab-button">
              {strings.member.member_detail.terms.term}
              <span className="material-symbols-outlined text-[15px]">double_arrow</span>
            </button>

            <div className="hidden group-hover:flex absolute left-[100%] bg-white bottom-0 min-w-max z-10 rounded origin-bottom animate-fadeIn transition-transform duration-200 shadow-dropdownContent">
              <ul className="py-1">
                {selectedTermButtons !== TermStatusType.Pending && (
                  <>
                    {memberPoolPermission &&
                        <li className="group relative">
                          <button
                            onClick={() => { setModal(true); setModalContent({ element: "units" }); setModalSize({ size: "middle" }) }}
                            className="flex justify-start w-full py-2 px-4 tab-button"
                          >
                            {strings.member.member_detail.terms.units}
                          </button>
                        </li>
                    }
                    {(memberPoolPermission && memberTeacherMessagePermission) &&
                        <li className="group relative">
                          <button
                            onClick={() => { setModal(true); setModalContent({ element: "memberteachermessage" }); setModalSize({ size: "large" }) }}
                            className="flex justify-start w-full py-2 px-4 tab-button"
                          >
                            {strings.member.member_detail.terms.member_teacher_message}
                          </button>
                        </li>
                    }
                    <li>
                        <Link
                          to={url("member.termschedule", { termId: termId })}
                          className="flex justify-start w-full py-2 px-4 tab-button"
                          target="_blank"
                        >
                          {strings.member.member_detail.terms.lesson_archive}
                        </Link>
                    </li>
                    {memberPoolPermission &&
                        <li>
                          <button
                            onClick={() => {
                              showCenteredAlertTwoButtonsWithParameters(
                                strings.alert.centered_alert_two_buttons.title_confirm,
                                strings.member.member_detail.terms.move_substitute_teacher_message,
                                strings.button.confirm,
                                strings.alert.centered_alert_two_buttons.cancel_text,
                                moveToSubstituteTeacher,
                                [termId, employee.EmployeeId]
                              );
                            }}
                            className="flex justify-start w-full py-2 px-4 tab-button"
                          >
                            {strings.member.member_detail.terms.move_substitute_teacher}
                          </button>
                        </li>
                    }
                  </>
                )}
                {(memberPoolPermission && !isAutoDemo) &&
                  <li>
                    <button
                      onClick={() => {
                        showCenteredAlertTwoButtonsWithParameters(
                          strings.alert.centered_alert_two_buttons.title_confirm,
                          strings.member.member_detail.terms.delete_term_message,
                          strings.button.confirm,
                          strings.alert.centered_alert_two_buttons.cancel_text,
                          deleteTerm,
                          [termId, employee.EmployeeId]
                        );
                      }}
                      className="flex justify-start w-full py-2 px-4 tab-button bg-red/10 hover:bg-red/20 focus:bg-red/20"
                    >
                      <span className="text-red font-bold">{strings.member.member_detail.terms.delete_term}</span>
                    </button>
                  </li>
                }
              </ul>
            </div>

          </li>
        )}
      </ul>
    </>
  )
};

export default OperationDropdown;