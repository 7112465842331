import { PageTitle } from "../../components";
import { useLocalization } from "../../hooks/Utils/useLocalization";

const MentorNpsReport = () => {

    const strings = useLocalization();

    return (
        <>
            <PageTitle title={strings.sidebar.report.mentor_nps} />

            <div className="flex justify-center mt-5">
                <iframe title="mentor_nps" width="1725" height="1725" src="https://lookerstudio.google.com/embed/reporting/581739f8-01f6-437c-bece-81b25f758c7e/page/p_iw082ldjmd" frameBorder="0" allowFullScreen></iframe>
            </div>
        </>
    )
}

export default MentorNpsReport;