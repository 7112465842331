import { createSlice } from "@reduxjs/toolkit";
import { getCookie, removeCookie, setCookie } from 'ko-basic-cookie';
import { esneksCookieName } from "../consts/Constants";

export const EsneksSlice = createSlice({
    name: 'esneks',
    initialState: { 
        esneksToken: getCookie(esneksCookieName) ? atob(getCookie(esneksCookieName)) : ""
    },
    reducers: {
        esneksLogin: (state, action) => {
            state.esneksToken = action.payload.esneksToken;
            setCookie(esneksCookieName, btoa(state.esneksToken), 1);
        },
        esneksLogout: (state) => {
            state.esneksToken = '';
            removeCookie(esneksCookieName);
        }
    },
})

export const { esneksLogin, esneksLogout } = EsneksSlice.actions

export default EsneksSlice.reducer