import tr from 'date-fns/locale/tr';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { closeCloseSection, closePostponeSection, Dropdown, Loading, openCloseSection, openPostponeSection, showCenteredAlertTwoButtons, showTopAlert } from '../../../../../components';
import {
    getddMMyyyyDate,
    ISOStringToDate,
    stringToDate
} from '../../../../../components/Utility';
import { CustomEventDescriptions, CustomEvents } from "../../../../../consts/DataLayer";
import useAuth from '../../../../../hooks/Log/useAuth';
import useDataLayer from "../../../../../hooks/Log/useDataLayer";
import { useLocalization } from '../../../../../hooks/Utils/useLocalization';
import { GetMemberNoteTitlesByStatus } from '../../../../../services/Member/MemberFormService';
import { EndTask, GetTaskCountByEmployeeId, GetTaskNotes, GetTaskPostponedDates, PostponeTask } from '../../../../../services/Task/TaskService';
import TaskAiAnalysis from './components/TaskAiAnalysis';

const StartTaskModal = (props) => {

    registerLocale("tr", tr);

    const { employee } = useAuth();
    const dataLayer = useDataLayer();

    const [taskNotes, setTaskNotes] = useState([]);
    const [taskPostponedDates, setTaskPostponedDates] = useState([]);
    const firstVisit = useRef(true);

    const { classes, data, closeTask, closeModal, sendRequest } = props;

    const strings = useLocalization();
    const postponeSectionRef = useRef();
    const closeSectionRef = useRef();
    const buttonsRef = useRef();
    const taskCountRef = useRef();
    const taskNoteRef = useRef();

    const [taskNote, setTaskNote] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [postponeDate, setPostponeDate] = useState(new Date(Date.now() + (24 * 60 * 60 * 1000)));
    const [postponeMessage, setPostponeMessage] = useState('');
    const [loading, setLoading] = useState(true);

    const [postponeNoteTitleId, setPostponeNoteTitleId] = useState(null);
    const firstReason = { id: -1, name: strings.member.member_detail.tasks.choose_reason };
    const [closeReason, setCloseReason] = useState(firstReason);
    const [closeReasonList, setCloseReasonList] = useState([firstReason]);

    useEffect(() => {
        let message = strings.member.member_detail.tasks.postpone_task_question;
        let formattedMessage = message.replace(/#date#/g, getddMMyyyyDate(postponeDate));
        setPostponeMessage(formattedMessage);
        getTaskCountByEmployeeId(employee.EmployeeId);
    }, [postponeDate])

    const handlePostponeTask = async () => {
        //showCenteredAlertTwoButtons(strings.alert.centered_alert_two_buttons.title, postponeMessage, strings.button.save, strings.button.cancel)
        const model = {
            taskId: data.taskId,
            employeeId: employee.EmployeeId,
            taskNote: taskNote,
            noteTitleId: postponeNoteTitleId,
            postponeDate: postponeDate
        }

        const result = await PostponeTask(model);

        if (result.status === 200) {
            //İşlem başarılı olduktan sonrta yapılacak işlemler
            showTopAlert(strings.member.member_detail.tasks.postpone_task_success, "success");
            setPostponeDate(new Date(Date.now() + (24 * 60 * 60 * 1000)));
            taskNoteRef.current.value = "";
            setTaskNote("");
            taskCountRef.current.innerText = 0;
            closePostponeSection(postponeSectionRef);
            buttonsRef.current.classList.remove("hidden");
            getTaskNotes(data.taskId);
            getTaskPostponedDates(data.taskId);
            getMemberNoteTitlesByStatus();
            sendRequest && sendRequest();
            closeModal && closeModal();

            //DATA LAYER LOGGED
            dataLayer.useCustomEvents(CustomEvents.EMPLOYEE_EVENTS, CustomEventDescriptions.POSTPONE_TASK);
        } else {
            showTopAlert(strings.member.member_detail.tasks.postpone_task_error, "error")
        }

    }

    const handleCloseTask = async () => {

        const model = {
            taskId: data.taskId,
            employeeId: employee.EmployeeId,
            taskNote: taskNote,
            noteTitleId: closeReason.id
        }

        const result = await EndTask(model);

        if (result.status === 200) {
            showTopAlert(strings.member.member_detail.tasks.close_task_success, "success");
            closeTask && closeTask();
            closeModal && closeModal();

            //DATA LAYER LOGGED
            dataLayer.useCustomEvents(CustomEvents.EMPLOYEE_EVENTS, CustomEventDescriptions.CLOSE_TASK);
        }

    }

    //Employee ye ait seçilen günde kaç tane görev olduğunu gösteriyoruz
    const getTaskCountByEmployeeId = async (employeeId) => {

        const model = {
            beginDate: stringToDate(getddMMyyyyDate(postponeDate)),
            endDate: stringToDate(getddMMyyyyDate(postponeDate), 23, 59, 59),
            employeeId: employeeId
        }

        const result = await GetTaskCountByEmployeeId(model);

        if (result.status === 200) {
            taskCountRef.current.innerText = parseInt(result.content);
        }
    }

    //Daha önce görev notu girilmişse aşağıdaki liste için çağırılıyor
    const getTaskNotes = async (taskId) => {
        const result = await GetTaskNotes(taskId);
        if (result.status === 200) {
            setTaskNotes(taskNotes => JSON.parse(result.content));
        }
    }

    //Daha önce görev ertelendiyse aşağıdaki liste için çağırılıyor
    const getTaskPostponedDates = async (taskId) => {
        const result = await GetTaskPostponedDates(taskId);
        if (result.status === 200) {
            setTaskPostponedDates(taskPostponedDates => JSON.parse(result.content));
            setLoading(loading => false)
        }
    }

    //Görev erteleme ve kapatma note title id leri getiriliyor
    const getMemberNoteTitlesByStatus = async () => {
        const result = await GetMemberNoteTitlesByStatus(3);
        if (result.status === 200) {
            let resultContent = JSON.parse(result.content);
            //Görev ertelendi id alacağız
            let postponeTitle = resultContent.find(title => title.text === 'Görev ertelendi');
            if (typeof postponeTitle !== 'undefined') {
                setPostponeNoteTitleId(postponeNoteTitleId => parseInt(postponeTitle.value));
            }

            //Görev ertelendi hariç olan tüm başlıklar görev kapatıldı olarak tutulacak
            let arrayList = [];
            resultContent.filter(title => title.text !== 'Görev ertelendi').map((item) => {
                let arrayItem = { id: parseInt(item.value), name: item.text };
                arrayList.push(arrayItem);
            });

            if (arrayList.length > 0) {
                setCloseReasonList(closeReasonList => [firstReason, ...arrayList]);
            }
        }
    }

    useEffect(() => {
        //Modal ile açıldığı için olabilir. Bazen servise sürekli istek atıyor. O sebeple ilk ziyaretten sonra isteği durduruyoruz.
        if (firstVisit.current) {
            if (data && data.taskId) {
                getTaskNotes(data.taskId);
                getTaskPostponedDates(data.taskId);
                getMemberNoteTitlesByStatus();
            }
            firstVisit.current = false;
        }

    }, [])

    return (
        <div className={`${classes} overflow-x-hidden pr-2 overflow-y-auto`}>
            <div className="flex flex-col md:flex-row gap-1 md:gap-3">
                <p className="font-bold text-[20px]">{data.memberName}</p>
            </div>
            <hr className="-mx-2 sm:-mx-6 my-3" />
            <form className="flex flex-col mt-2">
                {data?.message?.length > 0 &&
                    <>
                        <label>{strings.member.member_detail.tasks.task_message}</label>
                        <p className='mb-3'>{data.message}</p>
                    </>
                }
                <label htmlFor="task_note">{strings.member.member_detail.tasks.task_note}</label>
                <textarea
                    ref={taskNoteRef}
                    id="task_note"
                    rows="2"
                    className="outline-0 py-3 px-2 border border-[#ebedf2] focus:border-blue rounded"
                    onChange={(e) => setTaskNote(e.target.value)}
                >
                </textarea>
            </form>
            <div ref={postponeSectionRef} className="mt-5 hidden">
                <div className="flex gap-2 items-center mb-2">
                    <p className="font-bold">{strings.member.member_detail.tasks.postpone_date}</p>
                    <div className="w-7 h-7 flex justify-center items-center border border-[#dddddd] rounded-full text-[11.3px] cursor-default relative group z-10">
                        <span ref={taskCountRef}>0</span>
                        <div className="tooltip-top !right-[-120px] !left-[-120px]">
                            <p>{strings.member.member_detail.tasks.total_task_number}</p>
                        </div>
                    </div>
                </div>
                <div className="w-full flex flex-col md:flex-row gap-3">
                    <div className="relative w-full">
                        <DatePicker
                            locale="tr"
                            dateFormat="dd/MM/yyyy"
                            className="form-input w-full"
                            selected={postponeDate}
                            onChange={(date) => { setPostponeDate(date); setIsOpen(false) }}
                            minDate={new Date()}
                            maxDate={(() => {
                                if (data?.taskDate) {
                                    const maxDate = new Date(data.taskDate);
                                    maxDate.setMonth(maxDate.getMonth() + 3);
                                    maxDate.setDate(maxDate.getDate() + 1);
                                    return maxDate;
                                }
                                return null;
                            })()}
                            placeholderText={strings.form_info.date_placeholder}
                            open={isOpen}
                            readOnly={true}
                            onClickOutside={() => setIsOpen(false)}
                            onFocus={() => setIsOpen(true)}
                        />
                        <button type="button" onClick={() => setIsOpen(!isOpen)}>
                            <span className="material-symbols-outlined absolute right-4 top-2 text-lg">
                                calendar_today
                            </span>
                        </button>
                    </div>
                    <div className="flex justify-center gap-2">
                        <button
                            type="button"
                            className="button h-[45px] rounded-full search-button bg-blue text-white"
                            onClick={() =>
                                showCenteredAlertTwoButtons(strings.alert.centered_alert_two_buttons.title, postponeMessage, strings.button.save, strings.button.cancel, handlePostponeTask)
                            }
                        >
                            {strings.button.postpone_upper}
                        </button>
                        <button
                            type="button"
                            className="button h-[45px] rounded-full search-button bg-red text-white focus:border-red px-5"
                            onClick={() => {
                                closePostponeSection(postponeSectionRef);
                                buttonsRef.current.classList.remove("hidden");
                                taskCountRef.current.innerText = 0;
                            }}
                        >
                            {strings.button.cancel_upper}
                        </button>
                    </div>
                </div>
            </div>
            <div ref={closeSectionRef} className="mt-5 hidden w-full">
                <div className="mt-2 flex md:gap-10 gap-3 w-full flex-col md:flex-row items-center">
                    <Dropdown
                        classnames="md:max-w-full"
                        data={closeReasonList}
                        selectedData={closeReason}
                        setSelectedData={setCloseReason}
                    />
                    <div className="flex gap-2">
                        <button
                            type="button"
                            className="button h-[45px] rounded-full search-button bg-blue text-white"
                            onClick={() => {
                                if (closeReason.id === -1) {
                                    showTopAlert(strings.member.member_detail.tasks.choose_reason_error, "error");
                                } else {
                                    showCenteredAlertTwoButtons(strings.alert.centered_alert_two_buttons.title, strings.member.member_detail.tasks.close_question, strings.button.save, strings.button.cancel, handleCloseTask)
                                }
                            }}
                        >
                            {strings.button.confirm_upper}
                        </button>
                        <button
                            type="button"
                            className="button h-[45px] rounded-full search-button bg-red text-white focus:border-red"
                            onClick={() => {
                                closeCloseSection(closeSectionRef);
                                buttonsRef.current.classList.remove("hidden");
                            }}
                        >
                            {strings.button.cancel_upper}
                        </button>
                    </div>
                </div>
            </div>
            <div ref={buttonsRef} className="mt-5 w-full justify-center flex gap-10">
                <button className="button search-button bg-blue border-blue text-white" onClick={() => {
                    openPostponeSection(postponeSectionRef, closeSectionRef);
                    buttonsRef.current.classList.add("hidden");
                }}>
                    {strings.member.member_detail.tasks.postpone_task_upper}
                </button>
                <button className="button search-button bg-red border-red focus:border-red text-white" onClick={() => {
                    openCloseSection(closeSectionRef, postponeSectionRef);
                    buttonsRef.current.classList.add("hidden");
                }}>
                    {strings.member.member_detail.tasks.close_task_upper}
                </button>
            </div>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <TaskAiAnalysis data={data} />
                    <hr className="my-5" />
                    <div className="flex gap-5 flex-col md:flex-row md-xl:overflow-x-scroll pb-2">
                        <div className="w-full text-center">
                            <p className="font-bold th text-center">{strings.member.member_detail.tasks.notes_about_task}</p>
                            <div className='md:h-[213px] md:overflow-y-scroll mt-1'>

                                {taskNotes.length > 0 ?
                                    taskNotes.map((note, index) =>
                                    (
                                        <div key={index} className="px-5 py-2 border-b border-[#ebedf2] bg-white hover:bg-[#f0f0f0] flex flex-col gap-3">
                                            <div className="flex items-center gap-3">
                                                <span className="material-symbols-outlined flex-shrink-0 text-[19px]">calendar_month</span>
                                                <div className='flex flex-col sm:flex-row gap-1'>
                                                    <p className='min-w-max'>{ISOStringToDate(note.createDate, parseInt(employee.TimeZone))}</p>
                                                    <div className="px-[15px] py-[4px] inline-flex justify-center items-center text-[11px] bg-card border border-input-border rounded-full">
                                                        <p className='min-w-max'>{note.createUser}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex items-center gap-3">
                                                <span className="material-symbols-outlined flex-shrink-0 text-[19px]">info</span>
                                                <p className='max-w-[1000px] text-start'>{note.note.length !== 0 ? note.note : strings.member.member_detail.tasks.task_notes_not_found}</p>
                                            </div>
                                        </div>
                                    )
                                    )
                                    : (<div className="py-4">{strings.member.member_detail.tasks.not_found_task_note}</div>)
                                }

                            </div>
                        </div>
                        <div className="w-full text-center">
                            <p className="font-bold th text-center min-w-max">{strings.member.member_detail.tasks.date_of_postponed_task}</p>
                            <div className='flex w-full'>
                                <div className="th w-full">
                                    <p className="font-bold py-[14px] text-center">{strings.member.member_detail.tasks.postponed_date}</p>
                                </div>
                                <div className="th w-full">
                                    <p className="font-bold py-[14px] text-center">{strings.table.operation_informations}</p>
                                </div>
                            </div>
                            <div className='md:h-[140px] md:overflow-y-scroll mt-1 mr-2'>

                                {taskPostponedDates.length > 0 ?
                                    taskPostponedDates.map((date, index) => (
                                        <div key={index} className='bg-white hover:bg-[#f0f0f0] flex w-full'>
                                            <div className="td align-middle w-full">
                                                <p>{(ISOStringToDate(date.postponedDate, parseInt(employee.TimeZone)))?.split(" / ")[0]}</p>
                                            </div>
                                            <div className="td align-middle w-full">
                                                <p className='min-w-max'>{ISOStringToDate(date.transactionDate, parseInt(employee.TimeZone))}</p>
                                                <div className="px-[15px] mt-2 py-[4px] inline-flex justify-center items-center text-[11px] border bg-card rounded-full">
                                                    <p className='min-w-max'>{date.transactionUser}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                    : (<div className="py-4">{strings.member.member_detail.tasks.not_found_task_postponed_date}</div>)
                                }

                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
};

export default StartTaskModal;