import React, { useCallback, useEffect, useState, useRef } from "react";
import { Ai } from "../../../../../../assets/svg";
import { AIKeys, SalesAiType, ServiceErrorMessages } from "../../../../../../consts/Constants";
import useAiRouting from "../../../../../../hooks/Utils/useAiRouting";
import { useLocalization } from "../../../../../../hooks/Utils/useLocalization";
import { GetAiHistoryToAiAnalysis, GetMemberAiSalesAnalysis } from "../../../../../../services/Member/MemberSalesAiAnalysis";

const AnalysisSection = ({ title, onShowDetail }) => {

    const strings = useLocalization();

    return (
        <div className="bg-white rounded-lg shadow-sm border border-[#ebebeb]">
            <div className="flex items-center justify-between p-4">
                <div className="flex items-center gap-2">
                    <div className="bg-blue/10 p-2 rounded-lg">
                        <img src={Ai} alt="ai" width={24} height={24} />
                    </div>
                    <p className="font-semibold">{title}</p>
                </div>
                <button
                    type="button"
                    className="flex items-center gap-0.5 text-blue hover:bg-blue/10 px-4 py-2 rounded-lg transition-colors"
                    onClick={onShowDetail}
                >
                    <span className="material-symbols-outlined text-[18px]">
                        visibility
                    </span>
                    <span> {strings.member.member_detail.profile.sales_analysis.show_detail}</span>
                </button>
            </div>
        </div>
    )
};

const MemberSalesAiAnalysis = ({ memberId, showAiDemoAnalysis, aiUseCaseSessionExists, setModal, setModalContent }) => {

    const strings = useLocalization();

    const levelTestUseCaseId = useAiRouting(AIKeys.LEVEL_FINDER);
    const interviewProUseCaseId = useAiRouting(AIKeys.INTERVIEW_PR0);

    const firstVisit = useRef(true);

    const [loading, setLoading] = useState({
        demoAnalysis: false,
        levelTest: false,
        interviewPro: false
    });

    const [error, setError] = useState({
        demoAnalysis: false,
        levelTest: false,
        interviewPro: false
    });

    const [salesAnalysis, setSalesAnalysis] = useState("");
    const [levelTestAnalysis, setLevelTestAnalysis] = useState("");
    const [interviewProAnalysis, setInterviewProAnalysis] = useState("");

    const getMemberAiSalesAnalysis = useCallback(async (memberId) => {
        if (loading.demoAnalysis) return;

        setLoading(prev => ({ ...prev, demoAnalysis: true }));
        setError(prev => ({ ...prev, demoAnalysis: false }));

        const result = await GetMemberAiSalesAnalysis(memberId);

        if (result.status === 200) {
            setSalesAnalysis(result.content);
        } else if (ServiceErrorMessages.AI_ANALYSIS_GAVE_ERROR === result.content
            || ServiceErrorMessages.AI_ANALYSIS_NOT_FOUND === result.content
        ) {
            setError(prev => ({ ...prev, demoAnalysis: true }));
        }

        setLoading(prev => ({ ...prev, demoAnalysis: false }));
    }, []);

    const getAiHistoryToAiAnalysis = useCallback(async (memberId, salesAiType, useCaseId) => {
        const loadingKey = salesAiType === SalesAiType.LEVEL_TEST ? 'levelTest' : 'interviewPro';
        if (loading[loadingKey]) return;

        setLoading(prev => ({ ...prev, [loadingKey]: true }));
        setError(prev => ({ ...prev, [loadingKey]: false }));

        const result = await GetAiHistoryToAiAnalysis(memberId, salesAiType, useCaseId);

        if (result.status === 200) {
            if (salesAiType === SalesAiType.LEVEL_TEST) {
                setLevelTestAnalysis(result.content);
            } else if (salesAiType === SalesAiType.INTERVIEW_PRO) {
                setInterviewProAnalysis(result.content);
            }
        } else if (ServiceErrorMessages.AI_ANALYSIS_GAVE_ERROR === result.content
            || ServiceErrorMessages.AI_ANALYSIS_NOT_FOUND === result.content
        ) {
            setError(prev => ({ ...prev, [loadingKey]: true }));
        }

        setLoading(prev => ({ ...prev, [loadingKey]: false }));
    }, []);

    useEffect(() => {
        if (firstVisit.current) {
            firstVisit.current = false;
        } else {
            if (showAiDemoAnalysis) {
                getMemberAiSalesAnalysis(memberId);
            }
            if (aiUseCaseSessionExists.levelTestUseCaseExisting) {
                getAiHistoryToAiAnalysis(memberId, SalesAiType.LEVEL_TEST, levelTestUseCaseId);
            }
            if (aiUseCaseSessionExists.interviewProUseCaseExisting) {
                getAiHistoryToAiAnalysis(memberId, SalesAiType.INTERVIEW_PRO, interviewProUseCaseId);
            }
        }
    }, [memberId, showAiDemoAnalysis, aiUseCaseSessionExists, levelTestUseCaseId, interviewProUseCaseId, getMemberAiSalesAnalysis, getAiHistoryToAiAnalysis]);

    return (
        <div className="flex flex-col gap-4">
            {/* AI Demo Satış Sistemi Yorumu */}
            {showAiDemoAnalysis &&
                <AnalysisSection
                    title={strings.member.member_detail.profile.sales_analysis.sales_analysis_title}
                    onShowDetail={() => {
                        setModal(true);
                        setModalContent({
                            element: "salesaianalysis",
                            props: {
                                title: strings.member.member_detail.profile.sales_analysis.sales_analysis_title,
                                content: salesAnalysis,
                                loading: loading.demoAnalysis,
                                error: error.demoAnalysis,
                                notFoundMessage: strings.member.member_detail.profile.sales_analysis.sales_analysis_not_found,
                                tryAgainMessage: strings.button.try_again,
                                onRetry: () => getMemberAiSalesAnalysis(memberId)
                            }
                        });
                    }}
                />
            }

            {/* AI Level Test Satış Sistemi Yorumu */}
            {aiUseCaseSessionExists.levelTestUseCaseExisting &&
                <AnalysisSection
                    title={strings.member.member_detail.profile.sales_analysis.level_test_title}
                    onShowDetail={() => {
                        setModal(true);
                        setModalContent({
                            element: "salesaianalysis",
                            props: {
                                title: strings.member.member_detail.profile.sales_analysis.level_test_title,
                                content: levelTestAnalysis,
                                loading: loading.levelTest,
                                error: error.levelTest,
                                notFoundMessage: strings.member.member_detail.profile.sales_analysis.level_test_not_found,
                                tryAgainMessage: strings.button.try_again,
                                onRetry: () => getAiHistoryToAiAnalysis(memberId, SalesAiType.LEVEL_TEST, levelTestUseCaseId)
                            }
                        });
                    }}
                />
            }

            {/* AI Mülakat Pro Satış Sistemi Yorumu */}
            {aiUseCaseSessionExists.interviewProUseCaseExisting &&
                <AnalysisSection
                    title={strings.member.member_detail.profile.sales_analysis.interview_pro_title}
                    onShowDetail={() => {
                        setModal(true);
                        setModalContent({
                            element: "salesaianalysis",
                            props: {
                                title: strings.member.member_detail.profile.sales_analysis.interview_pro_title,
                                content: interviewProAnalysis,
                                loading: loading.interviewPro,
                                error: error.interviewPro,
                                notFoundMessage: strings.member.member_detail.profile.sales_analysis.interview_pro_not_found,
                                tryAgainMessage: strings.button.try_again,
                                onRetry: () => getAiHistoryToAiAnalysis(memberId, SalesAiType.INTERVIEW_PRO, interviewProUseCaseId)
                            }
                        });
                    }}
                />
            }
        </div>
    );
};

export default MemberSalesAiAnalysis;