import { useEffect, useRef, useState } from "react";
import { Loading, LoadingSpinner, PageTitle, Pagination, SearchBar, SearchCount, showTopAlert } from "../../components";
import NotAuthorized from "../../components/NotAuthorized";
import { areObjectsEqual, stringToDate } from "../../components/Utility";
import { ServiceErrorMessages } from "../../consts/Constants";
import { CustomEventDescriptions, CustomEvents } from "../../consts/DataLayer";
import useAuth from "../../hooks/Log/useAuth";
import useDataLayer from "../../hooks/Log/useDataLayer";
import { useLocalization } from "../../hooks/Utils/useLocalization";
import { GetBestPossibleMemberFromPool, MemberCandidateFilter } from "../../services/Member/MemberCandidateService";
import { CandidateTable } from "./components";
import CandidateDetailSearch from "./components/Candidate/CandidateDetailSearch";

const Candidate = () => {

    const firstVisit = useRef(true);
    //const startRef = useRef(null);
    const strings = useLocalization();
    const dataLayer = useDataLayer();

    const {employee, employeeTypeId, checkPermission, getPermission} = useAuth();
    const roles = getPermission();
    const permission = checkPermission(roles, employeeTypeId);

    const limit = parseInt(process.env.REACT_APP_TABLE_ROW_LIMIT);
    const [start, setStart] = useState(0);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecord, setTotalRecord] = useState(0);
    const [data, setData] = useState([]);
    const [waitForServiceResponse, setWaitForServiceResponse] = useState(false)

    const initialDetailSearch = {
        brandId: parseInt(employee.BrandId),
        status: -1,
        reminderBeginDate: null,
        reminderEndDate: null,
        salesPersonId: null,
        professionId: -1,
        utmSource: -1,
        reminderReasonId: -1,
        dropPoolReasonId: -1,
        searchText: ""
    };

    const [detailSearch, setDetailSearch] = useState(initialDetailSearch);
    //const detailSearchRef = useRef(initialDetailSearch);

    //Detail Search objesini ilk başta tutup değişip-değilmediğini anlamak için kullanılır.
    const [tmpDetailSearch, setTmpDetailSearch] = useState({});
    //Servise gidip-gitmeme durumunu kontrol eden state
    const [sendRequest, setSendRequest] = useState();
    //Servise gidildiğinde çalışır.
    const [isFirstRequest, setIsFirstRequest] = useState(false);

    //Arama verileri değiştiğinde değerleri sıfırlıyoruz
    const resetValue = () => {
        setStart(start => 0);
        setCurrentPage(currentPage => 1);
    }

    //select değişiklikleri bu alandan yönetiliyor
    const changeDetail = (values) => {
        setDetailSearch({...detailSearch, ...values});
        resetValue();
    }

    //tab değişikliğini bu alandan kontrol ediyoruz
    // const handleVisibilityChange = () => {
    //     if (!document.hidden && !firstVisit.current) {
    //         getMemberCandidate();
    //     }
    // }

    //input değişiklikleri bu alandan yönetiliyor
    const changeValue = (value) => {
        setDetailSearch({...detailSearch, searchText: value});
        resetValue();
    }

    //sayfa değiştikçe bilgileri yeniden çağırıyoruz
    useEffect(() => {
        //startRef.current = start;
        if (totalRecord !== 0) {
            getMemberCandidate();
        }
    }, [start])

    // useEffect(() => {
    //     detailSearchRef.current = detailSearch;
    // }, [detailSearch]);

    const getMemberCandidate = async () => {
        setLoading(loading => true);

        const model = {
            start: start,
            length: limit,
            employeeId: employee.EmployeeId,
            reminderBeginDate: detailSearch?.reminderBeginDate ? stringToDate(detailSearch?.reminderBeginDate) : null,
            reminderEndDate: detailSearch?.reminderEndDate ? stringToDate(detailSearch?.reminderEndDate, 23, 59, 59) : null,
            brandId: detailSearch?.brandId,
            status: detailSearch?.status,
            salesPersonId: detailSearch?.salesPersonId,
            professionId: detailSearch?.professionId,
            utmSource: detailSearch?.utmSource,
            reminderReasonId: detailSearch?.reminderReasonId,
            dropPoolReasonId: detailSearch?.dropPoolReasonId,
            searchText: detailSearch?.searchText
        }

        const result = await MemberCandidateFilter(model);

        if (200 === result.status) {
            let resultContent = JSON.parse(result.content);
            setIsFirstRequest(isFirstRequest => true);

            setData(data => resultContent.data);
            setTotalRecord(totalRecord => resultContent.recordsTotal);
            setLoading(loading => false);
        } else {
            showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
        }
    }

    const getCandidate = async (employeeId) => {
        setWaitForServiceResponse(true)

        const result = await GetBestPossibleMemberFromPool(employeeId);

        if (result.status === 200) {
            showTopAlert(strings.member.candidate.get_candidate_success, "success");
            setLoading(loading => true);
            getMemberCandidate();

            //DATA LAYER LOGGED
            dataLayer.useCustomEvents(CustomEvents.EMPLOYEE_EVENTS, CustomEventDescriptions.GET_STUDENT_FROM_POOL);
        } else {
            if(result.content === ServiceErrorMessages.MEMBER_ALREADY_HAS_ACTIVE_SALES)
                showTopAlert(strings.member.candidate.member_has_already_active_sales, "error");
            else if(result.content === ServiceErrorMessages.LIMIT_ERROR)
                showTopAlert(strings.member.candidate.limit_error, "error");
            else if(result.content === ServiceErrorMessages.DISTRIBUTION_STATUS_UNDISTRIBUTED)
                showTopAlert(strings.member.candidate.undistributed_error, "error");
            else if(result.content === ServiceErrorMessages.BRAND_ID_NOT_FOUND)
                showTopAlert(strings.member.candidate.brand_id_error, "error");
            else if(result.content === ServiceErrorMessages.BEST_POSSIBLE_MEMBER_NOT_FOUND)
                showTopAlert(strings.member.candidate.best_possible_member_error, "error");
            else if(result.content === ServiceErrorMessages.MEMBER_FORM_NOT_FOUND)
                showTopAlert(strings.member.candidate.member_form_error, "error");
            else if(result.content === ServiceErrorMessages.EMPLOYEE_NOT_FOUND)
                showTopAlert(strings.report.candidate_reports.returned_from_whatsapp_error_messages.employee_not_found, "error");
            else if(result.content === ServiceErrorMessages.EMPLOYEE_STATUS_MUST_BE_ACTIVE)
                showTopAlert(strings.report.candidate_reports.returned_from_whatsapp_error_messages.employee_status_must_be_active, "error");
            else if(result.content === ServiceErrorMessages.SALESPERSON_NOT_ASSIGNED)
                showTopAlert(strings.report.candidate_reports.returned_from_whatsapp_error_messages.salesperson_not_assigned, "error");
            else
                showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
        }
        setTimeout(() => setWaitForServiceResponse(waitForServiceResponse => false), 3000)
    }

    const getCandidateAuthority = () => {
        return (parseInt(employee.EmployeeTypeId) === 4) ||
            (parseInt(employee.EmployeeTypeId) === 5) ||
            (parseInt(employee.EmployeeTypeId) === 6);
    }

    useEffect(() => {

        let abortController = new AbortController();

        //Endpoint'e gitmeden loading'i true'ya çekip dil değiştiğinde de loading'in devreye girmesini sağlıyoruz
        if (sendRequest) {
            getMemberCandidate();
        }

        return () => {
            abortController.abort();
        }
    }, [detailSearch, sendRequest])

    useEffect(() => {
            setTmpDetailSearch(tmpDetailSearch => detailSearch);
            //document.addEventListener("visibilitychange",  handleVisibilityChange);
            firstVisit.current = false;

            // return () => {
            //     document.removeEventListener("visibilitychange",  handleVisibilityChange);
            // }

    }, []);

    useEffect(() => {
        if (Object.keys(tmpDetailSearch).length > 0) {
            setSendRequest(sendRequest => !areObjectsEqual(detailSearch, tmpDetailSearch));
        }
    }, [detailSearch, tmpDetailSearch])

    return (
        <>
            {permission && (
                <>
                    <CandidateDetailSearch
                        changeDetail={changeDetail}
                        loading={loading}
                    />
                    <SearchBar
                        changeValue={changeValue}
                        placeHolder={strings.search_bar.search_candidate}
                        handleRefresh={() => getMemberCandidate()}
                        isDisabled={loading}
                    />
                    {getCandidateAuthority() &&
                        (
                            <button
                                onClick={() => getCandidate(employee.EmployeeId)}
                                disabled={loading || waitForServiceResponse}
                                className={`${(loading || waitForServiceResponse) ? 'opacity-70 hover:opacity-70 cursor-not-allowed' : ''} button search-button w-[133px] -mt-1 mb-5 gap-3 text-white bg-blue border-blue`}
                            >
                                {!(loading || waitForServiceResponse) && (
                                    <>
                                        <span className="material-symbols-outlined">download</span>
                                        <p className="min-w-max">{strings.button.get_candidate}</p>
                                    </>
                                )}

                                {(loading || waitForServiceResponse) && <LoadingSpinner/>}
                            </button>
                        )
                    }
                    {!loading && <SearchCount count={totalRecord}/>}
                </>
            )}
            <PageTitle title={strings.sidebar.member.candidates}/>
            {permission ? (
                loading ?
                    (
                        <Loading/>
                    )
                    :
                    (
                        <>
                            <CandidateTable
                                data={data}
                                isFirstRequest={isFirstRequest}
                            />
                            <Pagination
                                totalCount={totalRecord}
                                limit={limit}
                                start={start}
                                setStart={setStart}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                setLoading={setLoading}
                                loadScreen={true}
                            />
                        </>
                    )
            ) : (<NotAuthorized/>)}

        </>
    );
}

export default Candidate
