import { getLanguageTypeByLanguageKey } from '../../components/Utility';
import { fetchApi } from '../index';

export const GetMemberTerms = async (memberId, language) => {

    const url = '/member/v1/term/get_member_terms/';

    const payload = {
        memberId: memberId,
        languageType: getLanguageTypeByLanguageKey(language)
    };
    
    return await fetchApi(url, payload);
}

//Member Detail eğitim paketleri kısmında öğrenci adını güncelleyen servis.
export const UpdateStudentName = async (model) => {

    const url = '/member/v1/term/update_student_name/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

//Member Detail eğitim paketleri kısmında dönem başlangıç tarihini güncelleyen servis.
export const UpdateBeginDate = async (model) => {

    const url = '/member/v2/term/term_update_begin_date/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

/*
    Member Detail eğitim paketleri kısmında öğrenci haklarını güncelleyen servis.
    1 - Postpone
    2 - Freeze
    3 - Missed
    4 - Teacher Hour Change
    5 - Additional Lesson
*/
export const UpdateTermRight = async (model) => {

    const url = '/member/v1/term_right/update_term_right/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

//Öğrencinin ilgili dönemini silen servis.
export const DeleteTerm = async (termId, createUserId) => {
    const url = '/member/v1/term/delete_term/';

    const payload = {
        termId: termId,
        createUserId: createUserId
    };

    return await fetchApi(url, payload);
}

//Öğrencinin kitaplarını ve ünitelerini getiren servis.
export const GetTermUnits = async (termId) => {
    const url = '/member/v1/term/get_term_units/';

    const payload = {
        termId: termId
    };

    return await fetchApi(url, payload);
}

//Öğrenciye ders eklemek ve silmek için kullanılan servis.
export const LessonAddDelete = async (model) => {
    const url = '/member/v1/term/lesson_add_delete/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

//Öğrencinin dönemini yedek eğitmene taşıyan servis.
export const MoveToSubTeacher = async (termId, createUserId) => {
    const url = '/member/v1/term/move_to_sub_teacher/';

    const payload = {
        termId: termId,
        createUserId: createUserId
    };

    return await fetchApi(url, payload);
}


//Kitaba göre üniteleri getiren servis.
export const GetBookUnits = async (bookId) => {
    const url = '/member/v1/term/book_unit_selectlist/';

    const payload = {
        bookId: bookId
    };

    return await fetchApi(url, payload);
}

//Kitap ve ünite güncelleyen servis.
export const UpdateTermScheduleBook = async (model) => {
    const url = '/member/v2/term/update_term_schedule_book/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

//Daily kitaplarda ünite güncellemek için kitap var mı yok mu onu döndüren servis.
export const CheckUnitUpdateDailyBook = async (bookUnitId) => {
    const url = '/member/v1/term/check_unit_update_daily_book/';

    const payload = {
        bookUnitId: bookUnitId
    };

    return await fetchApi(url, payload);
}

//Term - Arama Tipi modalında, arama tipi status'ünü true yapan servis.
export const CreateTermLessonApp = async (model) => {
    const url = '/member/v1/term_lesson_app/create_term_lesson_app/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

//Term - Arama Tipi modalında, arama tipi status'ünü false yapan servis.
export const UpdateTermLessonApp = async (termLessonAppId) => {
    const url = '/member/v1/term_lesson_app/update_term_lesson_app/';

    const payload = {
        termLessonAppId: termLessonAppId
    };

    return await fetchApi(url, payload);
}

//Term - Arama Tipi modalında, kullanıcının arama tiplerini listeleyen servis.
export const GetMemberLessonAppsWithTerm = async (termId, memberId) => {
    const url = '/member/v1/member_lesson_app/get_member_lesson_apps_with_term/';

    const payload = {
        termId: termId,
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

//Term - İlgili term'e ait öğrenci adını getiren servis.
export const GetTermStudentName = async (termId) => {
    const url = '/member/v1/term/get_student_name/';

    const payload = {
        termId: termId,
    };

    return await fetchApi(url, payload);
}

//Term - İlgili term'e ve hak türüne göre öğrencinin kalan haklarını getiren servis.
export const GetTermRight = async (termId, rightTypeId) => {
    const url = '/member/v1/term_right/get_term_right/';

    const payload = {
        termId: termId,
        rightTypeId: rightTypeId
    }

    return await fetchApi(url, payload);
}

//Term - İlgili term'e ait dönem başlangıç tarihini getiren servis.
export const GetTermBeginDateAndDay = async (termId) => {
    const url = '/member/v1/term/get_term_begin_date_and_day/';

    const payload = {
        termId: termId
    };

    return await fetchApi(url, payload);
}

//Term - İlgili term'e ait dönem başlangıç tarihini getiren servis.
export const GetTermEndDateAndDay = async (termId) => {
    const url = '/member/v1/term/get_term_end_date_and_day/';

    const payload = {
        termId: termId
    };

    return await fetchApi(url, payload);
}

//Term - İlgili term'e ait ders sayısı detaylarını getiren servis.
export const GetLessonCountDetails = async (termId) => {
    const url = '/member/v1/term/get_lesson_count_details/';

    const payload = {
        termId: termId
    };

    return await fetchApi(url, payload);
}

//Term - İlgili term'e ait ders saatini ve öğretmen adını getiren servis.
export const GetLessonTimeAndTeacher = async (termId) => {
    const url = '/member/v1/term/get_lesson_time_and_teacher/';

    const payload = {
        termId: termId
    };

    return await fetchApi(url, payload);
}

//Term - İlgili term'e ait ders geçmişini getiren servis.
export const GetLessonHistory = async (termId) => {
    const url = '/member/v1/term_schedule/term_schedule_status/';

    const payload = {
        termId: termId
    };

    return await fetchApi(url, payload);    
}

//Eğitmen saat değişikliği için müsait olup olmadığını kontrol edelim
export const TeacherLessonTimeChangeList = async (termId, sourceType) => {
    const url = '/member/v1/support/teacher_lesson_time_change_list/';

    const payload = {
        termId: termId,
        sourceType: sourceType
    };

    return await fetchApi(url, payload);    
}

//Eğitmen saat değişikliği için müsait olup olmadığını kontrol edelim
export const TeacherFreeTimeByRange = async (model) => {
    const url = '/member/v1/support/teacher_free_time_by_range/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);    
}

//Eğitmen saat değişikliği Teacher Sandy kurgusu için öğrencinin ders süresini getiren fonksiyon
export const GetLessonDurationByTermId = async (termId) => {
    const url = '/member/v1/term/get_lesson_duration/';

    const payload = {
        termId: termId
    };

    return await fetchApi(url, payload);
}

//Eğitmen saat değişikliği yapılan servise istek atıyoruz
export const TeacherLessonTimeChange = async (model) => {
    const url = '/member/v2/support/teacher_lesson_time_change/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);    
}

//Öğrencinin cuma günü freetalk alıp almadığını getiren servis.
export const GetIsFridayLessonFreeTalk = async (termId) => {
    const url = '/member/v1/term/get_is_friday_lesson_free_talk/';

    const payload = {
        termId: termId
    };

    return await fetchApi(url, payload);
}

//Ödeme işlemi başarılı olduktan sonra dönem
//oluşturmak için kullanılıyor
export const AddMemberTerm = async (model) => {
    const url = '/member/v3/term/add/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const GetCurrentProductDetail = async (termId) => {

    const url = '/member/v1/term/with_order_detail/';

    const payload = {
        termId: termId
    };

    return await fetchApi(url, payload);   
}

export const GetTermInformationByTermId = async (termId) => {
    const url = '/member/v1/term/get_term_information_by_term_id/';

    const payload = {
        termId: termId
    };

    return await fetchApi(url, payload);
}

//Ders ekleme için uygun zamanda olup olmadığını getiren servis
export const IsAvailableToAddLesson = async (termId) => {
    const url = '/member/v2/term/is_available_to_add_lesson/';

    const payload = {
        termId: termId
    };

    return await fetchApi(url, payload);
}


//Ders ekleme işlemi için kullanılan servis
export const LessonAdd = async (model) => {
    const url = '/member/v2/term/lesson_add/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

//termId'ye göre paketin ders gün sayısını getiren servis.
export const GetProductDayCountByTermId = async (termId) => {
    const url = '/member/v1/term/get_product_day_count/';

    const payload = {
        termId: termId
    };

    return await fetchApi(url, payload);
}

//Eğer açılan paket türü otomatik alınan demo ise MC numarası ile gidilecek
export const CheckAutoDemoAndMissedCallStatus = async (model) => {

    const url = '/member/v1/term/check_auto_demo_and_missed_call_status/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const GetAssignTeacherSandyStatus = async (termId) => {
    const url = '/member/v1/term_not_assign_teacher_sandy/get_assign_teacher_sandy_status/';

    const payload = {
        termId: termId
    };

    return await fetchApi(url, payload);
}

export const UpdateAssignTeacherSandyStatus = async (model) => {

    const url = '/member/v1/term_not_assign_teacher_sandy/update_assign_teacher_sandy_status/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}