import { useEffect, useState } from "react";
import { GetMemberActiveCampaign } from "../../../../../services/Member/MemberProfileService";
import { TextBadge } from "../../../../../components";
import { useLocalization } from "../../../../../hooks/Utils/useLocalization";
import useAuth from "../../../../../hooks/Log/useAuth";
import { ISOStringToDate } from "../../../../../components/Utility";

const MemberActiveCampaign = ({ memberId }) => {

    const strings = useLocalization();
    const { employee } = useAuth();

    const [activeCampaign, setActiveCampaign] = useState(null);

    const createAutoCampaignType = Object.freeze({
        "ADVERT": 1,
        "DEMO": 2,
        "REFERENCE_OR_BRANDING": 3,
        "HOURLY_DISCOUNT": 4,
        "OTHER": 5
    });

    useEffect(() => {
        if (memberId) {
            GetMemberActiveCampaign(memberId)
                .then(result => {
                    if(result.status === 200)
                        setActiveCampaign(JSON.parse(result.content));
                })
                .catch(error => console.error("Kampanya bilgisi alınamadı:", error));
        }
    }, [memberId]);

    const getCampaignText = (campaign) => {
        if (!campaign) return "";
        switch (campaign.createAutoCampaignType) {
            case createAutoCampaignType.HOURLY_DISCOUNT:
                return strings.member.member_detail.profile.member_campaign.hourly_discount
                    .replace("#discountRate#", +campaign.discountRate);
            case createAutoCampaignType.ADVERT:
                return strings.member.member_detail.profile.member_campaign.advert
                    .replace("#discountRate#", +campaign.discountRate);
            case createAutoCampaignType.OTHER:
                return strings.member.member_detail.profile.member_campaign.other_campaign
                    .replace("#discountRate#", +campaign.discountRate);
            default:
                return strings.member.member_detail.profile.member_campaign.active_campaign;
        }
    };

    if (!activeCampaign) return null;

    return (
          <TextBadge className="bg-green text-white h-6 max-w-max font-medium relative group cursor-default">
            <div className="flex gap-1 items-center">
                {strings.member.member_detail.profile.member_campaign.active_campaign}
                <svg
                    className={`fill-white opacity-60 h-1.5 w-1.5`}
                    viewBox="0 0 6 6"
                    aria-hidden="true"
                >
                    <circle cx="3" cy="3" r="3"/>
                </svg>
                %{activeCampaign.discountRate}
            </div>
            <div className="tooltip-top -top-[55px]">
                {getCampaignText(activeCampaign)}<br />
                {strings.member.member_detail.profile.member_campaign.last_date}: {ISOStringToDate(activeCampaign.campaignEndDate, employee.TimeZone)}
            </div>
          </TextBadge>
    );
}

export default MemberActiveCampaign;