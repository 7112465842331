import React from "react";
import { Ai } from "../../../../../../assets/svg";
import { Loading } from "../../../../../../components";

const SalesAiAnalysisModal = ({ title, content, loading, error, notFoundMessage, tryAgainMessage, onRetry }) => {
    const formatContent = (text) => {
        if (!text) return "";

        // ai'den </br> şeklinde gelen yazıları silip boşlukları düzenlemek için kullanılıyor
        let formattedText = text.replace(/\s*<\/br>/g, '\n');

        // ai'den gelen ** işaretleri arasındaki yazıları kalın yazı yapmak için kullanılıyor
        const parts = formattedText.split(/(\*\*.*?\*\*)/g);

        return parts.map((part, index) => {
            if (part.startsWith('**') && part.endsWith('**')) {
                return (
                    <strong key={index} className="font-bold">
                        {part.slice(2, -2)}
                    </strong>
                );
            }
            return <span key={index}>{part}</span>;
        });
    };

    return (
        <div className="flex flex-col gap-3 p-4">
            <div className="flex items-center gap-1">
                <img src={Ai} alt="ai" width={20} height={20} />
                <p className="font-bold py-3">{title}</p>
            </div>
            <hr />
            <div className="h-[calc(100vh-250px)] overflow-y-auto">
                {loading && <Loading />}
                {!loading && error && (
                    <div className="flex justify-center">
                        <button
                            type="button"
                            className="flex items-center gap-1 border border-dark-gray hover:bg-card h-6 max-w-max px-3 rounded-[10px]"
                            onClick={onRetry}
                        >
                            <span className="material-symbols-outlined text-[16px] font-bold">
                                sync
                            </span>
                            {tryAgainMessage}
                        </button>
                    </div>
                )}
                {!loading && !error && content.length === 0 ? (
                    <p className="text-center">{notFoundMessage}</p>
                ) : (
                    <div className="whitespace-pre-line leading-relaxed">
                        {formatContent(content)}
                    </div>
                )}
            </div>
        </div>
    );
};

export default SalesAiAnalysisModal;