import { fetchApi } from '../index';

export const GetMemberProfile = async (model) => {

    const url = '/member/v1/member/get_member_profile/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const GetFollowingsByMemberId = async (memberId, page = 0, limit = 50) => {

    const url = '/member/v1/member_follow/get_followings_by_member_id/';

    const payload = {
        memberId: memberId,
        page: page,
        limit: limit
    };

    return await fetchApi(url, payload);
}

export const GetPublicRegisteredContactsByMemberId = async (memberId, page = 0, limit = 50) => {

    const url = '/member/v1/member_follow/get_public_registered_contacts_by_member_id/';

    const payload = {
        memberId: memberId,
        page: page,
        limit: limit
    };

    return await fetchApi(url, payload);
}

export const GetReferrerContactsByMemberId = async (memberId, page = 0, limit = 50) => {

    const url = '/member/v1/member_follow/get_referrer_contacts_by_member_id/';

    const payload = {
        memberId: memberId,
        page: page,
        limit: limit
    };

    return await fetchApi(url, payload);
}

export const GetOtherContactsByMemberId = async (memberId, page = 0, limit = 5) => {

    const url = '/member/v1/member_follow/get_other_contacts_by_member_id/';

    const payload = {
        memberId: memberId,
        page: page,
        limit: limit
    };

    return await fetchApi(url, payload);
}

export const GetMemberNPSSubmission = async (memberId) => {

    const url = '/prime_nps/v1/member_nps_submission/all/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const UpdateMemberProfile = async (model) => {

    const url = '/member/v1/member/update_member_profile/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const AddOrUpdateMemberProfileNote = async (model) => {

    const url = '/member/v1/member_profile_note/add_or_update/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const GetMemberDemoRightCount = async (memberId) => {

    const url = '/member/v1/member_demo_right/get_member_demo_right_count/'

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const UpdateMemberDemoRightCount = async (memberId, rightCount) =>  {

    const url = '/member/v1/member_demo_right/update_right_count/'

    const payload = {
        memberId: memberId,
        rightCount: rightCount
    };

    return await fetchApi(url, payload);
}

export const CheckIsDemoStudent = async (memberId) => {

    const url = '/member/v1/member/check_is_demo_student/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const GetMemberActiveCampaign = async (memberId) => {

    const url = '/member/v1/campaign/get_member_active_campaign/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const GetMemberFamilyActivity = async (memberId) => {

    const url = '/member/v1/member_family/get_member_family_activity/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}