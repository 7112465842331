import { PageTitle } from "../../components";
import { useLocalization } from "../../hooks/Utils/useLocalization";
import React from "react";
import useAuth from '../../hooks/Log/useAuth';
import NotAuthorized from "../../components/NotAuthorized";

const FamilyPackageDashboard = () => {
    
    const strings = useLocalization();
    const { checkPermission, getPermission, employeeTypeId } = useAuth();
    const roles = getPermission();
    const permisson = checkPermission(roles, employeeTypeId);

    return (
        <>
            <PageTitle title={strings.sidebar.product_report.family_package_dashboard} />

            {permisson ? (
                <div className="flex justify-center mt-5">
                    <iframe title="product" width="1725" height="1725" src="https://lookerstudio.google.com/embed/reporting/19471f6e-338c-4880-87af-06bc4d27f9ba/page/4JD6D" frameBorder="0" allowFullScreen></iframe>
                </div>
            ) : (<NotAuthorized />)}

        </>
    )
}

export default FamilyPackageDashboard;