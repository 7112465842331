import classNames from "classnames";

const SwitchButton = (props) => {

    const { 
        state, 
        setState, 
        text, 
        loading = false, 
        classnames,
        infoButtonText = "",
        infoButtonClassnames = "",
        action = () => {},
        id = "switch-" + Math.random().toString(36).substr(2, 9)
    } = props;

    return (
        <div
            className={classNames("w-full md:w-[246px] flex items-center", {
                [classnames] : [!!classnames],
                "pointer-events-none opacity-70": loading
            })}
        >
            <input
                id={id}
                type="checkbox"
                className="hidden peer"
                checked={state}
                onChange={(e) => {
                    setState(e.target.checked);
                    action(e.target.checked);
                }}
            />
            <label
                htmlFor={id}
                className="relative mr-1 w-12 h-6 shrink-0 rounded-full bg-red peer-checked:bg-green cursor-pointer duration-500 before:switch-circle"
            ></label>
            <div className="flex gap-0.5 items-center">
                {infoButtonText.length > 0 && 
                    <div className="relative group">
                        <span className="material-symbols-outlined mt-1.5 text-[19px]">info</span>
                        <span className={classNames("tooltip-top", {
                            [infoButtonClassnames] : [!!infoButtonClassnames]
                        })}>{infoButtonText}</span>
                    </div>
                }
                <p className={`${state ? 'flex' : 'hidden'} animate-fadeIn`}>
                    {text.open}
                </p>
                <p className={`${state ? 'hidden' : 'flex'} animate-fadeIn`}>
                    {text.close}
                </p>
            </div>
        </div>
    )
}

export default SwitchButton;