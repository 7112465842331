import { useEffect, useRef, useState } from "react";
import DatePicker from 'react-datepicker';
import { DetailSearchLayout, Dropdown, DropdownSearch } from "../../../../components";
import { getDateWithDayDifference, getddMMyyyyDate } from '../../../../components/Utility';
import { DateRanges, DiscountRateRanges, GeneralStatusTypes } from "../../../../consts/Constants";
import { useLocalization } from "../../../../hooks/Utils/useLocalization";
import usePrimeData from "../../../../hooks/Utils/usePrimeData";

const CampaignDetailSearch = ({ changeDetail, loading, hasAdminRole }) => {

    const strings = useLocalization();
    const primeData = usePrimeData();

    //Bu bölüm belirli tarih aralığı seçmek için kullanılan değişkenlerin/fonksiyonların olduğu bölüm
    const datePickerRef1 = useRef();
    const datePickerRef2 = useRef();

    const [validBeginDate, setValidBeginDate] = useState(null);
    const [validEndDate, setValidEndDate] = useState(null);
    const [creationBeginDate, setCreationBeginDate] = useState(null);
    const [creationEndDate, setCreationEndDate] = useState(null);

    //Kampanyaları geçerlilik tarihin aralığına göre filtrelemek içindir.
    const handleValidDateRange = (dates) => {
        const [start, end] = dates;
        setValidBeginDate(start);
        setValidEndDate(end);
        if (start !== null && end !== null) {
            let tmpDateRange = { id: 8, name: getddMMyyyyDate(start) + " - " + getddMMyyyyDate(end) };
            validDateRangeList.push(tmpDateRange);
            changeDetail?.({ validBeginDate: getddMMyyyyDate(start), validEndDate: getddMMyyyyDate(end) });
            setValidBeginDate(null);
            setValidEndDate(null);
            setValidDateRange(dateRange => validDateRangeList[8]);
            datePickerRef1.current.classList.add("hidden");
        }
    };

    //Kampanyaları oluşturulma tarihin aralığına göre filtrelemek içindir.
    const handleCreationDateRange = (dates) => {
        const [start, end] = dates;
        setCreationBeginDate(start);
        setCreationEndDate(end);
        if (start !== null && end !== null) {
            let tmpDateRange = { id: 8, name: getddMMyyyyDate(start) + " - " + getddMMyyyyDate(end) };
            creationDateRangeList.push(tmpDateRange);
            changeDetail?.({ creationBeginDate: getddMMyyyyDate(start), creationEndDate: getddMMyyyyDate(end) });
            setCreationBeginDate(null);
            setCreationEndDate(null);
            setCreationDateRange(dateRange => creationDateRangeList[8]);
            datePickerRef2.current.classList.add("hidden");
        }
    };
    //Bu bölüm belirli tarih aralığı seçmek için kullanılan değişkenlerin/fonksiyonların olduğu bölüm

    const firstValidDateRange = { id: -1, name: strings.detail_search_titles.valid_date_range};
    const validDateRangeList = [firstValidDateRange, ...DateRanges];

    const firstCreationDateRange = { id: -1, name: strings.detail_search_titles.creation_date_range};
    const creationDateRangeList = [firstCreationDateRange, ...DateRanges];

    const firstBrand = { id: -1, name: strings.detail_search_titles.brands };
    const brandList = [firstBrand, ...primeData.brandList];

    const firstStatus = { id: -1, name: strings.detail_search_titles.status};
    const statusList = [firstStatus, ...GeneralStatusTypes];

    const firstDiscountRate = { id: -1, name: strings.member.member_detail.moneybox.choose_discount_rate };
    const discountRateList = [firstDiscountRate, ...DiscountRateRanges];

    const [validDateRange, setValidDateRange] = useState(firstValidDateRange);
    const [creationDateRange, setCreationDateRange] = useState(firstCreationDateRange);
    const [brand, setBrand] = useState(firstBrand);
    const [status, setStatus] = useState(firstStatus);
    const [discountRateRange, setDiscountRateRange] = useState(firstDiscountRate);

    const firstVisit = useRef(true);

    //Kampanyaları geçerlilik tarihin aralığına göre filtrelemek içindir.
    useEffect(() => {
        //Sayfa ilk kez yüklenmiyorsa işlemi yap
        //Servise birden çok gez gitmesini engelliyoruz
        if (!firstVisit.current) {
            let todayDate = new Date();
            let tmpBeginDate = '';
            let tmpEndDate = '';

            datePickerRef1.current.classList.add("hidden");

            switch (validDateRange.id) {
                case 1:
                    //Bugün seçildiyse
                    tmpBeginDate = getddMMyyyyDate(todayDate);
                    tmpEndDate = tmpBeginDate;
                    break;
                case 2:
                    //Dün seçildiyse
                    tmpBeginDate = getddMMyyyyDate(getDateWithDayDifference(todayDate, 1));
                    tmpEndDate = tmpBeginDate;
                    break;
                case 3:
                    //Son 7 gün seçildiyse
                    tmpEndDate = getddMMyyyyDate(todayDate);
                    tmpBeginDate = getddMMyyyyDate(getDateWithDayDifference(todayDate, 6));
                    break;
                case 4:
                    //Son 30 gün seçildiyse
                    tmpEndDate = getddMMyyyyDate(todayDate);
                    tmpBeginDate = getddMMyyyyDate(getDateWithDayDifference(todayDate, 30));
                    break;
                case 5:
                    //Bu ay seçildiyse
                    tmpBeginDate = getddMMyyyyDate(new Date(todayDate.getFullYear(), todayDate.getMonth(), 1));
                    tmpEndDate = getddMMyyyyDate(new Date(todayDate.getFullYear(), todayDate.getMonth() + 1, 0));
                    break;
                case 6:
                    //Geçen ay seçildiyse
                    tmpBeginDate = getddMMyyyyDate(new Date(todayDate.getFullYear(), todayDate.getMonth() - 1, 1));
                    tmpEndDate = getddMMyyyyDate(new Date(todayDate.getFullYear(), todayDate.getMonth(), 0));
                    break;
                case 7:
                    //Tarih aralığı seçildiyse
                    datePickerRef1.current.classList.remove('hidden');
                    break;
            }

            (validDateRange.id < 7) && changeDetail?.({ validBeginDate: tmpBeginDate, validEndDate: tmpEndDate });

            firstVisit.current = false;
        }
    }, [validDateRange])

    //Kampanyaları oluşturulma tarihin aralığına göre filtrelemek içindir.
    useEffect(() => {
        //Sayfa ilk kez yüklenmiyorsa işlemi yap
        //Servise birden çok gez gitmesini engelliyoruz
        if (!firstVisit.current) {
            let todayDate = new Date();
            let tmpBeginDate = '';
            let tmpEndDate = '';

            datePickerRef2.current.classList.add("hidden");

            switch (creationDateRange.id) {
                case 1:
                    //Bugün seçildiyse
                    tmpBeginDate = getddMMyyyyDate(todayDate);
                    tmpEndDate = tmpBeginDate;
                    break;
                case 2:
                    //Dün seçildiyse
                    tmpBeginDate = getddMMyyyyDate(getDateWithDayDifference(todayDate, 1));
                    tmpEndDate = tmpBeginDate;
                    break;
                case 3:
                    //Son 7 gün seçildiyse
                    tmpEndDate = getddMMyyyyDate(todayDate);
                    tmpBeginDate = getddMMyyyyDate(getDateWithDayDifference(todayDate, 6));
                    break;
                case 4:
                    //Son 30 gün seçildiyse
                    tmpEndDate = getddMMyyyyDate(todayDate);
                    tmpBeginDate = getddMMyyyyDate(getDateWithDayDifference(todayDate, 30));
                    break;
                case 5:
                    //Bu ay seçildiyse
                    tmpBeginDate = getddMMyyyyDate(new Date(todayDate.getFullYear(), todayDate.getMonth(), 1));
                    tmpEndDate = getddMMyyyyDate(new Date(todayDate.getFullYear(), todayDate.getMonth() + 1, 0));
                    break;
                case 6:
                    //Geçen ay seçildiyse
                    tmpBeginDate = getddMMyyyyDate(new Date(todayDate.getFullYear(), todayDate.getMonth() - 1, 1));
                    tmpEndDate = getddMMyyyyDate(new Date(todayDate.getFullYear(), todayDate.getMonth(), 0));
                    break;
                case 7:
                    //Tarih aralığı seçildiyse
                    datePickerRef2.current.classList.remove('hidden');
                    break;
            }

            (creationDateRange.id < 7) && changeDetail?.({ creationBeginDate: tmpBeginDate, creationEndDate: tmpEndDate });

            firstVisit.current = false;
        }
    }, [creationDateRange])

    useEffect(() => {
        //Sayfa ilk kez yüklenmiyorsa işlemi yap
        //Servise birden çok gez gitmesini engelliyoruz
        if (!firstVisit.current) {
            changeDetail?.({ brandId: brand?.id });
            firstVisit.current = false;
        }
    }, [brand])

    useEffect(() => {
        //Sayfa ilk kez yüklenmiyorsa işlemi yap
        //Servise birden çok gez gitmesini engelliyoruz
        if (!firstVisit.current) {
            changeDetail?.({ status: status?.id });
            firstVisit.current = false;
        }
    }, [status])

    useEffect(() => {
        if (!firstVisit.current) {
            let tmpDiscountBeginRate = discountRateRange.id * 5;
            let tmpDiscountEndRate = tmpDiscountBeginRate + 5;
            changeDetail?.({ discountBeginRate: tmpDiscountBeginRate, discountEndRate: tmpDiscountEndRate });
        }

        firstVisit.current = false;
    }, [discountRateRange]);

    /*useEffect(() => {
        if(brandList.length > 1) {
            const selectedBrand = brandList.find(brand => brand.id === parseInt(employee.BrandId))
            setBrand(brand => selectedBrand)
        }
    }, [primeData.brandList])*/

    useEffect(() => {
        //Sayfa yüklendikten sonra false yaparak her değişiklikte
        //servisin ziyaret edilmesini sağlıyoruz
        firstVisit.current = false;
    }, [])

    return(
        <>
            <DetailSearchLayout
                isDisabled={loading}
                children={
                    <div  className="!flex gap-x-4 gap-y-5 max-md-lg:justify-center flex-wrap">
                        {hasAdminRole && <Dropdown
                            title={strings.detail_search_titles.brands}
                            data={brandList}
                            selectedData={brand}
                            setSelectedData={setBrand}
                            isDisabled={loading}
                        />}

                        <div className="w-full md:max-w-[246px] relative">
                            <Dropdown
                                title={strings.detail_search_titles.valid_date_range}
                                data={validDateRangeList}
                                selectedData={validDateRange}
                                setSelectedData={setValidDateRange}
                                isDisabled={loading}
                            />
                            <div ref={datePickerRef1} className='absolute left-0 top-[75px] z-10 hidden'>
                                <button className='absolute button search-button !h-[25px] !p-2 top-[-28px] bg-red text-white focus:!border-none right-0'
                                    onClick={() => datePickerRef1.current.classList.add("hidden")}>X</button>
                                <DatePicker
                                    selected={validBeginDate}
                                    onChange={handleValidDateRange}
                                    onClickOutside={() => datePickerRef1.current.classList.add("hidden")}
                                    startDate={validBeginDate}
                                    endDate={validEndDate}
                                    selectsRange
                                    inline
                                />
                            </div>
                        </div>
                        <div className="w-full md:max-w-[246px] relative">
                            <Dropdown
                                title={strings.detail_search_titles.creation_date_range}
                                data={creationDateRangeList}
                                selectedData={creationDateRange}
                                setSelectedData={setCreationDateRange}
                                isDisabled={loading}
                            />
                            <div ref={datePickerRef2} className='absolute right-0 top-[75px] z-10 hidden'>
                                <button className='absolute button search-button !h-[25px] !p-2 top-[-28px] bg-red text-white focus:!border-none right-0'
                                    onClick={() => datePickerRef2.current.classList.add("hidden")}>X</button>
                                <DatePicker
                                    selected={creationBeginDate}
                                    onChange={handleCreationDateRange}
                                    onClickOutside={() => datePickerRef2.current.classList.add("hidden")}
                                    startDate={creationBeginDate}
                                    endDate={creationEndDate}
                                    selectsRange
                                    inline
                                />
                            </div>
                        </div>
                        <Dropdown
                            title={strings.detail_search_titles.status}
                            data={statusList}
                            selectedData={status}
                            setSelectedData={setStatus}
                            isDisabled={loading}
                        />
                        <DropdownSearch
                            title={strings.detail_search_titles.discount_ranges}
                            inputPlaceholder={strings.detail_search_titles.search_discount_rate}
                            data={discountRateList}
                            selectedData={discountRateRange}
                            setSelectedData={setDiscountRateRange}
                            isDisabled={loading}
                        />
                    </div>
                }
            />
        </>
    );
}

export default CampaignDetailSearch;
