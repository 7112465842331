import React, { useEffect, useState } from "react";
import { LoadingSpinner } from "../../../../../components";
import {
    convertSecondsToMinutesAndSeconds,
    isItSpecified,
    ISODateToReadableDateWithSeconds,
    maskPhoneNumber
} from "../../../../../components/Utility";
import { useLocalization } from "../../../../../hooks/Utils/useLocalization";
import { GetRecordingInfo } from "../../../../../services/Alotech/AlotechService";
import classNames from "classnames";
import useAuth from "../../../../../hooks/Log/useAuth";
import { getButtonPermission } from "../../../../../rights/Utility";

const CallRecordsTableRow = ({ callInfo, esneksRecordingPath, openModal, onSelectRecord }) => {

    const strings = useLocalization();
    const notSpecified = strings.errors.it_is_not_specified;

    const { employeeTypeId, checkPermission } = useAuth();

    const [loading, setLoading] = useState(true);
    const [callRecord, setCallRecord] = useState("");
    const [isChecked, setIsChecked] = useState(false);

    const readTranscribeButtonRoles = getButtonPermission({ group: "memberDetailCallRecords", name: "readTranscribe" });
    const readTranscribePermission = checkPermission(readTranscribeButtonRoles, employeeTypeId);

    //Arama durumuna göre localization ve className belirleniyor.
    const getAnswerStatusClassnames = (answerStatus) => {

        let string;
        let className;

        switch (answerStatus) {
            case "NOANSWER":
                string = strings.member.member_detail.call_records.no_answer
                className = "text-red"
                break;
            case "ANSWER":
                string = strings.member.member_detail.call_records.answer
                className = "text-green"
                break;
            case "BUSY":
                string = strings.member.member_detail.call_records.busy
                className = "text-warning"
                break;
            case "CANCEL":
                string = strings.member.member_detail.call_records.cancel
                className = "text-danger"
                break;
            case "CONGESTION":
                string = strings.member.member_detail.call_records.congestion
                className = "text-danger"
                break;
            default:
                string = isItSpecified(answerStatus, notSpecified)
                className = "text-base-text"
        }

        return {
            string: string,
            className: className
        }
    }

    const getCallRecordLink = async (recordingUrl, callCompany) => {
        try {
            if(callCompany === "esneks"){
                if(!recordingUrl) {
                    setCallRecord("");
                    setLoading(false);
                    return;
                } 

                const esneksBaseCallRecordUrl = "https://konusarakogren.3cx.com.tr:4444/downloadrecord";
                const encodedBaseDir = encodeURIComponent(esneksRecordingPath + "/");
                const splittedCallRecordUrl = recordingUrl.split("/");
                const encodedAgentNumberDir = encodeURIComponent(splittedCallRecordUrl[0] + "/") + "/";
                const splittedCallRecordUrlSecondPart = splittedCallRecordUrl[1].split("_");
                const encodedAgentName = encodeURIComponent(encodeURIComponent(splittedCallRecordUrlSecondPart[0]));
                const callRecordUrl = `${esneksBaseCallRecordUrl}/${encodedBaseDir}${encodedAgentNumberDir}${encodedAgentName}_${splittedCallRecordUrlSecondPart[1]}_${splittedCallRecordUrlSecondPart[2]}`;
                
                // Ses dosyasını blob olarak indir
                const response = await fetch(callRecordUrl);
                const blob = await response.blob();
                const url = URL.createObjectURL(blob);
                setCallRecord(url);
            } else { 
                const result = await GetRecordingInfo(recordingUrl);
                const response = await fetch(result.audioUrl);
                const blob = await response.blob();
                const url = URL.createObjectURL(blob);
                setCallRecord(url);
            }

            setLoading(false);
        } catch (error) {
            console.error("Error loading audio:", error);
            setLoading(false);
        }
    }

    useEffect(() => {
        if(callInfo?.callDuration > 0)
            getCallRecordLink(callInfo?.recordingUrl, callInfo?.callCompany);
        else
            setLoading(false)
    }, [])

    // Component unmount olduğunda URL'i temizle
    useEffect(() => {
        return () => {
            if (callRecord) {
                URL.revokeObjectURL(callRecord);
            }
        };
    }, [callRecord]);

    const handleCheckboxChange = () => {
        const newCheckedState = !isChecked;
        setIsChecked(newCheckedState);
        
        // Seçim durumu ve kayıt bilgileri parent'a gönderiliyor
        onSelectRecord({
            id: callInfo?.id,
            callerName: callInfo?.employeeName,
            callDate: callInfo?.callDate,
            callDuration: callInfo?.callDuration,
            isSelected: newCheckedState
        });
    }

    return (
        <tr className="bg-white hover:bg-[#f0f0f0]">
            <td className="td h-[60px] align-middle">
                <div className="flex items-center justify-center">
                    <input
                        id={`checkbox_${callInfo?.id}`}
                        type="checkbox"
                        className="hidden peer"
                        checked={isChecked}
                        disabled={!callInfo?.haveTranscribe || !readTranscribePermission}
                        onChange={handleCheckboxChange}
                    />
                    <label
                        htmlFor={`checkbox_${callInfo?.id}`}
                        className={classNames(
                            "w-7 h-7 rounded-[3px] cursor-pointer duration-500 flex items-center justify-center",
                            isChecked ? "bg-green border border-green text-white" : "border border-[#aaaaaa]",
                            !callInfo?.haveTranscribe && "opacity-50 pointer-events-none bg-light-gray"
                        )}
                    >
                    <span className={classNames('text-inherit text-[24px] material-symbols-outlined animate-fadeIn font-bold', { 
                        'hidden': !isChecked 
                        })}>
                            done
                        </span>
                    </label>
                </div>
            </td>
            <td className="td h-[60px] align-middle">
                <div className="flex items-center">
                    <p className={`${getAnswerStatusClassnames(callInfo?.disposition).className} font-bold text-[14px]`}>
                        {getAnswerStatusClassnames(callInfo?.disposition).string}
                    </p>
                </div>
            </td>
            <td className="td h-[60px] align-middle">
                <div className="flex items-center">
                    <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
                        person
                    </span>
                    <p className="group relative">
                        {isItSpecified(callInfo?.employeeName, notSpecified)}
                        {callInfo?.callerId && (
                            <span className="tooltip-top">
                                {maskPhoneNumber(`+9${callInfo?.callerId}`)}
                            </span>
                        )}
                    </p>
                </div>
                <hr className="my-1" />
                <div className="flex items-center">
                    <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
                        timer
                    </span>
                    <p className="min-w-max">
                        {convertSecondsToMinutesAndSeconds(callInfo?.callDuration, strings)}
                    </p>
                </div>
                <hr className="my-1" />
                <div className="flex items-center">
                    <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
                        calendar_month
                    </span>
                    <p className="min-w-max">
                        {ISODateToReadableDateWithSeconds(callInfo?.callDate)}
                    </p>
                </div>
            </td>
            <td className="td h-[60px] align-middle">
                <p className="badge">
                    {callInfo?.callCompany === "esneks" ? "Esneks/3CX" : "Alotech"}
                </p>
            </td>
            <td className="td h-[60px] align-middle">
                <div className="w-full min-w-[300px]">
                    {callRecord && callRecord.length > 0 ? (
                        <div className="flex items-center gap-2">
                            {callInfo?.haveTranscribe && 
                                <div className="group">
                                    <button 
                                        type="button"
                                        disabled={!readTranscribePermission}
                                        className={classNames("flex-shrink-0 flex items-center rounded-full border border-dark-gray p-1 relative", {
                                            "opacity-50 cursor-not-allowed pointer-events-none": !readTranscribePermission
                                        })}
                                        onClick={() => openModal("readTranscribe", {
                                            callId: callInfo?.id,
                                            callerName: callInfo?.employeeName,
                                            callDate: callInfo?.callDate,
                                            callDuration: callInfo?.callDuration
                                        })}    
                                    >
                                        <span className="material-symbols-outlined text-[19px]">
                                            description
                                        </span>
                                        <div className="tooltip-top">
                                            {readTranscribePermission ? strings.member.member_detail.call_records.read_transcript : strings.errors.not_authorized}
                                        </div>
                                    </button>
                                </div>
                            }
                            <audio
                                className="w-full min-w-[200px]"
                                controls
                                preload="metadata"
                            >
                                <source
                                    src={callRecord}
                                    type="audio/mp3"
                                />
                                Your browser does not support the audio element.
                            </audio>
                        </div>
                    ) : (
                        <>
                            {loading && <LoadingSpinner />}

                            {!loading && (
                                <div className="flex items-center">
                                    <span className="material-symbols-outlined mr-2 text-[19px] text-red">
                                        music_off
                                    </span>
                                    <span>{strings.member.member_detail.call_records.call_record_not_found}</span>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </td>
        </tr>
    )
}

export default CallRecordsTableRow;
