import { useEffect, useState } from "react";
import { useLocalization } from "../../../../../../../../hooks/Utils/useLocalization";
import { SwitchButton } from "../../../../../../../../components";
import { GetAssignTeacherSandyStatus, UpdateAssignTeacherSandyStatus } from "../../../../../../../../services/Term/TermService";
import { useParams } from "react-router-dom";

const TeacherSandyStatus = ({ termId, employeeId }) => {

    const strings = useLocalization();

    const { memberId } = useParams();

    const [teacherSandyStatus, setTeacherSandyStatus] = useState(false);
    const [loading, setLoading] = useState(false);

    const updateTeacherSandyStatus = (status) => {
        UpdateAssignTeacherSandyStatus({
            memberId: memberId,
            termId: termId,
            status: status ? 1 : 0,
            createUserId: employeeId
        })
            .then(result => {
                if(result.status === 200) 
                    setTeacherSandyStatus(JSON.parse(result.content) === 1);
                else 
                    setTeacherSandyStatus(false);
            })
            .catch();
    }

    useEffect(() => {
        if(termId) {
            setLoading(true);
            GetAssignTeacherSandyStatus(termId)
                .then(result => {
                    if(result.status === 200) {
                        const resultContent = JSON.parse(result.content);
                        // 1 ise true, 0 ise false
                        setTeacherSandyStatus(resultContent === 1);
                    }

                    setLoading(false);
                })
                .catch();
        }
    }, [termId]);

    return (
        <div className="w-full mt-4 ml-2">
            <SwitchButton
                state={teacherSandyStatus}
                setState={setTeacherSandyStatus}
                text={{ open: strings.member.member_detail.terms.modal.teacher_sandy_status_open, close: strings.member.member_detail.terms.modal.teacher_sandy_status_close }}
                classnames="!w-full"
                loading={loading}
                infoButtonText={strings.member.member_detail.terms.modal.teacher_sandy_info}
                infoButtonClassnames="-top-[90px]"
                action={updateTeacherSandyStatus}
            />
        </div>
    )
}

export default TeacherSandyStatus;