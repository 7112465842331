import tr from 'date-fns/locale/tr';
import React, {useEffect, useRef, useState} from "react";
import DatePicker, {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {useParams} from "react-router-dom";
import {
  Dropdown,
  LoadingSpinner,
  MemberInformations,
  PageTitle,
  showCenteredAlertTwoButtons,
  showTopAlert,
  TimePicker
} from "../../../../../../components";
import {fullDateToShortDateWithAdjustTimezone} from "../../../../../../components/Utility";
import useAuth from "../../../../../../hooks/Log/useAuth";
import {useLocalization} from "../../../../../../hooks/Utils/useLocalization";
import usePrimeData from "../../../../../../hooks/Utils/usePrimeData";
import {GetReminderListByMemberId, SetReminderService} from "../../../../../../services/Member/MemberFormService";
import {ServiceErrorMessages} from "../../../../../../consts/Constants";
import {ReminderAccordion} from "../../../index";
import classNames from "classnames";
import useDataLayer from "../../../../../../hooks/Log/useDataLayer";
import {CustomEventDescriptions, CustomEvents} from "../../../../../../consts/DataLayer";

const SetReminder = () => {

  registerLocale('tr', tr);
  const strings = useLocalization();

  const dataLayer = useDataLayer();
  const primeData = usePrimeData();
  const { memberId } = useParams() || false;
  const { employee } = useAuth();

  const errorRef = useRef();
  const noteRef = useRef();

  const firstReminderReason = { id: -1, name: strings.member.member_detail.candidate_operations.choose_reminder_reason };
  const reasonList = [firstReminderReason, ...primeData.reminderReasonList];

  const SetReminderResult = Object.freeze({
    CREATED: 1,
    UPDATED: 2
  });

  const [postponeDate, setPostponeDate] = useState(new Date());
  const [selectedReminderReason, setSelectedReminderReason] = useState(firstReminderReason);
  const [selectedTime, setSelectedTime] = useState("");
  const [note, setNote] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [activeReminderList, setActiveReminderList] = useState([]);
  const [passiveReminderList, setPassiveReminderList] = useState([]);
  const [reminderListLoading, setReminderListLoading] = useState(false);

  const submitHandle = async e => {
    e.preventDefault();

    let valid = true;

    //Hatanın içini boşaltıyoruz.
    errorRef.current.innerText = '';

    if (selectedReminderReason.id === -1) {
      valid = false;
      errorRef.current.innerText = strings.member.member_detail.candidate_operations.set_reminder_error;
    }

    if (valid) {
      showCenteredAlertTwoButtons(
          strings.alert.centered_alert_two_buttons.title,
          strings.member.member_detail.candidate_operations.set_reminder_question,
          strings.button.save,
          strings.button.cancel,
          sendToEndPoint
      );
    }
  };

  const sendToEndPoint = async () => {
    setLoading(true);

    let splitedTime = selectedTime.split(":");
    
    const model = {
      memberId: memberId,
      callDate: fullDateToShortDateWithAdjustTimezone(postponeDate, splitedTime[0], splitedTime[1]),
      note: note.length === 0 ? selectedReminderReason.name : selectedReminderReason.name + ' - ' + note,
      memberNoteTitleId: selectedReminderReason.id,
      createUserId: employee.EmployeeId,
    };

    const result = await SetReminderService(model);

    if (result.status === 200) {
      const resultContent = JSON.parse(result.content);

      const successMessage = resultContent === SetReminderResult.CREATED 
        ? strings.member.member_detail.candidate_operations.set_reminder_success 
        : strings.member.member_detail.candidate_operations.set_reminder_updated_success;

      showTopAlert(successMessage, "success");

      //DATA LAYER LOGGED
      dataLayer.useCustomEvents(CustomEvents.EMPLOYEE_EVENTS, CustomEventDescriptions.SET_REMINDER);

      setTimeout(() => { window.location.reload()}, 1500);
    } else {

      if(result.content === ServiceErrorMessages.DUPLICATE_REMINDER_ERROR) {
        showTopAlert(strings.member.member_detail.candidate_operations.duplicate_reminder_error, "error");
      } else if(result.content === ServiceErrorMessages.CALL_DATE_PAST_ERROR) {
        showTopAlert(strings.member.member_detail.candidate_operations.call_date_past_error, "error");
      } else if(result.content === ServiceErrorMessages.ALREADY_DATE_AND_TIME_ERROR) {
        showTopAlert(strings.member.member_detail.candidate_operations.already_date_and_time_error, "error");
      } else {
        showTopAlert(strings.errors.an_error_occurred, "error");
      }
    }

    setLoading(false);
  }

  //Öğrenciye kurulan hatırlatma listesi alınıyor.
  const getReminderList = async (memberId) => {
    setReminderListLoading(reminderListLoading => true);

    const result = await GetReminderListByMemberId(memberId);

    if(result.status === 200){
      let resultContent = JSON.parse(result.content);

      setActiveReminderList(resultContent?.activeReminders);
      setPassiveReminderList(resultContent?.updatedReminders);
    }

    setReminderListLoading(false);
  }

  useEffect(() => {
    if(memberId.length > 0)
      getReminderList(memberId);
  }, [memberId]);

  return (
      <>
        <PageTitle title={strings.member.member_detail.header.content.set_reminder}/>
        <MemberInformations memberId={memberId} viewStatus={['name', 'phone', 'email']} />
        <form onSubmit={submitHandle}>
          <div className="flex flex-col lg:flex-row lg:justify-between">
            <div className="mt-5 w-full">
              <div className="flex flex-col px-[10px] pb-0">
                <label htmlFor="discount_start_date">
                  {strings.member.member_detail.candidate_operations.postpone_date} <span className="text-red">*</span>
                </label>
                <div className="relative">
                  <DatePicker
                      locale="tr"
                      dateFormat="dd/MM/yyyy"
                      className="form-input w-full"
                      selected={postponeDate}
                      onClickOutside={() => setIsOpen(false)}
                      onChange={(date) => {
                        setPostponeDate(date);
                        setIsOpen(false)
                      }}
                      placeholderText={strings.form_info.date_placeholder}
                      open={isOpen}
                      onFocus={() => setIsOpen(true)}
                      minDate={new Date()}
                      readOnly={true}
                  />
                  <button type="button" onClick={() => setIsOpen(!isOpen)}>
                  <span className="material-symbols-outlined absolute right-6 top-2 text-lg">
                    calendar_today
                  </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="mt-5 w-full">
              <div className="flex flex-col px-[10px] pb-0">
                <label htmlFor="discount_type">
                  {strings.member.member_detail.candidate_operations.postpone_time} <span className="text-red">*</span>
                </label>
                {/* Seçili tarih eğer bugün ise sadece şuanki zamandan sonrası yazdırılıyor.
                    Bugün değil ise tüm saat ve dakikalar yazdırılıyor. */}
                {/* .setHours(0, 0, 0, 0) ile date formatından saat-dakika vs çıkarılıp sadece date
                  alanı karşılaştırılıyor. */}
                <TimePicker
                    listAllHours={!(new Date().setHours(0, 0, 0, 0) === postponeDate.setHours(0, 0, 0, 0))}
                    setSelectedTime={setSelectedTime}
                />
              </div>
            </div>
            <div className="mt-5 w-full">
              <div className="flex flex-col px-[10px] pb-0">
                <label htmlFor="discount_type">
                  {strings.member.member_detail.candidate_operations.reminder_reason} <span
                    className="text-red">*</span>
                </label>
                <Dropdown
                    classnames="md:max-w-full"
                    data={reasonList}
                    selectedData={selectedReminderReason}
                    setSelectedData={setSelectedReminderReason}
                />
                <span
                    ref={errorRef}
                    className="text-danger field-validation-valid"
                >
              </span>
              </div>
            </div>
          </div>
          <div className="flex flex-col px-[10px] pb-0 mt-5 w-full">
            <label className="font-bold mb-2" htmlFor="note">
              {strings.member.member_detail.candidate_operations.note}
            </label>
            <textarea
                ref={noteRef}
                id="note"
                className="border rounded-lg outline-0 focus:border-blue py-3 px-2 w-full"
                type="text"
                rows="3"
                onChange={(e) => setNote(e.target.value)}
            ></textarea>
          </div>
          <button
              type="submit"
              disabled={loading}
              className={classNames("button search-button gap-3 text-white bg-blue border-blue mt-7 mx-auto px-10 w-[125px]", {
                "!opacity-70 cursor-not-allow" : loading
              })}
          >
            {loading && <LoadingSpinner />}
            {!loading && strings.button.save}
          </button>
        </form>

        <ReminderAccordion
            type="active"
            reminderList={activeReminderList}
            loading={reminderListLoading}
        />
        <ReminderAccordion
            type="passive"
            reminderList={passiveReminderList}
            loading={reminderListLoading}
        />
      </>
  );
};

export default SetReminder;
