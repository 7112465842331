import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { closeMemberDetailAccordion, toggleMemberDetailAccordion } from '../../../../components';

const Accordion = (props) => {

    const { title, button, content, isOpen = false, outSideClose, iconContent } = props;

    const generalRef = useRef();
    const contentRef = useRef();
    const arrowRef = useRef();
    const textRef = useRef();

    const [isOutside, setIsOutside] = useState(false);
    const [isAccordionOpen, setIsAccordionOpen] = useState(isOpen);

    const toggleAccordion = () => {
        setIsAccordionOpen(!isAccordionOpen);
        toggleMemberDetailAccordion(generalRef, contentRef, arrowRef, textRef);
    };

    useEffect(() => {
        if (outSideClose && isOutside) {
            closeMemberDetailAccordion(generalRef, contentRef, arrowRef, textRef);
        }
    }, [isOutside])

    return (
        <>
            <OutsideClickHandler onOutsideClick={() => setIsOutside(true)}>
                <div
                    className="relative z-0 mt-5"
                    onClick={() => setIsOutside(false)}
                >
                    <div
                        ref={generalRef}
                        className={classNames("overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto w-full px-5 cursor-pointer bg-card hover:bg-light-gray focus:bg-light-gray rounded-t-md rounded-b-md border border-[#ebecec] flex gap-x-5 justify-between items-center shadow-content", {
                            "!rounded-b-0": arrowRef?.current?.classList.contains("text-blue")
                        })}
                    >
                        <div
                            className="flex items-center gap-1 w-full h-[65px] justify-between"
                            onClick={toggleAccordion}
                        >
                            <div className="flex items-center justify-center gap-1">
                                <span ref={arrowRef} className={`${isAccordionOpen ? 'text-blue' : '-rotate-90'} material-symbols-outlined text-[28px] transform transition duration-500`}>
                                    expand_more
                                </span>
                                <p ref={textRef} className={`${isAccordionOpen ? 'text-blue' : ''} font-bold transform transition duration-500 min-w-max`}> {title}</p>
                            </div>
                            {iconContent}
                        </div>
                        <div className='flex gap-2 max-w-max min-w-max mr-5 group'>
                            {button}
                        </div>
                    </div>

                    {/* <!-- Detay Content --> */}
                    <div ref={contentRef} className={classNames("px-4 rounded-b-md gap-x-4 gap-y-5 flex-wrap max-md-lg:justify-center bg-white shadow-content transition-max-height duration-500", {
                        "max-h-max animate-fadeIn py-8 border border-[#ebebeb] border-t-0": isAccordionOpen,
                        "max-h-0 animate-fadeOut": !isAccordionOpen
                    })}>
                        {isAccordionOpen && (
                            <div>
                                {content}
                            </div>
                        )}
                    </div>
                </div>
            </OutsideClickHandler>
        </>
    )
}

export default Accordion;