import { useEffect, useRef, useState } from "react";
import { DetailSearchLayout, Dropdown } from "../../../components";
import { useLocalization } from "../../../hooks/Utils/useLocalization";
import usePrimeData from "../../../hooks/Utils/usePrimeData";

const EmployeeDetailSearch = ({ changeDetail, loading, hasAdminRole }) => {

    const primeData = usePrimeData();
    const strings = useLocalization();

    const firstBrand = { id: -1, name: strings.detail_search_titles.brands };
    const brandList = [firstBrand, ...primeData.brandList];

    const firstEmployeeStatus = { id: -1, name: strings.detail_search_titles.employees };
    const employeeStatusList = [firstEmployeeStatus, ...primeData.employeeStatusList];

    const firstEmployeeType = { id: -1, name: strings.detail_search_titles.types };
    const employeeTypeList = [firstEmployeeType, ...primeData.employeeTypeList];

    const [brand, setBrand] = useState(firstBrand);
    const [employeeStatus, setEmployeeStatus] = useState(firstEmployeeStatus)
    const [employeeType, setEmployeeType] = useState(firstEmployeeType)

    const firstVisit = useRef(true);

    useEffect(() => {
        //Sayfa ilk kez yüklenmiyorsa işlemi yap
        //Servise birden çok gez gitmesini engelliyoruz
        if (!firstVisit.current) {
            changeDetail?.({ brandId: brand?.id });
            firstVisit.current = false;
        }
    }, [brand])

    useEffect(() => {
        //Sayfa ilk kez yüklenmiyorsa işlemi yap
        //Servise birden çok gez gitmesini engelliyoruz
        if (!firstVisit.current) {
            changeDetail?.({ status: employeeStatus?.id });
            firstVisit.current = false;
        }
    }, [employeeStatus])

    useEffect(() => {
        //Sayfa ilk kez yüklenmiyorsa işlemi yap
        //Servise birden çok gez gitmesini engelliyoruz
        if (!firstVisit.current) {
            changeDetail?.({ department: employeeType?.id });
            firstVisit.current = false;
        }
    }, [employeeType])

    useEffect(() => {
        //Sayfa yüklendikten sonra false yaparak her değişiklikte
        //servisin ziyaret edilmesini sağlıyoruz
        firstVisit.current = false;
    }, [])

    return (
        <>
            <DetailSearchLayout
                isDisabled={loading}
                children={
                    <div className="!flex gap-x-4 gap-y-5 max-md-lg:justify-center flex-wrap">
                        {hasAdminRole && (
                            <Dropdown
                                title={strings.detail_search_titles.brands}
                                data={brandList}
                                selectedData={brand}
                                setSelectedData={setBrand}
                                isDisabled={loading}
                            />
                        )}
                        <Dropdown
                            title={strings.detail_search_titles.employees}
                            data={employeeStatusList}
                            selectedData={employeeStatus}
                            setSelectedData={setEmployeeStatus}
                            isDisabled={loading}
                        />
                        <Dropdown
                            title={strings.detail_search_titles.types}
                            data={employeeTypeList}
                            selectedData={employeeType}
                            setSelectedData={setEmployeeType}
                            isDisabled={loading}
                        />
                    </div>
                }
            />
        </>
    );
}

export default EmployeeDetailSearch