import { LogService } from '../../services/Log/LogService';
import useAuth from './useAuth';

const useAnalytics = () => {

    //const firebase = useContext(FirebaseContext);

    //const analytics = getAnalytics(firebase);

    const { employee } = useAuth();

    const useAnalytics = (event, params) => {
        
        const { employeeId, memberId, termId } = params || false;
        
        if (event !== "Screen_View") {
            LogService(event, employee.EmployeeId, employeeId, memberId, termId)
                .then(e => {})
                .catch(error => {});
        }

    };

    // // key value ("user_type", "student")
    // const useCustomAnalytics = (key, value) => {
    //     const property = {};
    //     property[key] = value;
    //     setUserProperties(analytics, property);
    // };

    return { useAnalytics };

}

export default useAnalytics