import React from "react";
import { NoRecordsFound } from "../../../../../components";
import { useLocalization } from "../../../../../hooks/Utils/useLocalization";
import CallRecordsTableRow from "./CallRecordsTableRow";

const CallRecordsTable = ({ data, esneksRecordingPath, openModal, onSelectRecord }) => {
    
    const strings = useLocalization();

    return (
        <table className="min-w-full leading-normal text-header">
            <thead>
                <tr>
                    <th className="th w-[1%]"><p className="min-w-max">{strings.member.member_detail.call_records.choose_for_analysis}</p></th>
                    <th className="th w-[10%]"><p className="min-w-max">{strings.member.member_detail.call_records.call_status}</p></th>
                    <th className="th w-[20%]"><p className="min-w-max">{strings.member.member_detail.call_records.caller_info}</p></th>
                    <th className="th w-[15%]"><p className="min-w-max">{strings.member.member_detail.call_records.call_provider}</p></th>
                    <th className="th w-[40%]"><p className="min-w-max">{strings.member.member_detail.call_records.call_record}</p></th>
                </tr>
            </thead>
            <tbody>
                {data.length > 0 ? (
                    data.map((callInfo, index) => (
                        <CallRecordsTableRow
                            key={index}
                            callInfo={callInfo}
                            esneksRecordingPath={esneksRecordingPath}
                            openModal={openModal}
                            onSelectRecord={onSelectRecord}
                        />
                    ))
                ) : (
                    <tr className="bg-white hover:bg-[#f0f0f0]">
                        <td className="td" colSpan={5}>
                            <NoRecordsFound/>
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    );
}

export default CallRecordsTable;