import { fetchApi } from '../index';

export const CreateSession = async (agentUsername) => {

    const url = '/alotech/create_session/index.php';

    const payload = {
        agentUsername: agentUsername
    };

    return await fetchApi(url, payload);
}

export const Click2Call = async (session, memberPhoneNumber) => {

    const url = '/alotech/click_2_call/index.php';

    const payload = {
        memberPhoneNumber: memberPhoneNumber,
        session: session
    };

    return await fetchApi(url, payload);
}

export const GetRecordingInfo = async (callKey) => {

    const url = '/alotech/recording_info/index.php';

    const payload = {
        callKey: callKey,
    };

    return await fetchApi(url, payload);
}