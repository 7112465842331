import {Input} from "../../../../../components";
import React, {useEffect, useState} from "react";
import {checkNumber, formatCreditCardNumber, removeSpaces} from "../../../../../components/Utility";
import {useLocalization} from "../../../../../hooks/Utils/useLocalization";
import {
    AmericanExpressBin,
    AxessBin,
    BankKartKomboBin,
    BonusBin,
    CardFinansBin, GarantiLogoBin,
    HalkbankBin,
    IsBankasiBin,
    KuveytTurkBin,
    MastercardBin,
    MaximumBin, MilesAndSmilesBin,
    NeoBin,
    ParacardBin,
    ParafBin,
    QnbFinansbankBin, ShopflyBin,
    TlCardBin,
    TroyBin,
    VakifbankBin,
    VisaBin,
    WorldCardBin, ZiraatBin
} from "../../../../../assets/img";
import {BinCheck} from "../../../../../services/Iyzico/IyzicoService";

const BinChecker = (props) => {

    const {
        setFlipped,
        cardNumber,
        setCardNumber,
        bankPosList,
        setBank,
        setBankName,
        setIsOneShot,
        setIsForeignCard,
        setCardAssociation
    } = props;
    const strings = useLocalization();

    // BIN check servisinin karşıladığı karakter sayısı default olarak tanımlanıyor.
    const binCheckCharacterLength = 8;

    // Servise bir defa gitmek için eski bin numarası ile yeni girilen bin numarasını karşılaştırmak amacıyla bu state tutuluyor
    const [tempBin, setTempBin] = useState(null);
    // Servisten gelen kredi kartı bilgilerinin tutulduğu state
    const [cardInfo, setCardInfo] = useState({});
    // Kart tipi logosunun tutulduğu state
    const [cardAssociationLogo, setCardAssociationLogo] = useState(null);
    // Kart ailesi logosunun tutulduğu state
    const [cardFamilyLogo, setCardFamilyLogo] = useState(null);

    const cardTypes = Object.freeze(
        {
            "DEBIT_CARD": "DEBIT_CARD",
            "CREDIT_CARD": "CREDIT_CARD"
        }
    )

    const cardAssociationLogos = [
        {
            cardAssociation: "MASTER_CARD",
            logo: MastercardBin
        },
        {
            cardAssociation: "VISA",
            logo: VisaBin
        },
        {
            cardAssociation: "AMERICAN_EXPRESS",
            logo: AmericanExpressBin
        },
        {
            cardAssociation: "TROY",
            logo: TroyBin
        }
    ];

    const cardFamilyLogos = [
        {
            cardFamily: "Neo",
            logo: NeoBin
        },
        {
            cardFamily: "Axess",
            logo: AxessBin
        },
        {
            cardFamily: "Paracard",
            logo: ParacardBin
        },
        {
            cardFamily: "Bonus",
            logo: BonusBin
        },
        {
            cardFamily: "Cardfinans DC",
            logo: CardFinansBin
        },
        {
            cardFamily: "QNB Finans Bank CC",
            logo: QnbFinansbankBin
        },
        {
            cardFamily: "QNB CC",
            logo: QnbFinansbankBin
        },
        {
            cardFamily: "Halkbank DC",
            logo: HalkbankBin
        },
        {
            cardFamily: "Paraf",
            logo: ParafBin
        },
        {
            cardFamily: "Bankamatik",
            logo: IsBankasiBin
        },
        {
            cardFamily: "Maximum",
            logo: MaximumBin
        },
        {
            cardFamily: "Vakıfbank DC",
            logo: VakifbankBin
        },
        {
            cardFamily: "World",
            logo: WorldCardBin
        },
        {
            cardFamily: "Tlcard",
            logo: TlCardBin
        },
        {
            cardFamily: "BankkartCombo",
            logo: BankKartKomboBin
        },
        {
            cardFamily: "BankkartCombo DC",
            logo: BankKartKomboBin
        },
        {
            cardFamily: "Garanti CC",
            logo: GarantiLogoBin
        },
        {
            cardFamily: "Ziraat Bankası CC",
            logo: ZiraatBin
        },
        {
            cardFamily: "Shop&Fly",
            logo: ShopflyBin
        },
        {
            cardFamily: "Miles&Smiles",
            logo: MilesAndSmilesBin
        },
        {
            cardFamily: "Kuveyt Türk CC",
            logo: KuveytTurkBin
        },
        {
            cardFamily: "Kuveyt Türk DC",
            logo: KuveytTurkBin
        }
    ];

    const cardFamilyBanks = [
        {
            cardFamily: "Axess",
            bank: "Akbank"
        },
        {
            cardFamily: "Bonus",
            bank: "Garanti Bankası"
        },
        {
            cardFamily: "QNB Finans Bank CC",
            bank: "Finans Bank"
        },
        {
            cardFamily: "QNB CC",
            bank: "Finans Bank"
        },
        {
            cardFamily: "Maximum",
            bank: "İş Bankası"
        },
        // Geçici olarak kapatıldı. Bunu kapatmak sayesinde world kartlardan vakıf olanlar vakıfa, yk olanlar yk'ya yönleniyor.
        {
            cardFamily: "World",
            bank: "Vakıfbank"
        },
        {
            cardFamily: "BankkartCombo",
            bank: "Ziraat Bankası"
        },
        {
            cardFamily: "BankkartCombo DC",
            bank: "Ziraat Bankası"
        },
        {
            cardFamily: "Ziraat Bankası CC",
            bank: "Ziraat Bankası"
        },
        {
            cardFamily: "Paraf",
            bank: "Halkbank"
        },
        {
            cardFamily: "Advantage",
            bank: "Finans Bank"
        },
        {
            cardFamily: "Şeker Bank CC",
            bank: "Garanti Bankası"
        },
        {
            cardFamily: "TEB CC",
            bank: "Garanti Bankası"
        },
        {
            cardFamily: "Garanti CC",
            bank: "Garanti Bankası"
        },
        {
            cardFamily: "Shop&Fly",
            bank: "Garanti Bankası"
        },
        {
            cardFamily: "Miles&Smiles",
            bank: "Garanti Bankası"
        },
        {
            cardFamily: "Kuveyt Türk CC",
            bank: "Kuveyt Türk"
        }
    ]

    // Kendi sistemimiz ile dış servis sisteminin bilgilerini eşleştirmek için kullanılan array
    const cardBankNames = [
        {
            providerBank: "Akbank",
            systemBank: "Akbank"
        },
        {
            providerBank: "Finansbank",
            systemBank: "Finans Bank"
        },
        {
            providerBank: "Garanti Bankası",
            systemBank: "Garanti Bankası"
        },
        {
            providerBank: "Halk Bankası",
            systemBank: "Halkbank"
        },
        {
            providerBank: "İş Bankası",
            systemBank: "İş Bankası"
        },
        {
            providerBank: "Vakıfbank",
            systemBank: "Vakıfbank"
        },
        {
            providerBank: "Yapı Kredi Bankası",
            systemBank: "Yapıkredi"
        },
        {
            providerBank: "Ziraat Bankası",
            systemBank: "Ziraat Bankası"
        },
        {
            providerBank: "Kuveyt Türk",
            systemBank: "Kuveyt Türk"
        }
    ];

    useEffect(() => {
        /*
            * Kart numarasındaki boşluklar siliniyor ve ilk 6 hanesi alınıyor
            * İlk 6 hanesinin alınmasının sebebi BIN check servisin ilk 6 hane ile çalışmasıdır
        */
        const binNumber = removeSpaces(cardNumber).substring(0 ,8);

        if(binNumber.length >= binCheckCharacterLength && tempBin !== binNumber) {
            setTempBin(binNumber);

            BinCheck(binNumber.substring(0, 6))
                .then(result => {
                    if(result.status === 200) {
                        const resultContent = JSON.parse(result.content);
                        setCardAssociation(resultContent?.cardAssociation);
                        setCardInfo(resultContent);
                    }
                })
                .catch()
        } else if (binNumber.length <= 7) {
            setCardInfo({});
            setTempBin(null);
        }
    }, [cardNumber]);

    useEffect(() => {
        if(Object.keys(cardInfo).length > 0) {
            setIsForeignCard(false);

            // Kart tipi (mastercard, visa vs) logosu set ediliyor.
            const tempCardAssociationLogo = cardAssociationLogos.find(cl => cl.cardAssociation === cardInfo?.cardAssociation) || false;
            tempCardAssociationLogo
                ? setCardAssociationLogo(tempCardAssociationLogo?.logo)
                : setCardAssociationLogo(null);

            // Kart ailesi (bonus, maximum, neo vs) logosu set ediliyor.
            const tempCardFamilyLogo = cardFamilyLogos.find(cf => cf.cardFamily === cardInfo?.cardFamily) || false;
            tempCardFamilyLogo
                ? setCardFamilyLogo(tempCardFamilyLogo?.logo)
                : setCardFamilyLogo(null);

            // Eğer kart debit card ise (banka kartı) tek çekim çekilmesi için oneShot state'i true'ya set ediliyor
            if(cardInfo.cardType === cardTypes.DEBIT_CARD) {
                const tempBank = bankPosList.find(b => b.bankName === "İş Bankası");
                setBank(tempBank?.bankPosId);
                setBankName(tempBank?.bankName);
                setIsOneShot(true);
            } else if(cardInfo.cardType === cardTypes.CREDIT_CARD) {
                // Eğer kart credit card ise aşağıdaki işlemlerden devam ediliyor
                setIsOneShot(false);

                const checkCardFamily = cardFamilyBanks.find(cf => cf.cardFamily === cardInfo.cardFamily) || false;

                /*
                  * Eğer kart gruplarım(bonus, axess vs.) içerisinde, kullanıcının girdiği kart bulunursa,
                  * İlgili karta ait banka set ediliyor
                */
                if(checkCardFamily) {
                    const tempBank = bankPosList.find(b => b.bankName === checkCardFamily.bank);
                    setBank(tempBank?.bankPosId);
                    setBankName(tempBank?.bankName);
                } else {
                    /*
                        * Eğer banka kart grubu bulunamadıysa sistemimizdeki banka adları ile karşılaştırma yapılıp,
                        * Banka bilgisi set ediliyor.
                    */
                    const tempBankName = cardBankNames.find(cb => cb.providerBank === cardInfo.bankName) || false;
                    if(tempBankName) {
                        const tempBank = bankPosList.find(b => b.bankName === tempBankName.systemBank);
                        setBank(tempBank?.bankPosId);
                        setBankName(tempBank?.bankName);
                    } else {
                        // Eğer status "success" ise kart Türkiye kartı demektir.
                        if(cardInfo.status === "success") {
                            const tempBank = bankPosList.find(b => b.bankName === "İş Bankası");
                            setBank(tempBank?.bankPosId);
                            setBankName(tempBank?.bankName);
                            setIsOneShot(true);
                        }
                    }
                }
            } else {
                // Eğer status "failure" ise kart yabancı ülke kartıdır ve Garanti Bankasından tek çekim olarak çekilmelidir.
                const tempBank = bankPosList.find(b => b.bankName === "Garanti Bankası");
                setBank(tempBank?.bankPosId);
                setBankName(tempBank?.bankName);
                setIsOneShot(true);
                setIsForeignCard(true);
            }
        } else {
            setCardAssociationLogo(null);
            setCardFamilyLogo(null);
            setCardAssociation(null);
            setBank(null);
            setBankName("");
            setIsForeignCard(false);
        }
    }, [cardInfo])

    return (
        <div className="flex flex-col px-[10px] pb-0 w-full">
            <label htmlFor="card_number">{strings.member.member_detail.sales_operations.card_number}</label>
            <div className="relative">
                <Input
                    id="card_number"
                    type="text"
                    placeholder="**** **** **** ****"
                    maxLength="19"
                    value={formatCreditCardNumber(cardNumber)}
                    onKeyDown={(e) => !checkNumber(e)}
                    onChange={(e) => setCardNumber(e.target.value)}
                    onFocus={() => setFlipped(false)}
                />

                {cardAssociationLogo &&
                    <img
                        className="absolute right-2.5 bottom-0 top-[12px]"
                        src={cardAssociationLogo}
                        width={45}
                        height={22.5}
                        alt="card-family-logo"
                    />
                }

                {cardFamilyLogo &&
                    <img
                        className="absolute right-[58px] bottom-0 top-[12px]"
                        src={cardFamilyLogo}
                        width={45}
                        height={22.5}
                        alt="card-association-logo"
                    />
                }
            </div>
        </div>
    )
}

export default BinChecker;