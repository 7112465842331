import classNames from "classnames";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Loading, NoRecordsFound } from "../../../../../../components";
import { ISOStringToDate } from "../../../../../../components/Utility";
import { FamilyRole, FamilyStatus } from "../../../../../../consts/Constants";
import { useLocalization } from "../../../../../../hooks/Utils/useLocalization";
import { url } from "../../../../../../routes/utility";
import { GetMemberFamilyActivity } from "../../../../../../services/Member/MemberProfileService";

const FamilyModal = ({ memberId, timeZone }) => {

    const strings = useLocalization();

    const [tabs, setTabs] = useState([
        {
            title: strings.member.member_detail.profile.family_package.family_members,
            key: "family-members"
        },
        {
            title: strings.member.member_detail.profile.family_package.family_activities,
            key: "family-activities"
        }
    ]);
    const [activeTab, setActiveTab] = useState("family-members");
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (memberId) {
            GetMemberFamilyActivity(memberId)
                .then(result => {
                    if(result.status === 200) {
                        const resultContent = JSON.parse(result.content);
                        setData(resultContent);
                    }

                    setLoading(false);
                })
                .catch();
        }
    }, [memberId]);

    return (
        <div className="flex flex-col gap-2 max-h-[70vh] overflow-y-hidden hover:overflow-y-auto">
            {loading && <Loading />}

            {!loading && 
                <>
                    <nav className="flex gap-x-3 sm:gap-x-8 flex-wrap mb-2">
                        {tabs.map((tab, index) => (
                            <button
                                key={index}
                                className={classNames("member-nav-button", activeTab === tab.key && "member-nav-button-active")}
                                onClick={() => setActiveTab(tab.key)}
                            >
                                {tab.title}
                            </button>
                        ))}
                    </nav>

                    <div className="overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">  
                        <div className="inline-block min-w-full rounded-lg overflow-hidden">
                            {activeTab === "family-members" && data.activeFamilyMembers?.length > 0 && <FamilyMembersTable data={data.activeFamilyMembers} />}
                            {activeTab === "family-activities" && data.familyActivities?.length > 0 && <FamilyActivitiesTable data={data.familyActivities} timeZone={timeZone} />}
                        </div>
                    </div>
                </>
            }
        </div>
    );
}

const FamilyMembersTable = ({ data }) => {

    const strings = useLocalization();

    const getFamilyRole = (role) => {
        if(role === FamilyRole.MANAGER) return strings.member.member_detail.profile.family_package.family_manager;
        if(role === FamilyRole.MEMBER) return strings.member.member_detail.profile.family_package.family_member;
    }

    return (
        <table className="min-w-full leading-normal text-header">
            <thead>
                <tr className="bg-white hover:bg-[#f0f0f0]">
                    <th className="th"><p className="min-w-max">{strings.table.name}</p></th>
                    <th className="th"><p className="min-w-max">{strings.member.member_detail.profile.family_package.family_role}</p></th>
                </tr>
            </thead>
            <tbody>
                {data.length > 0 ?
                    data.map((item, index) => (
                        <tr key={index} className="bg-white hover:bg-[#f0f0f0]">
                            <td className="td align-middle">
                                <Link
                                    to={url("member.detail.profile", { memberId: item.memberId })}
                                    target="_blank"
                                    className="font-bold text-blue hover:underline focus:underline"
                                >
                                    <p className="min-w-max">{item.memberName}</p>
                                </Link>
                            </td>
                            <td className="td align-middle max-w-[300px]">
                                {getFamilyRole(item.memberFamilyRole)}
                            </td>
                        </tr>
                    ))
                    :
                    (
                        <tr className="bg-white hover:bg-[#f0f0f0]">
                            <td colSpan="2">
                                <NoRecordsFound />
                            </td>
                        </tr>
                    )
                }
            </tbody>
        </table>
    );
}

const FamilyActivitiesTable = ({ data, timeZone }) => {

    const strings = useLocalization();
    
    const getFamilyActivity = (status) => {
        if(status === FamilyStatus.ACCEPTED) return strings.member.member_detail.profile.family_package.invite_status_accepted;
        if(status === FamilyStatus.REJECTED) return strings.member.member_detail.profile.family_package.invite_status_rejected;
        if(status === FamilyStatus.REMOVED) return strings.member.member_detail.profile.family_package.invite_status_removed;
    }

    return (
        <table className="min-w-full leading-normal text-header">
            <thead>
                <tr className="bg-white hover:bg-[#f0f0f0]">
                    <th className="th"><p className="min-w-max">{strings.table.name}</p></th>
                    <th className="th"><p className="min-w-max">{strings.member.member_detail.profile.family_package.family_role}</p></th>
                    <th className="th"><p className="min-w-max">{strings.table.operation_date}</p></th>
                </tr>
            </thead>
            <tbody>
                {data.length > 0 ?
                    data.map((item, index) => (
                        <tr key={index} className="bg-white hover:bg-[#f0f0f0]">
                            <td className="td align-middle">
                                <Link
                                    to={url("member.detail.profile", { memberId: item.memberId })}
                                    target="_blank"
                                    className="font-bold text-blue hover:underline focus:underline"
                                >
                                    <p className="min-w-max">{item.memberName}</p>
                                </Link>
                            </td>
                            <td className="td align-middle max-w-[300px]">
                                {getFamilyActivity(item.memberFamilyStatus)}
                            </td>
                            <td className="td align-middle max-w-[300px]">
                                {ISOStringToDate(item?.createDate, timeZone)}
                            </td>
                        </tr>
                    ))
                    :
                    (
                        <tr className="bg-white hover:bg-[#f0f0f0]">
                            <td colSpan="3">
                                <NoRecordsFound />
                            </td>
                        </tr>
                    )
                }
            </tbody>
        </table>
    );
}

export default FamilyModal;