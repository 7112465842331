import React, { forwardRef } from "react";
import { useDispatch } from "react-redux";
import useAuth from "../../hooks/Log/useAuth";
import { useLocalization } from "../../hooks/Utils/useLocalization";
import { employeeLogout } from "../../store/EmployeeSlice";
import useAnalytics from "../../hooks/Log/useAnalytics";
import {alotechLogout} from "../../store/AlotechSlice";
import { useNavigate } from "react-router-dom";
import { url } from "../../routes/utility";
import {removeFromLocalStorage} from "../../utils";
import {StorageKeys} from "../../consts/Constants";
import { esneksLogout } from "../../store/EsneksSlice";

const NavbarProfile = forwardRef(() => {
    
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const strings = useLocalization();
  const { employee } = useAuth();
  const analytics = useAnalytics();

  const handleQuit = () => {
      navigate(url("dashboard"));
      dispatch(employeeLogout());
      dispatch(alotechLogout());
      dispatch(esneksLogout());
      analytics.useAnalytics('Kullanici_Cikis_Yapti');
  };

  return (
    <>
      <div className="flex px-4">
        <img
          className="rounded-lg"
          src={process.env.REACT_APP_PROFILE_URL + "/employee/" + employee.ProfilePicture}
          width="60"
          height="60"
          alt=""
        />
        <div className="ml-2">
          <p className="my-1">{employee.FullName}</p>
          <p className="text-[12px] text-[#6c757d] mb-1">{employee.Email}</p>
        </div>
      </div>

      <hr className="mb-3 mt-4 px-4" />
      <button
        className="block px-5 py-[3px] hover:bg-[#F8F9FA] focus:bg-[#F8F9FA] w-full text-start"
        tabIndex="-1"
      >
        {strings.sidebar.profile.my_profile}
      </button>

      <button
        onClick={handleQuit}
        className="block px-5 py-[3px] cursor-pointer hover:bg-[#F8F9FA] focus:bg-[#F8F9FA] w-full text-start"
        tabIndex="-1"
      >
        {strings.sidebar.profile.logout}
      </button>
    </>
  );
});

export default NavbarProfile;