import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Loading, NoRecordsFound, showTopAlert } from "../../../../../../components";
import { ISOStringToDate } from "../../../../../../components/Utility";
import { CriterionTypeList } from "../../../../../../consts/Constants";
import useAuth from "../../../../../../hooks/Log/useAuth";
import { useLocalization } from "../../../../../../hooks/Utils/useLocalization";
import { GetMemberNPSSubmission } from "../../../../../../services/Member/MemberProfileService";

const NpsScoresModal = ({ memberId }) => {

    const strings = useLocalization();
    const { employee } = useAuth();
    const language = useSelector(state => state.localizationStore.language);

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        //NPS verilerini getiren servis
        GetMemberNPSSubmission(memberId)
            .then(result => {
                if (result.status === 200) {
                    const resultContent = JSON.parse(result.content);
                    setData(resultContent);
                } else {
                    showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
                }
                setLoading(false);
            })
            .catch()
    }, [])

    return (
        <div className="flex flex-col gap-2 max-h-[80vh] overflow-y-hidden hover:overflow-y-auto">
            <div className="py-4 overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
                <div className="inline-block min-w-full rounded-lg overflow-hidden">
                    <table className="min-w-full leading-normal text-header">
                        <thead >
                            <tr className="bg-white hover:bg-[#f0f0f0]">
                                <th className="th"><p className="min-w-max">{strings.member.member_detail.profile.nps_type}</p></th>
                                <th className="th"><p className="min-w-max">{strings.member.member_detail.profile.nps_comment}</p></th>
                                <th className="th"><p className="min-w-max">{strings.member.member_detail.profile.nps_rate}</p></th>
                                <th className="th"><p className="min-w-max">{strings.table.operation_date}</p></th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ?
                                <tr className="bg-white hover:bg-[#f0f0f0]">
                                    <td colSpan="4">
                                        <Loading />
                                    </td>
                                </tr>
                                :
                                data.length > 0 ?
                                    (
                                        data.map((nps, index) => (
                                            <tr key={index} className="bg-white hover:bg-[#f0f0f0]">
                                                <td className="td align-middle">
                                                    <p className="min-w-max">{CriterionTypeList.find(criterion => criterion.id === nps.criterionType)[language]}</p>
                                                </td>
                                                <td className="td align-middle max-w-[300px]">
                                                    {nps.comment || strings.errors.it_is_not_specified}
                                                </td>
                                                <td className="td align-middle">
                                                    <p className="min-w-max">{nps.rate}</p>
                                                </td>
                                                <td className="td align-middle">
                                                    <p className="min-w-max">{ISOStringToDate(nps.createDate, employee.TimeZone)}</p>
                                                </td>
                                            </tr>
                                        ))
                                    )
                                    :
                                    (
                                        <tr className="bg-white hover:bg-[#f0f0f0]">
                                            <td colSpan="4">
                                                <NoRecordsFound />
                                            </td>
                                        </tr>
                                    )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default NpsScoresModal;