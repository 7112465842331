import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Loading, LoadingSpinner, showTopAlert } from "../../../../../../components";
import CopyToClipboard from "../../../../../../components/CopyToClipboard";
import { convertSecondsToMinutesAndSeconds, ISODateToReadableDateWithSeconds } from "../../../../../../components/Utility";
import { useLocalization } from "../../../../../../hooks/Utils/useLocalization";
import { AnalyzeCallRecords, GetTranscribesByEmployeeCallRecordIds } from "../../../../../../services/Employee/EmployeeService";
import { formatContent } from "../../../../../../utils";

const AnalyzeTranscribeModal = ({ data }) => {
    
    const strings = useLocalization();
    const { memberId } = useParams();
    const promptErrorRef = useRef();

    const [loading, setLoading] = useState(true);
    const [analyzing, setAnalyzing] = useState(false);
    const [transcribes, setTranscribes] = useState([]);
    const [prompt, setPrompt] = useState('');
    const [analysis, setAnalysis] = useState('');

    useEffect(() => {
        const recordIds = data.map(record => record.id);

        GetTranscribesByEmployeeCallRecordIds({ employeeCallRecordIds: recordIds })
            .then(result => {
                if(result.status === 200) {
                    const resultContent = JSON.parse(result.content);
                    const combinedData = resultContent.map(transcript => {
                        const originalRecord = data.find(record => record.id === transcript.employeeCallRecordId);
                        return {
                            ...transcript,
                            callerName: originalRecord.callerName,
                            callDate: originalRecord.callDate,
                            callDuration: originalRecord.callDuration
                        };
                    });
                    setTranscribes(combinedData);
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }, [data]);

    const formatTranscriptsForAI = () => {
        return transcribes.map((item, index) => (
            `Konuşma #${index + 1}:
            Arayan: ${item.callerName}
            Tarih: ${ISODateToReadableDateWithSeconds(item.callDate)}
            Süre: ${convertSecondsToMinutesAndSeconds(item.callDuration, strings)}
            Transkript:
            ${item.transcribe}
            -------------------`
        )).join('\n\n');
    };

    const handleAnalyze = async () => {
        if (prompt.trim() === '') {
            promptErrorRef.current.innerHTML = strings.ticket.ai_answer.prompt_null_error;
            return;
        }
        promptErrorRef.current.innerHTML = '';
        setAnalyzing(true);

        const formattedTranscripts = formatTranscriptsForAI();
        const fullPrompt = `${prompt}\n\nSeçili Konuşmalar:\n\n${formattedTranscripts}`;

        const result = await AnalyzeCallRecords({
            memberId: memberId,
            prompt: fullPrompt
        });

        if (result.status === 200) {
            setAnalysis(result.content);
        } else {
            showTopAlert(strings.errors.an_error_occurred, "error");
        }

        setAnalyzing(false);
    };

    if (loading) return <Loading />;

    return (
        <div className="flex flex-col gap-3 p-3 sm:p-5 max-h-[85vh]">
            <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-2">
                <div className="flex items-center">
                    <span className="material-symbols-outlined mr-2 text-[19px]">
                        description
                    </span>
                    <p className="font-bold">
                        {strings.member.member_detail.call_records.selected_transcripts}
                    </p>
                </div>
                <p className="text-sm text-gray">
                    {strings.member.member_detail.call_records.selected_records_count}: {transcribes.length}
                </p>
            </div>
            <hr />
            <div className="flex flex-col lg:flex-row gap-4 h-full">
                <div className="flex-1 lg:w-[45%] xl:w-[50%] min-h-[200px] sm:min-h-[300px] max-h-[440px] lg:max-h-[calc(85vh-140px)] overflow-y-auto pr-2">
                    <div className="flex flex-col gap-4 mb-6">
                        {transcribes.map((item, index) => (
                            <div key={index} className="flex flex-col bg-white rounded-lg border border-[#ebedf2] hover:shadow-sm transition-shadow">
                                <div className="sticky top-0 z-10">
                                    <div className="flex flex-col sm:flex-row sm:items-center justify-between bg-card p-3 rounded-t-lg">
                                        <div className="flex flex-col sm:flex-row sm:items-center gap-2 sm:gap-3 text-sm">
                                            <p className="font-semibold">{item.callerName}</p>
                                            <div className="hidden sm:block text-dark-gray">•</div>
                                            <p className="text-base-text">{convertSecondsToMinutesAndSeconds(item.callDuration, strings)}</p>
                                            <div className="hidden sm:block text-dark-gray">•</div>
                                            <p className="text-base-text">{ISODateToReadableDateWithSeconds(item.callDate)}</p>
                                        </div>
                                        <div className="bg-blue/10 text-blue px-2 py-1 rounded-[10px] text-sm mt-2 sm:mt-0 !max-w-[50px]">
                                            #{index + 1}
                                        </div>
                                    </div>
                                </div>
                                <div className="p-3 sm:p-4">
                                    <div className="whitespace-pre-line leading-relaxed text-sm text-base-text">
                                        {item.transcribe}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="lg:w-[55%] xl:w-[50%] flex flex-col gap-3 max-h-[calc(85vh-140px)] overflow-y-auto">
                    <div className="flex flex-col gap-3">
                        <textarea 
                            rows="6" 
                            className="w-full border border-input-border focus:border-blue/30 focus:bg-[#fafafa] rounded-lg px-4 py-2 outline-none text-sm transition-colors"
                            placeholder={strings.member.member_detail.call_records.prompt_placeholder}
                            value={prompt} 
                            onChange={(e) => setPrompt(e.target.value)}
                        />
                        <button
                            type="button"
                            className="button search-button text-white bg-green hover:bg-green/90 border-green focus:!border-green/70 px-5 h-[44px] rounded-full transition-colors disabled:opacity-50"
                            onClick={handleAnalyze}
                            disabled={analyzing}
                        >
                            <div className="flex items-center justify-center gap-2">
                                {analyzing ? (
                                    <LoadingSpinner />
                                ) : (
                                    <>
                                        <span className="material-symbols-outlined">
                                            smart_toy
                                        </span>
                                        <span>{strings.member.member_detail.call_records.analyze}</span>
                                    </>
                                )}
                            </div>
                        </button>
                        <span ref={promptErrorRef} className="text-danger field-validation-valid" />
                    </div>
                    
                    {analysis && (
                        <>
                            <div className="flex items-center gap-1 sticky top-0 bg-white z-10">
                                <p className="font-bold text-[14px]">
                                    {strings.member.member_detail.call_records.analysis_result}
                                </p>
                                <CopyToClipboard 
                                    text={analysis}
                                    iconClassName="text-[18px]"
                                />
                            </div>
                            
                            <div className="flex-1 overflow-hidden">
                                <div className="bg-card p-3 rounded-lg whitespace-pre-line text-sm h-full overflow-y-auto border border-input-border">
                                    {formatContent(analysis)}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AnalyzeTranscribeModal;