import { fetchApi } from '../index';

export const UseCaseHistoryListService = async (model) => {
    const url = '/ai_prime_history/ai_use_case_session/list/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const UseCaseHistorySessionLogListService = async (aiUseCaseSessionId) => {
    const url = '/ai_prime_history/ai_use_case_session_log/list/';

    const payload = {
        aiUseCaseSessionId: aiUseCaseSessionId
    };

    return await fetchApi(url, payload);
}

export const GetAiUseCaseSessionExists = async (memberId, useCaseIds) => { 
    const url = '/ai_prime_history/ai_use_case_session/get_ai_use_case_session_exists/';

    const payload = {
        memberId: memberId,
        useCaseIds: useCaseIds
    };

    return await fetchApi(url, payload);
}
