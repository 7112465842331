import ko_logo from './ko-logo-papagan.png';
import login_isometric from './login-isometric.png';
import default_picture from './default_profile.png';

import accept from './accept.png';
import accept_light from './accept-light.png';

import garanti from './banks/garanti_bbva.jpg';
import akbank from './banks/akbank.png';
import yapikredi from './banks/yapikredi.png';
import isbankasi from './banks/turkiye-isbankasi.png'
import finansbank from './banks/qnb-finansbank.png';
import halkbank from './banks/halkbank.png';

import renkli_fikirler_logo from './renkli-fikirler-logo.png';
import rf_logo from './rf-logo.png';

import visa from './cards/visa.png';
import amex from './cards/amex.png';
import mastercard from './cards/mastercard.png';
import maestro from './cards/maestro.png';
import troy from "./cards/troy.png";

import accept_2 from './accept-2.png';
import multiply from './multiply.png';

import tr_flag from './flags/tr_flag.png';
import usa_flag from './flags/usa_flag.png';
import sa_flag from './flags/sa_flag.png';
import az_flag from './flags/az_flag.png';

import thumbnail from './academy/thumbnail.png';
import doc_thumbnail from './academy/doc_thumbnail.png';
import picture_thumbnail from './academy/picture_thumbnail.png';
import student_thumbnail from './academy/student_thumbnail.png';
import pdf_thumbnail from './academy/pdf_thumbnail.png';

import productivity_call_flow from './academy/productivity/verimlilik_arama_akisi.png'
import productivity_sales_charts from './academy/productivity/verimlilik_satis_baremleri.png'

import american_express_bin from "./banks_bin/american_express.png";
import mastercard_bin from "./banks_bin/mastercard.png";
import troy_bin from "./banks_bin/troy.png";
import visa_bin from "./banks_bin/visa.png";
import axess_bin from "./banks_bin/axess.png";
import neo_bin from "./banks_bin/neo.png";
import paracard_bin from "./banks_bin/paracard.png";
import bonus_bin from "./banks_bin/bonus.png";
import card_finans_bin from "./banks_bin/cardfinans.png";
import qnb_finansbank_bin from "./banks_bin/qnb_finansbank.png";
import halkbank_bin from "./banks_bin/halkbank.png";
import paraf_bin from "./banks_bin/paraf.png";
import is_bankasi_bin from "./banks_bin/is_bankasi.png";
import maximum_bin from "./banks_bin/maximum.png";
import vakifbank_bin from "./banks_bin/vakifbank.png";
import world_card_bin from "./banks_bin/world_card.png";
import tl_card_bin from "./banks_bin/tl_card.png";
import bankkart_kombo_bin from "./banks_bin/bankkart_combo.png";
import garanti_bin from "./banks_bin/garanti_logo.png";
import ziraat_bin from "./banks_bin/ziraat_logo.png";
import shopfly_bin from "./banks_bin/shopfly.png";
import miles_and_smiles_bin from "./banks_bin/miles_and_smiles.png";
import kuveyt_turk_bin from "./banks_bin/kuveyt_turk.png";

export const DefaultProfile = default_picture;
export const KoLogo = ko_logo;
export const LoginIsometric = login_isometric;

export const Accept = accept;
export const AcceptLight = accept_light;

export const Garanti = garanti;
export const Akbank = akbank;
export const Yapikredi = yapikredi;
export const IsBankasi = isbankasi;
export const Finansbank = finansbank;
export const Halkbank = halkbank;

export const RenkliFikirlerLogo = renkli_fikirler_logo;
export const RFLogo = rf_logo;

export const Visa = visa;
export const Mastercard = mastercard;
export const AmericanExpress = amex;
export const Maestro = maestro;
export const Troy = troy;

export const Accept2 = accept_2;
export const Multiply = multiply;

export const TrFlag = tr_flag;
export const UsaFlag = usa_flag;
export const SaFlag = sa_flag;
export const AzFlag = az_flag;

export const Thumbnail = thumbnail;
export const DocThumbnail = doc_thumbnail;
export const PictureThumbnail = picture_thumbnail;
export const StudentThumbnail = student_thumbnail;
export const PdfThumbnail = pdf_thumbnail;

export const ProductivityCallFlow = productivity_call_flow
export const ProductivitySalesCharts = productivity_sales_charts

export const AmericanExpressBin = american_express_bin;
export const MastercardBin = mastercard_bin;
export const TroyBin = troy_bin;
export const VisaBin = visa_bin;
export const AxessBin = axess_bin;
export const NeoBin = neo_bin;
export const ParacardBin = paracard_bin;
export const BonusBin = bonus_bin;
export const CardFinansBin = card_finans_bin;
export const QnbFinansbankBin = qnb_finansbank_bin;
export const HalkbankBin = halkbank_bin;
export const ParafBin = paraf_bin;
export const IsBankasiBin = is_bankasi_bin;
export const MaximumBin = maximum_bin;
export const VakifbankBin = vakifbank_bin;
export const WorldCardBin = world_card_bin;
export const TlCardBin = tl_card_bin;
export const BankKartKomboBin = bankkart_kombo_bin;
export const GarantiLogoBin = garanti_bin;
export const ZiraatBin = ziraat_bin;
export const ShopflyBin = shopfly_bin;
export const MilesAndSmilesBin = miles_and_smiles_bin;
export const KuveytTurkBin = kuveyt_turk_bin;