import { fetchApi } from '../index';

export const GetMemberAiSalesAnalysis = async (memberId) => {

    const url = '/member/v1/member_sales_ai_analysis/send_captions_to_ai_by_member_id/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const GetAiHistoryToAiAnalysis = async (memberId, salesAiType, aiUseCaseId) => {

    const url = '/member/v1/member_sales_ai_analysis/send_ai_history_to_ai_analysis/';

    const payload = {
        memberId: memberId,
        salesAiType: salesAiType,
        aiUseCaseId: aiUseCaseId
    };

    return await fetchApi(url, payload);
}