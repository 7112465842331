import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Loading, NoRecordsFound, Pagination, showTopAlert } from "../../../../../../components";
import { MemberProfileFollowStatus } from "../../../../../../consts/Constants";
import { useLocalization } from "../../../../../../hooks/Utils/useLocalization";
import { url } from "../../../../../../routes/utility";
import { GetFollowingsByMemberId, GetOtherContactsByMemberId, GetPublicRegisteredContactsByMemberId, GetReferrerContactsByMemberId } from "../../../../../../services/Member/MemberProfileService";

const ContactModal = ({ memberId }) => {

    const strings = useLocalization();

    const [activeTab, setActiveTab] = useState("following-members");
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecordsCount, setTotalRecordsCount] = useState(0);

    const limit = 15;

    const tabs = [
        {
            title: strings.member.member_detail.profile.contact.following_members,
            key: "following-members"
        },
        {
            title: strings.member.member_detail.profile.contact.contact_members_and_public,
            key: "contact-members"
        },
        {
            title: strings.member.member_detail.profile.contact.referrer_members,
            key: "referrer-members"
        },
        {
            title: strings.member.member_detail.profile.contact.added_contact_members,
            key: "added-contact-members"
        }
    ];

    const getMemberProfileFollowStatus = (memberProfileFollowStatus) => {
        switch(memberProfileFollowStatus) {
            case MemberProfileFollowStatus.FOLLOWING_MEMBER: return strings.member.member_detail.profile.contact.following;
            case MemberProfileFollowStatus.PUBLIC_CONTACT_MEMBER: return strings.member.member_detail.profile.contact.contact_and_public;
            case MemberProfileFollowStatus.EXIST_MEMBER_BY_PUBLIC_OTHER_CONTACTS: return strings.member.member_detail.profile.contact.exist_member_by_public_other_contacts;
            case MemberProfileFollowStatus.EXIST_MEMBER_BY_PRIVATE_OTHER_CONTACTS: return strings.member.member_detail.profile.contact.exist_member_by_private_other_contacts;
            case MemberProfileFollowStatus.CURRENT_REFERER_MEMBER: return strings.member.member_detail.profile.contact.current_referer_member;
            case MemberProfileFollowStatus.OLD_REFERER_MEMBER: return strings.member.member_detail.profile.contact.old_referer_member;
        }
    }

    const fetchData = useCallback(() => {
        setLoading(true);
        let fetchFunction;

        switch(activeTab) {
            case "following-members":
                fetchFunction = GetFollowingsByMemberId;
                break;
            case "contact-members":
                fetchFunction = GetPublicRegisteredContactsByMemberId;
                break;
            case "referrer-members":
                fetchFunction = GetReferrerContactsByMemberId;
                break;
            case "added-contact-members":
                fetchFunction = GetOtherContactsByMemberId;
                break;
            default:
                return;
        }

        fetchFunction(memberId, currentPage - 1, limit)
            .then(result => {
                if (result.status === 200) {
                    const newData = JSON.parse(result.content);
                    setData(newData.followingsWithPublicContactItems);
                    setTotalRecordsCount(newData.totalMemberCount);
                } else {
                    showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
                }
                setLoading(false);
            })
            .catch(() => setLoading(false));
    }, [activeTab, memberId, currentPage, limit]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        setCurrentPage(1);
        setData([]);
    }, [activeTab]);

    return (
        <div className="flex flex-col gap-2 max-h-[80vh] overflow-y-hidden hover:overflow-y-auto">
            <nav className="flex gap-x-3 sm:gap-x-8 flex-wrap mb-2">
                {tabs.map((tab, index) => (
                    <button
                        key={index}
                        className={classNames("member-nav-button", activeTab === tab.key && "member-nav-button-active")}
                        onClick={() => setActiveTab(tab.key)}
                    >
                        {tab.title}
                    </button>
                ))}
            </nav>
            <div className="overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">  
                <div className="inline-block min-w-full rounded-lg overflow-hidden">
                    <table className="min-w-full leading-normal text-header">
                        <thead>
                            <tr className="bg-white hover:bg-[#f0f0f0]">
                                <th className="th"><p className="min-w-max">{strings.table.name}</p></th>
                                <th className="th"><p className="min-w-max">{strings.table.status}</p></th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ?
                                <tr className="bg-white hover:bg-[#f0f0f0]">
                                    <td colSpan="2">
                                        <Loading />
                                    </td>
                                </tr>
                                :
                                data.length > 0 ?
                                    (
                                        data.map((member, index) => (
                                            <tr key={index} className="bg-white hover:bg-[#f0f0f0]">
                                                <td className="td align-middle">
                                                    <Link
                                                        to={url("member.detail.profile", { memberId: member.memberId })}
                                                        target="_blank"
                                                        className="font-bold text-blue hover:underline focus:underline">
                                                        <p className="min-w-max">
                                                            {member.fullName}
                                                        </p>
                                                    </Link>
                                                </td>
                                                <td className="td align-middle max-w-[300px]">
                                                    <p className="min-w-max">
                                                        {getMemberProfileFollowStatus(member.memberProfileFollowStatus)}
                                                    </p>
                                                </td>
                                            </tr>
                                        ))
                                    )
                                    :
                                    (
                                        <tr className="bg-white hover:bg-[#f0f0f0]">
                                            <td colSpan="2">
                                                <NoRecordsFound />
                                            </td>
                                        </tr>
                                    )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <Pagination
                totalCount={totalRecordsCount}
                limit={limit}
                start={(currentPage - 1) * limit}
                setStart={(newStart) => setCurrentPage(newStart / limit + 1)}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                setLoading={setLoading}
            />
        </div>
    )
}

export default ContactModal;