import { useEffect, useState } from 'react'
import { CandidatePoolReportDetailSearch, CandidatePoolReportTable } from './components'
import { SearchBar, SearchCount, PageTitle, showTopAlert, Loading } from '../../components'
import { useLocalization } from '../../hooks/Utils/useLocalization'
import { areObjectsEqual, stringToDate } from '../../components/Utility'
import Pagination from '../../components/Pagination'
import useAuth from '../../hooks/Log/useAuth'
import NotAuthorized from '../../components/NotAuthorized'
import { MemberPoolReport } from '../../services/Member/MemberPoolReportService'
import { getButtonPermission } from '../../rights/Utility'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

const CandidatePoolReport = () => {

    const strings = useLocalization();
    const limit = parseInt(process.env.REACT_APP_TABLE_ROW_LIMIT);
    const language = useSelector(state => state.localizationStore.language);

    const { employee, employeeTypeId, checkPermission, getPermission } = useAuth();
    const roles = getPermission();
    const permission = checkPermission(roles, employeeTypeId);
    const contentRoles = getButtonPermission({ group: "report", name: "getAllContent" });
    const contentPermission = checkPermission(contentRoles, employeeTypeId);
    const adminRoles = getButtonPermission({ group: "admin", name: "adminRole" });
    const hasAdminRole = checkPermission(adminRoles, employeeTypeId);

    const [start, setStart] = useState(0);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecord, setTotalRecord] = useState(0);
    const [data, setData] = useState([]);
    //Search yapıldığında çalışır
    const [showWhatsappReturnButton, setShowWhatsappReturnButton] = useState(false)
    //Servise gidildiğinde çalışır.
    const [isFirstRequest, setIsFirstRequest] = useState(false);
    const [sendRequest, setSendRequest] = useState();
    //Detail Search objesini ilk başta tutup değişip-değilmediğini anlamak için kullanılır.
    const [tmpDetailSearch, setTmpDetailSearch] = useState({});

    const [detailSearch, setDetailSearch] = useState({
        beginDate: null,
        endDate: null,
        brandId: hasAdminRole ? -1 : +employee?.BrandId,
        scoreFilter: -1,
        formStatus: -1,
        utmSource: -1,
        searchText: '',
    })

    //Arama verileri değiştiğinde değerleri sıfırlıyoruz
    const resetValue = () => {
        setStart(start => 0);
        setCurrentPage(currentPage => 1);
    }

    //select değişiklikleri bu alandan yönetiliyor
    const changeDetail = (values) => {
        setDetailSearch({ ...detailSearch, ...values });
        resetValue();
    }

    //input değişiklikleri bu alandan yönetiliyor
    const changeValue = (value) => {
        setDetailSearch({ ...detailSearch, searchText: value });
        resetValue();
    }

    //sayfa değiştikçe bilgileri yeniden çağırıyoruz
    useEffect(() => {
        if (totalRecord !== 0) {
            getMemberPoolReport();
        }
    }, [start])

    useEffect(() => {
        if (detailSearch.searchText.length > 0 || !contentPermission) setShowWhatsappReturnButton(showWhatsappReturnButton => true)
        else setShowWhatsappReturnButton(showWhatsappReturnButton => false)
    }, [detailSearch.searchText])

    const getMemberPoolReport = async () => {
        setLoading(loading => true);
        const model = {
            start: start,
            length: limit,
            beginDate: detailSearch.beginDate ? stringToDate(detailSearch.beginDate) : null,
            endDate: detailSearch.endDate ? stringToDate(detailSearch.endDate, 23, 59, 59) : null,
            brandId: detailSearch.brandId,
            formStatus: detailSearch.formStatus,
            utmSource: detailSearch.utmSource,
            searchText: detailSearch.searchText
        }

        const result = await MemberPoolReport(model);

        if (result.status === 200) {
            let resultContent = JSON.parse(result.content);
            setData(data => resultContent.data);
            setTotalRecord(totalRecord => resultContent.recordsTotal);
            setLoading(loading => false);
            setIsFirstRequest(isFirstRequest => true);
        } else {
            showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
        }
    }

    useEffect(() => {

        let abortController = new AbortController();

        //Endpoint'e gitmeden loading'i true'ya çekip dil değiştiğinde de loading'in devreye girmesini sağlıyoruz
        if (sendRequest) {
            (contentPermission || detailSearch.searchText.length !== 0) && getMemberPoolReport()
        }

        return () => {
            abortController.abort();
        }
    }, [detailSearch, language, sendRequest])

    useEffect(() => {
        setTmpDetailSearch(tmpDetailSearch => detailSearch);
    }, []);

    useEffect(() => {
        if (Object.keys(tmpDetailSearch).length > 0) {
            setSendRequest(sendRequest => !areObjectsEqual(detailSearch, tmpDetailSearch));
        }
    }, [detailSearch, tmpDetailSearch])

    return (
        <>
            {permission && (
                <>
                    <CandidatePoolReportDetailSearch
                        changeDetail={changeDetail}
                        loading={loading}
                        contentPermission={contentPermission}
                        hasAdminRole={hasAdminRole}
                    />
                    <SearchBar
                        changeValue={changeValue}
                        placeHolder={strings.search_bar.search_candidate}
                        isDisabled={loading}
                        isDisabledSearchButton={!contentPermission}
                        content={
                            <button
                                className={classNames("button search-button gap-3 text-white bg-blue border-blue", {
                                    "opacity-70 pointer-events-none": !contentPermission
                                })}
                                onClick={() => setSendRequest(sendRequest => true)}
                                disabled={!contentPermission}
                            >
                                <span className="material-symbols-outlined">download</span>
                                <p>{strings.member.candidate_pool.all}</p>
                            </button>
                        }
                    />
                    {!loading && <SearchCount count={totalRecord} />}
                </>
            )}
            <PageTitle title={strings.sidebar.report.candidate_pool} />

            {permission ? (
                loading ?
                    (
                        <Loading />
                    )
                    :
                    (
                        <>
                            <CandidatePoolReportTable
                                data={data}
                                showWhatsappReturnButton={showWhatsappReturnButton}
                                isFirstRequest={isFirstRequest}
                            />
                            <Pagination
                                totalCount={totalRecord}
                                limit={limit}
                                start={start}
                                setStart={setStart}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                setLoading={setLoading}
                                loadScreen={true}
                            />
                        </>
                    )
            ) : (<NotAuthorized />)}
        </>
    )
}

export default CandidatePoolReport