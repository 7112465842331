import {getCookie} from 'ko-basic-cookie';
import {cookieName, EmployeeStatusType} from '../../consts/Constants';
import {decodeToken} from '../../components/Utility';
import {useLocation} from 'react-router-dom';
import {useDispatch} from "react-redux";
import routes from '../../routes/routes';
import {useEffect} from 'react';
import {employeeLogout} from '../../store/EmployeeSlice';
import {alotechLogout} from "../../store/AlotechSlice";
import { esneksLogout } from '../../store/EsneksSlice';

const useAuth = () => {

    const token = getCookie(cookieName);
    const employee = token && decodeToken(token);
    const dispatch = useDispatch();
    const employeeType = Object.keys(EmployeeStatusType).find(key => EmployeeStatusType[key] === parseInt(employee.EmployeeTypeId));
    const employeeTypeId = parseInt(employee.EmployeeTypeId);
    const IsEmployeeTeamLead = employee.IsEmployeeTeamLead === "True";

    const location = useLocation();

    //expiration date şimdiki zamandan ilerideyse false, gerideyse true döndürüyor
    const isAfterExpirationDate = (expirationDateTimeStamp) => {
        const currentTimeStamp = Date.parse(new Date());
        return currentTimeStamp > expirationDateTimeStamp;
    };

    const expirationDateTimeStamp = employee.exp * 1000;
    const isExpirationDate = isAfterExpirationDate(expirationDateTimeStamp);

    const getPermission = () => {
        let lastRoute, roles;
        const path = location.pathname.replace(/\//, '');

        path.split('/').map(p => {
            if (!lastRoute) {
                lastRoute = routes.find(r => r.name === p);
                roles = lastRoute.roles;
            } else {
                //Eğer path id içermiyorsa route objesini kontrol ediyoruz çünkü path name'i id ile eşleşmediğinden hata veriyor.
                const isIncludeId = lastRoute.path.split("/").find(lr => lr.includes(":"));

                if (!isIncludeId) {
                    lastRoute = lastRoute.children.find(r => r.name === p);
                    roles = lastRoute.roles;
                }
            }
        })

        return roles;
    }

    const checkPermission = (roles, employeeTypeId) => {
        let pathRoles = roles.length > 0 ? roles.some(role => role === employeeTypeId) : true;
        return pathRoles;
    }

    useEffect(() => {
        //Expiratipn date'e ulaştığımızda logout yapıyor
        if(isExpirationDate) {
            dispatch(employeeLogout());
            dispatch(alotechLogout());
            dispatch(esneksLogout());
        }
    }, [isExpirationDate])

    return { employee, employeeType, employeeTypeId, IsEmployeeTeamLead, token, getPermission, checkPermission }
}

export default useAuth