import parsePhoneNumberFromString, {isValidPhoneNumber} from "libphonenumber-js";
import {RenkliFikirlerLogo, RFLogo} from "../assets/img";
import {AlingliziahLogo, AzerLogo, KoLogo} from "../assets/svg";
import {ParameterTypes} from "../consts/Constants";

export const decodeToken = token => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export const validateEmail = mail => {
    if (mail === null || mail === '')
        return false;
    let mailFormat = /^\w+([6\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/;
    return mail.match(mailFormat) ? true : false;

}

export const validPhoneNumber = phone => {
    return isValidPhoneNumber(phone);
}

export const getddMMyyyyDate = date => {
    return (("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear());
}

export const getDateWithDayDifference = (date, difference) => {
    const tmpDate = new Date();
    return new Date(tmpDate.setDate(date.getDate() - difference));
}

//string veriyi datee çevirerek servise iletiyoruz
export const stringToDate = (strDate, hour = 0, minute = 0, second = 0) => {
    let dateArr = strDate !== null && strDate.split("/");
    return strDate !== null && new Date(dateArr[2], parseInt(dateArr[1]) - 1, parseInt(dateArr[0]), hour, minute, second);
}

//Boş gelen verileri ekrana istediğimiz metin ile yazdırmamızı sağlıyor
export const isItSpecified = (value, message) => {
    return value ? value : message;
}

//Eğitmen detayı verileri bölünerek saate employee(kullanıcıya) ait timezone ekleniyor
export const memberLessonDetailList = (detail, timeZone) => {
    let lessonDetail = detail.split("/");
    let tempTime = lessonDetail[0].replace(/^\s+|\s+$/gm, '');
    let lessonTime = addTimeZone(tempTime, timeZone);
    let lessonTeacher = lessonDetail[1];
    return lessonTime + " / " + lessonTeacher;
}

//ISO Formatlı datetime içerikleri formatlamak için kullanılıyor
export const ISOStringToDate = (date, timeZone) => {
    let tmpDate = new Date(date);

    if (date.includes("T")) {
        const dateArr = date.split("T");
        const dateTmpTime = dateArr[1].split(":");
        tmpDate = new Date(Date.UTC(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate(), dateTmpTime[0], dateTmpTime[1], dateTmpTime[2].split(".")[0]));
    }

    let returnDate = ("0" + tmpDate.getDate()).slice(-2) + "." + ("0" + (tmpDate.getMonth() + 1)).slice(-2) + '.' + tmpDate.getFullYear();
    let tmpTime = tmpDate.getUTCHours() + ":" + tmpDate.getUTCMinutes();
    return returnDate + " / " + addTimeZone(tmpTime, timeZone)
}

//ISO Formatlı datetime içerikleri formatlamak için kullanılıyor. TimeZone ihtiyacı duyulmuyor
export const ISOStringToDate2 = date => {
    let tmpDate = new Date(date);
    let returnDate = ("0" + tmpDate.getDate()).slice(-2) + "." + ("0" + (tmpDate.getMonth() + 1)).slice(-2) + '.' + tmpDate.getFullYear()
    return returnDate
}

//ISO Formatlı datetime içerikleri formatlamak için kullanılıyor. Tarihin yanına saatte ekleniyor. TimeZone ihtiyacı duyulmuyor
export const ISOStringToDateWithTime = date => {
    let tmpDate = new Date(date);
    let returnDate = ("0" + tmpDate.getDate()).slice(-2) + "." + ("0" + (tmpDate.getMonth() + 1)).slice(-2) + '.' + tmpDate.getFullYear()
    let returnTime = ("0" + tmpDate.getHours()).slice(-2) + ":" + ("0" + tmpDate.getMinutes()).slice(-2) + ":" + ("0" + tmpDate.getSeconds()).slice(-2);

    return returnDate + " / " + returnTime;
}

export const ISODateToReadableDateWithSeconds = (dateString) => {
    const [date, time] = dateString.split(" ");
    const [year, month, day] = date.split("-");

    return `${day}.${month}.${year} / ${time}`
}

export const dateToStringWithTime = date => {
    let tmpDate = new Date(date);
    let returnDate = tmpDate.getFullYear() + '-' + ("0" + (tmpDate.getMonth() + 1)).slice(-2) + '-' + ("0" + tmpDate.getDate()).slice(-2);
    let returnTime = ("0" + tmpDate.getHours()).slice(-2) + ":" + ("0" + tmpDate.getMinutes()).slice(-2) + ":" + ("0" + tmpDate.getSeconds()).slice(-2);

    return returnDate + "%20" + returnTime;
}

//Full Date formatını Short Date(2023-03-16T00:00:00) formatına çeviren fonksiyon.
export const fullDateToShortDate = dateString => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    return formattedDate;
}

//Full Date formatını Short Date(2023-03-16T00:00:00) formatına çeviren fonksiyon.
export const fullDateToShortDateWithTime = (dateString, hour = 0, minute = 0, second = 0) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = hour.toString().padStart(2, "0");
    const minutes = minute.toString().padStart(2, "0");
    const seconds = second.toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    return formattedDate;
}

export const fullDateToShortDateWithAdjustTimezone = (date, hour = 0, minute = 0) => {
    let dateObject = new Date(date);

    let tempHour = parseInt(hour, 10); // Saat
    let tempMinute = parseInt(minute, 10); // Dakika

    dateObject.setHours(tempHour);
    dateObject.setMinutes(tempMinute);

    return dateObject.toISOString();
}

export const dateToShortDateStringWithoutTimezone = (date, hour = 0, minute= 0, second = 0) => {
    const timezoneDate = new Date(date?.getFullYear(), date.getMonth(), date.getDate(), hour, minute, second)
    return timezoneDate.toISOString();
}

//Full date formatını yıl-ay-gün değerleri bitiş olacak şekilde(20230302) formatlayan fonksiyon. 
export const fullDateToShortDate2 = dateString => {
    const year = dateString.getFullYear();
    const month = ('0' + (dateString.getMonth() + 1)).slice(-2);
    const day = ('0' + dateString.getDate()).slice(-2);
    return `${year}${month}${day}`;
}

//Date string'leri okunabilir hale getirir. (Saatli bir şekilde)
export const dateStringToReadableDate = (dateString) => {
    const [date, time] = dateString.split(' ');
    const dateParts = date.split('.');
    const day = dateParts[0].length === 1 ? '0' + dateParts[0] : dateParts[0];
    const month = dateParts[1].length === 1 ? '0' + dateParts[1] : dateParts[1];
    const year = dateParts[2];
    const timeParts = time.split(':');
    const hour = timeParts[0].length === 1 ? '0' + timeParts[0] : timeParts[0];
    const minute = timeParts[1].length === 1 ? '0' + timeParts[1] : timeParts[1];
    return day + '.' + month + '.' + year + ' / ' + hour + ':' + minute;
}

//Date string'leri okunabilir hale getirir. (Saatsiz bir şekilde)
export const dateStringToReadableDateWithoutTime = (dateString) => {
    let dateArr = dateString.split(".");
    return ("0" + dateArr[0]).slice(-2) + "." + ("0" + dateArr[1]).slice(-2) + "." + dateArr[2];
}

//Eğitmen detayı verileri bölünerek saate employee(kullanıcıya) ait timezone ekleniyor
export const addTimeZoneToFullDate = (date, timeZone) => {
    let tempArr = date.split(" ");
    //Saate çalışana ait timezone ekleniyor
    let tempTime = tempArr[1].replace(/^\s+|\s+$/gm, '');
    let lessonTime = addTimeZone(tempTime, timeZone);
    //Tarih formatları bazen 4.01.2023 gibi geliyor. Bunu 04.01.2023 şeklinde standarta getiriyoruz
    let tempDateArr = tempArr[0].split(".");
    let tempDate = ("0" + tempDateArr[0]).slice(-2) + "." + ("0" + tempDateArr[1]).slice(-2) + "." + tempDateArr[2];

    return tempDate + " / " + lessonTime;
}

//04.03.2022 22:00 gibi tarihlere timezone eklemek için kullanılıyor
export const addTimeZoneToFullDate2 = (date, timeZone) => {
    let tempArr = date.split(" ");
    let tmpDate = new Date(tempArr[0].replace(/\./g, "-").split("-").reverse().join("-"));
    const dateTmpTime = tempArr[1].split(":");
    tmpDate = new Date(Date.UTC(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate(), parseInt(dateTmpTime[0]), parseInt(dateTmpTime[1]), 0));

    let returnDate = ("0" + tmpDate.getDate()).slice(-2) + "." + ("0" + (tmpDate.getMonth() + 1)).slice(-2) + '.' + tmpDate.getFullYear();
    let tmpTime = tmpDate.getUTCHours() + ":" + tmpDate.getUTCMinutes();
    return returnDate + " / " + addTimeZone(tmpTime, timeZone)
}
// 2020-10-14 18:04:31.023 -> 14.10.2020 / 21:04
export const addTimeZoneToFullDate3 = (date, timeZone) => {
    let tempArr = date.split(" ");
    let dateString = tempArr[0].replace(/\./g, "-").split("-")
    let tmpDate = new Date(`${dateString[1]}/${dateString[2]}/${dateString[0]}`);
    const dateTmpTime = tempArr[1].split(":");
    tmpDate = new Date(Date.UTC(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate(), parseInt(dateTmpTime[0]), parseInt(dateTmpTime[1]), 0));

    let returnDate = ("0" + tmpDate.getDate()).slice(-2) + "." + ("0" + (tmpDate.getMonth() + 1)).slice(-2) + '.' + tmpDate.getFullYear();
    let tmpTime = tmpDate.getUTCHours() + ":" + tmpDate.getUTCMinutes();
    return returnDate + " / " + addTimeZone(tmpTime, timeZone)
}

//Timezone eklemek için kullanılan fonksiyon
export const addTimeZone = (tm, timeZone) => {
    let today = new Date();
    let timeArr = tm.split(":");
    let time = new Date(today.getFullYear(), today.getMonth(), today.getDay(), parseInt(timeArr[0]), parseInt(timeArr[1]));
    let returnTime = new Date(time.setMinutes(time.getMinutes() + parseInt(timeZone)));
    return ("0" + returnTime.getHours()).slice(-2) + ':' + ("0" + returnTime.getMinutes()).slice(-2);
}

export const adjustTimeWithTimeZone = (time, employeeTimeZone) => {
    const adjustedHour = (parseInt(time.split(':')[0]) - Math.floor(employeeTimeZone / 60) + 24) % 24;
    const adjustedTime = `${adjustedHour.toString().padStart(2, "0")}:${time.split(':')[1]}`;
    return adjustedTime;
}

// 2/1/2024 11:58:02 PM tarih formatını -> 18.04.2024 19.58.02 formatına çeviriri
export const formatForeignDateToTurkishDateWithTimezone = (dateString, timeZone) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    const timeWithTimeZone = addTimeZone(`${hours}:${minutes}`, timeZone);

    return `${day}.${month}.${year} ${timeWithTimeZone}:${seconds}`;
}

// 23.07.2024 21:15:01 şeklinde gelen tarihlere timeZone ekler.
export const fullDateToISOWithTimeZone = (dateString, timeZone) => {
    const splittedFullDate = dateString.split(" ");
    const date = splittedFullDate[0];
    const time = splittedFullDate[1];

    const splittedDate = date.split(".");
    const splittedTime = time.split(":");

    if(splittedDate[0].length === 1)
        splittedDate[0] = "0" + splittedDate[0];

    if(splittedDate[1].length === 1)
        splittedDate[1] = "0" + splittedDate[1];

    const formattedDate = splittedDate[2] +  "-" + splittedDate[1] + "-" + splittedDate[0] + "T" + splittedTime[0] + ":" + splittedTime[1] + ":00";
    const dateWithTimeZone = ISOStringToDate(formattedDate, timeZone);

    return dateWithTimeZone + ":" + splittedTime[2]
}

export const colorForTermStatus = (status) => {
    switch (status) {
        case 0:
            return "bg-red badge-color";
        case 1:
            return "bg-green badge-color";
        case 2:
            return "bg-blue badge-color";
        default:
            return "bg-gray-200 badge badge-color !text-base-text";
    }
}

//Kullanıcının id'sine göre ilgili markaya ait logo'yu getiren fonksiyon
export const getLogoByBrandId = (id) => {
    switch (id) {
        case 1:
            return KoLogo;
        case 2:
            return AzerLogo;
        case 3:
            return AlingliziahLogo;
        default:
            return null;
    }
}

//Kullanıcının id'sine göre ilgili markanın ismini getiren fonksiyon
export const getBrandNameById = (id) => {
    switch (id) {
        case 1:
            return 'Konuşarak Öğren';
        case 2:
            return 'Azer İngilisce';
        case 3:
            return 'Alingiliziah';
        default:
            return '';
    }
}

//TC Kimlik numarasının hane sayısını kontrol eder
export const isValidIdentityNumber = (identityNumber) => {
    if (!identityNumber || identityNumber.length !== 11) {
        return false;
    }
    return true;
}


//Sayının 0 ile 100 olup olmadığını kontrol eder
export const isValueBetweenZeroToHundred = (value) => {
    if (value < 0 || value > 100) {
        return false;
    }
    return true;
}

//kredi kartı numarasını maskelemek için kullanılan fonksiyon
export const formatCreditCardNumber = value => {
    const v = value
        .replace(/\s+/g, "")
        .replace(/[^0-9]/gi, "")
        .substr(0, 16);
    const parts = [];

    for (let i = 0; i < v.length; i += 4) {
        parts.push(v.substr(i, 4));
    }

    return parts.length > 1 ? parts.join(" ") : value;
}

//kredi kartının son kullanma tarihini kontrol etmek için kullanılan fonksiyon
export const formatExpirationDate = value => {
    const v = value
        .replace(/\s+/g, "")
        .replace(/[^0-9]/gi, "")
        .substr(0, 4);

    //ilk rakamın 2 ve 2'den büyük olmamasını sağlar  
    if (v.length === 1) {
        const firstDigit = parseInt(v.substring(0, 1));
        if (firstDigit >= 2) {
            return '';
        }
    }
    //ikinci rakamın 2'den büyük olmamasını sağlar, böylece ay değeri 12'den büyük olamaz  
    if (v.length === 2) {
        const firstTwoDigits = parseInt(v.substring(0, 2));
        if (firstTwoDigits > 12) {
            return parseInt(v.substring(0, 1));
        }
        else if (parseInt(v.substring(1, 2)) === 0 && parseInt(v.substring(0, 1)) === 0) {
            return parseInt(v.substring(0, 1));
        }
    }
    //yıl kısmının şuanki yıldan küçük girilememesini sağlar
    if (v.length >= 3) {
        const date = new Date();
        const year = String(date.getUTCFullYear());
        const lastTwoDigitOfCurrentYear = year.substring(2, 4);
        const lastTwoDigitOfValue = parseInt(v.substring(2, 4));

        const thirdDigitOfValue = parseInt(v.substring(2, 3));
        const fourthDigitOfValue = parseInt(v.substring(3, 4));

        //şuanki yılın son iki rakamından daha küçük değer girilememesini sağlar
        if (typeof thirdDigitOfValue === "number" && typeof fourthDigitOfValue === "number") {
            let value;
            if (lastTwoDigitOfValue >= lastTwoDigitOfCurrentYear) {
                value = v.substring(0, 2) + '/' + v.substring(2, 4);
            } else {
                value = v.substring(0, 2) + '/' + v.substring(2, 3);
            }
            return value;
        }

    }

    //ay ve yıl arasına '/' koyar
    const parts = [];

    for (let i = 0; i < v.length; i += 2) {
        parts.push(v.substr(i, 2));
    }

    const formattedDate = parts.length > 1 ? parts.join("/") : value;

    return formattedDate;
};

//girilen değerin para formatına uygun olup olmadığını kontrol eden fonksiyon
export const checkMoney = e => {

    let keypressed = e.keyCode;

    if ((keypressed >= 48 && keypressed <= 57) ||
        (keypressed >= 96 && keypressed <= 105)
        || keypressed === 8
        || keypressed === 27
        || keypressed === 46
        || keypressed === 9
        || keypressed === 190
        || (keypressed >= 35 && keypressed <= 40)) {
        return true;
    }

    return false;
}

//'13:40:00' gelen değeri '13:40' formatına çeviren fonksiyon
export const convertTimestapToReadbleTime = (timeString) => {
    const [hours, minutes] = timeString.split(":");
    const formattedTime = `${hours}:${minutes}`;
    return formattedTime;
}

//Verilen başlangıç zamanına ve süreye göre bitiş zamanını hesaplayan fonksiyon
export const calculateEndTime = (beginTime, duration) => {

    const [hour, minute] = beginTime.split(":");

    let hourInt = parseInt(hour);
    let minuteInt = parseInt(minute);

    minuteInt += duration;

    if (minuteInt >= 60) {
        hourInt += Math.floor(minuteInt / 60);
        minuteInt = minuteInt % 60;
    }
    if (hourInt >= 24) {
        hourInt = hourInt % 24;
    }

    const endTime = ('0' + hourInt).slice(-2) + ':' + ('0' + minuteInt).slice(-2);
    return endTime;
}

//girilen değerin number olup olmadığını kontrol eden fonksiyon
export const checkNumber = e => {

    let keypressed = e.keyCode;

    if ((keypressed >= 48 && keypressed <= 57) ||
        (keypressed >= 96 && keypressed <= 105)
        || keypressed === 8
        || keypressed === 27
        || keypressed === 46
        || keypressed === 9
        || (keypressed >= 35 && keypressed <= 40)) {
        return true;
    }

    return false;
}

//Checkbox css'ini kontrol eden fonksiyon
export const handleCheckbox = (status) => {
    if (status) {
        return 'bg-green border border-green text-white'
    } else {
        return 'border border-[#aaaaaa]'
    }
}

//Current Card sayfasında kart tipi arka planını kontrol eden fonksiyon
export const colorForCurrentCard = (cardType) => {

    switch (cardType.toLocaleUpperCase()) {
        case 'ŞAHIS':
            return 'bg-green';
        case 'KURUMSAL':
            return 'bg-red';
        case 'FIRMA':
            return 'bg-blue';
        default:
            return 'bg-card';
    }
}

//Para biriminin sadece virgülden sonraki iki rakamını alan fonksiyon
export const formatCurrency = (price) => {
    const floatPrice = parseFloat(price);
    const formattedAmount = new Intl.NumberFormat('tr-TR', {
        style: 'currency',
        currency: 'TRY',
        minimumFractionDigits: 2,
    }).format(floatPrice);
    return formattedAmount;
}

export const roundPrice = (price) => {
    return Math.round(price * 100000) / 100000;
}

//Kullanıcının cihaz id'sine göre cihaz işletim sistemini string olarak getiren fonksiyon
export const getMemberDeviceOS = (device, checkHuaweiPushkit = false) => {
    switch (device) {
        case 1:
            return 'Web';
        case 2:
            return 'IOS';
        case 3:
            return checkHuaweiPushkit ? 'pushkit' : 'Android';
        case 4: 
            return 'None';
        default:
            return ''
    }
}

//ID'ye göre use case'i getiren fonksiyon
export const getUseCaseById = (professionId) => {
    switch (professionId) {
        case 1:
            return 'İş hayatında kullanmak';
        case 2:
            return 'Kariyerinde yükselmek';
        case 3:
            return 'İş toplantılarında kullanmak';
        case 4: 
            return 'Mülakat iş görüşmelerine katılmak';
        case 5: 
            return 'Yurtdışı iş seyahatleri';
        case 6: 
            return 'Yurtdışı tatil';
        case 7: 
            return 'Yurtdışına çalışmak için çıkmak';
        case 8: 
            return 'Yakın akraba ve arkadaş ile iletişim';
        case 9: 
            return 'Okulda kullanacak öğrenciler';
        default:
            return ''
    }
}

//Para birimi formatlıyoruz
export const formatPrice = (price, symbol) => {

    var formattedOutput = new Intl.NumberFormat('tr-TR', {
        style: 'currency',
        currency: 'TRY',
        minimumFractionDigits: 2,
    });

    return formattedOutput.format(price).replace('₺', '')
}

//Para birimi formatlıyoruz ve . ile dönüyoruz
export const formatPriceWithDot = (price, symbol) => {

    var formattedOutput = new Intl.NumberFormat('tr-TR', {
        style: 'currency',
        currency: 'TRY',
        minimumFractionDigits: 2,
    });

    return formattedOutput.format(price).replace('₺', '').replace(",", ".")
}

//KDV Hesaplar
export const calcTaxPrice = (price, taxRate) => {
    return parseFloat(price) * parseFloat(taxRate) / 100;
}

//Gönderilen güne göre, günün key'ini döndüren fonksiyon
export const convertDayToKey = (day) => {

    let lowerDay = day.toLocaleLowerCase();

    if (lowerDay === 'monday' || lowerDay === 'pazartesi')
        return 1;
    else if (lowerDay === 'tuesday' || lowerDay === 'salı')
        return 2;
    else if (lowerDay === 'wednesday' || lowerDay === 'çarşamba')
        return 3;
    else if (lowerDay === 'thursday' || lowerDay === 'perşembe')
        return 4;
    else if (lowerDay === 'friday' || lowerDay === 'cuma')
        return 5;
    else if (lowerDay === 'saturday' || lowerDay === 'cumartesi')
        return 6;
    else if (lowerDay === 'sunday' || lowerDay === 'pazar')
        return 7;
    else
        return -1;
}

//Hafta günlerinin çevirisinde kullanılan fonksiyon 
export const translateWeekDays = (day, language) => {

    let key = convertDayToKey(day);
    let result = '';

    switch (key) {
        case 1:
            result = language === 'tr' ? 'Pazartesi' : language === 'en' ? 'Monday' : language === 'ar' ? 'Pazartesi' : language === 'az' ? 'Pazartesi' : '';
            return result;
        case 2:
            result = language === 'tr' ? 'Salı' : language === 'en' ? 'Tuesday' : language === 'ar' ? 'Salı' : language === 'az' ? 'Salı' : '';
            return result;
        case 3:
            result = language === 'tr' ? 'Çarşamba' : language === 'en' ? 'Wednesday' : language === 'ar' ? 'Çarşamba' : language === 'az' ? 'Çarşamba' : '';
            return result;
        case 4:
            result = language === 'tr' ? 'Perşembe' : language === 'en' ? 'Thursday' : language === 'ar' ? 'Perşembe' : language === 'az' ? 'Perşembe' : '';
            return result;
        case 5:
            result = language === 'tr' ? 'Cuma' : language === 'en' ? 'Friday' : language === 'ar' ? 'Cuma' : language === 'az' ? 'Cuma' : '';
            return result;
        case 6:
            result = language === 'tr' ? 'Cumartesi' : language === 'en' ? 'Saturday' : language === 'ar' ? 'Cumartesi' : language === 'az' ? 'Cumartesi' : '';
            return result;
        case 7:
            result = language === 'tr' ? 'Pazar' : language === 'en' ? 'Sunday' : language === 'ar' ? 'Pazar' : language === 'az' ? 'Pazar' : '';
            return result;
        default:
            return '';
    }
    
}

export const iconForLessonStatus = (status, language) => {
    switch (status) {
        case 1:
            return { iconName: "schedule", color: "", tooltip: language === "tr" ? 'Aktif' : language === 'en' ? 'Active' : language === 'ar' ? 'Aktif' : language === 'az' ? 'Aktif' : '' };
        case 2:
            return { iconName: "done", color: "text-green", tooltip: language === "tr" ? 'Katıldı' : language === 'en' ? 'Attented' : language === 'ar' ? 'Katıldı' : language === 'az' ? 'Katıldı' : '' };
        case 3:
            return { iconName: "close", color: "text-red", tooltip: language === "tr" ? 'Dersi Kaçırdı' : language === 'en' ? 'Missed' : language === 'ar' ? 'Dersi Kaçırdı' : language === 'az' ? 'Dersi Kaçırdı' : '' };
        case 4:
            return { iconName: "history", color: "text-blue", tooltip: language === "tr" ? 'Erteledi' : language === 'en' ? 'Postponed' : language === 'ar' ? 'Erteledi' : language === 'az' ? 'Erteledi' : '' };
        case 5:
            return { iconName: "ac_unit", color: "", tooltip: language === "tr" ? 'Dondurdu' : language === 'en' ? 'Donduruldu' : language === 'ar' ? 'Donduruldu' : language === 'az' ? 'Donduruldu' : '' };
        case 6:
            return { iconName: "hotel", color: "", tooltip: language === "tr" ? 'Tatil' : language === 'en' ? 'Holiday' : language === 'ar' ? 'Tatil' : language === 'az' ? 'Tatil' : '' };
        case 7:
            return { iconName: "category", color: "", tooltip: language === "tr" ? 'Hafta Sonu' : language === 'en' ? 'Weekend' : language === 'ar' ? 'Hafta Sonu' : language === 'az' ? 'Hafta Sonu' : '' };
        case 8:
            return { iconName: "do_not_disturb_on", color: "", tooltip: language === "tr" ? 'Ders Yok' : language === 'en' ? 'No Lesson' : language === 'ar' ? 'Ders Yok' : language === 'az' ? 'Ders Yok' : '' };
        case 9:
            return { iconName: "construction", color: "", tooltip: language === "tr" ? 'Sistem Erteleme' : language === 'en' ? 'System Postpone' : language === 'ar' ? 'Sistem Erteleme' : language === 'az' ? 'Sistem Erteleme' : '' };
        case 10:
            return { iconName: "delete", color: "", tooltip: language === "tr" ? 'Silindi' : language === 'en' ? 'Deleted' : language === 'ar' ? 'Silindi' : language === 'az' ? 'Silindi' : '' };
        default:
            return { iconName: "", color: "", tooltip: "" };
    }    
}

export const getCompanyLogoByName = (companyName) => {
    switch (companyName) {
        case 'RENKLİ FİKİRLER BİLİŞİM VE EĞİTİM TEKNOLOJİLERİ TİC. LTD. ŞTİ.':
            return RenkliFikirlerLogo;
        case 'RF BİLİŞİM VE EĞİTİM TEKNOLOJİLERİ TİC. LTD. ŞTİ.':
            return RFLogo;
        default:
            return null;
    }
}

export const employeeTypeTranslations = (employeeType, language) => {
    switch (employeeType) {
        case "Sistem": 
        return{
            tr: "Sistem",
            en: "System",
            ar: "Sistem",
            az: "Sistem"
        }[language];
        case "Yazılımcı": 
        return {
            tr: "Yazılımcı",
            en: "Developer",
            ar: "Yazılımcı",
            az: "Yazılımcı"
        }[language];
        case "Yönetici": 
        return {
            tr: "Yönetici",
            en: "Manager",
            ar: "Yönetici",
            az: "Yönetici"
        }[language];
        case "Satış Temsilcisi": 
        return {
            tr: "Satış Temsilcisi",
            en: "Sales Person",
            ar: "Satış Temsilcisi",
            az: "Satış Temsilcisi"
        }[language];
        case "Satış Takım Lideri": 
        return {
            tr: "Satış Takım Lideri",
            en: "Sales Team Leader",
            ar: "Satış Takım Lideri",
            az: "Satış Takım Lideri"
        }[language];
        case "Satış Takım Lideri Yardımcısı": 
        return {
            tr: "Satış Takım Lideri Yardımcısı",
            en: "Sales Team Leader Assistant",
            ar: "Satış Takım Lideri Yardımcısı",
            az: "Satış Takım Lideri Yardımcısı"
        }[language];
        case "Verimlilik Temsilcisi": 
        return {
            tr: "Verimlilik Temsilcisi",
            en: "Productivity Person",
            ar: "Verimlilik Temsilcisi",
            az: "Verimlilik Temsilcisi"
        }[language];
        case "Verimlilik Takım Lideri": 
        return {
            tr: "Verimlilik Takım Lideri",
            en: "Productivity Team Leader",
            ar: "Verimlilik Takım Lideri",
            az: "Verimlilik Takım Lideri"
        }[language];
        case "Verimlilik Takım Lideri Yardımcısı": 
        return {
            tr: "Verimlilik Takım Lideri Yardımcısı",
            en: "Productivity Team Leader Assistant",
            ar: "Verimlilik Takım Lideri Yardımcısı",
            az: "Verimlilik Takım Lideri Yardımcısı"
        }[language];
        case "Kurumsal Destek Temsilcisi": 
        return {
            tr: "Kurumsal Destek Temsilcisi",
            en: "Corporate Support Person",
            ar: "Kurumsal Destek Temsilcisi",
            az: "Kurumsal Destek Temsilcisi"
        }[language];
        case "Kurumsal Takım Lideri": 
        return {
            tr: "Kurumsal Takım Lideri",
            en: "Corporate Team Leader",
            ar: "Kurumsal Takım Lideri",
            az: "Kurumsal Takım Lideri"
        }[language];
        case "Kurumsal Takım Lideri Yardımcısı": 
        return {
            tr: "Kurumsal Takım Lideri Yardımcısı",
            en: "Corporate Team Leader Assistant",
            ar: "Kurumsal Takım Lideri Yardımcısı",
            az: "Kurumsal Takım Lideri Yardımcısı"
        }[language];
        case "Kurumsal Kilit Hesap Yöneticisi": 
        return {
            tr: "Kurumsal Kilit Hesap Yöneticisi",
            en: "Corporate Key Account Manager",
            ar: "Kurumsal Kilit Hesap Yöneticisi",
            az: "Kurumsal Kilit Hesap Yöneticisi"
        }[language];
        case "Muhasebe": 
        return {
            tr: "Muhasebe",
            en: "Accounting",
            ar: "Muhasebe",
            az: "Muhasebe"
        }[language];
        case "Destek":
        return  {
            tr: "Destek",
            en: "Support",
            ar: "Destek",
            az: "Destek"
        }[language];
        case "Referansatör": 
        return {
            tr: "Referansatör",
            en: "Referencer",
            ar: "Referansatör",
            az: "Referansatör"
        }[language];
        case "İnsan Kaynakları": 
        return {
            tr: "İnsan Kaynakları",
            en: "Human Resources",
            ar: "İnsan Kaynakları",
            az: "İnsan Kaynakları"
        }[language];
        case "Pazarlama": 
        return {
            tr: "Pazarlama",
            en: "Marketing",
            ar: "Pazarlama",
            az: "Pazarlama"
        }[language];
        case "Ürün Yöneticisi": 
        return {
            tr: "Ürün Yöneticisi",
            en: "Product Manager",
            ar: "Ürün Yöneticisi",
            az: "Ürün Yöneticisi"
        }[language];
        case "Santral":
        return {
            tr: "Santral",
            en: "Santral",
            ar: "Santral",
            az: "Santral"
        }[language];
        case "Stajyer":
        return {
            tr: "Stajyer",
            en: "Intern",
            ar: "Stajyer",
            az: "Stajyer"
        }[language];
        default:
            return '';
    }
};

export const convertToTurkishUpper = (text) => {
    const turkishChars = ['ı', 'i', 'ş', 'ğ', 'ü', 'ö', 'ç', 'İ', 'I', 'Ş', 'Ğ', 'Ü', 'Ö', 'Ç'];
    const englishChars = ['I', 'I', 'S', 'G', 'U', 'O', 'C', 'I', 'I', 'S', 'G', 'U', 'O', 'C'];
  
    let converted = text;
  
    for (let i = 0; i < turkishChars.length; i++) {
      const regex = new RegExp(turkishChars[i], 'g');
      converted = converted.replace(regex, englishChars[i]);
    }
  
    converted = converted.toUpperCase();
    return converted;
};

export const getSelectedLanguageByBrandId = (brandId) => {
    switch(brandId) {
        case 1:
            return 'tr';
        case 2: 
            return 'az';
        case 3:
            return 'ar';
    }
}

export const getLanguageTypeByLanguageKey = (languageKey) => {
    switch(languageKey) {
        case 'tr':
            return 1;
        case 'az': 
            return 2;
        case 'ar':
            return 3;
        case 'en':
            return 4;
    }
}

export const getLanguageKeyBySelectedLanguage = (language) => {
    switch(language) {
        case 'tr':
            return 'tr-TR';
        case 'en':
            return 'en-US';
        case 'az':
            return 'az';
        case 'ar':
            return 'ar';
    }
};

export const removeSpaces = (inputString)  => {
    return inputString.replace(/\s/g, '');
}

export const findDifferentElements = (arr1, arr2) => {
    const differentElements = [];

    for (const element of arr1) {
        if (!arr2.includes(element)) {
            differentElements.push(element);
        }
    }

    for (const element of arr2) {
        if (!arr1.includes(element)) {
            differentElements.push(element);
        }
    }

    return differentElements;
}

export const checkIfAnyValueNotEmpty = (obj) => {
    return Object.values(obj).map(value => value !== "").some(Boolean);
};

export const GetApplicationNameByApplicationId = (appId) => {
    switch (appId){
        case 1:
            return "KonusarakOgren";
        case 2:
            return "Azeringilisce";
        case 3:
            return "Alingiliziah";
        case 4:
            return "Alingiliziah";
        default:
            return "";
    }
}

export const createParameterObject = (parameters) => {
    const parameterObject = {};

    parameters.forEach(paramId => {
        const paramType = ParameterTypes.find(type => type.id === paramId);
        if (paramType) {
            parameterObject[paramType.name] = paramId;
        }
    });

    return parameterObject;
}

export let getFromBetween = {
    results: [],
    string: "",
    getFromBetween: function (sub1, sub2) {
        if (this.string.indexOf(sub1) < 0 || this.string.indexOf(sub2) < 0) return false;
        var SP = this.string.indexOf(sub1) + sub1.length;
        var string1 = this.string.substr(0, SP);
        var string2 = this.string.substr(SP);
        var TP = string1.length + string2.indexOf(sub2);
        return this.string.substring(SP, TP);
    },
    removeFromBetween: function (sub1, sub2) {
        if (this.string.indexOf(sub1) < 0 || this.string.indexOf(sub2) < 0) return false;
        var removal = sub1 + this.getFromBetween(sub1, sub2) + sub2;
        this.string = this.string.replace(removal, "");
    },
    getAllResults: function (sub1, sub2) {
        // first check to see if we do have both substrings
        if (this.string.indexOf(sub1) < 0 || this.string.indexOf(sub2) < 0) return;

        // find one result
        var result = this.getFromBetween(sub1, sub2);
        // push it to the results array
        this.results.push(result);
        // remove the most recently found one from the string
        this.removeFromBetween(sub1, sub2);

        // if there's more substrings
        if (this.string.indexOf(sub1) > -1 && this.string.indexOf(sub2) > -1) {
            this.getAllResults(sub1, sub2);
        }
        else return;
    },
    get: function (string, sub1, sub2) {
        this.results = [];
        this.string = string;
        this.getAllResults(sub1, sub2);
        return this.results;
    }
};

//Telefon numarasını ülkeye göre maskeleyen fonksiyon.
export const maskPhoneNumber = (phoneNumber) => {
    try {
        const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);
        if (parsedPhoneNumber) {
            const numberWithoutDialCode = phoneNumber.substring(parsedPhoneNumber.countryCallingCode.length + 1);
            if (numberWithoutDialCode.startsWith('0')) {
                throw new Error("Invalid phone number");
            }
            return parsedPhoneNumber.formatInternational();
        } else {
            return phoneNumber;
        }
    } catch (error) {
        return phoneNumber;
    }
}

export const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
}

//Ödeme durumuna göre ödeme durumu yazısını getiren fonksiyon.
export const getAccountingStatusName = (id) => {
    switch (id) {
        case 0:
            return 'MUHASEBE ONAYSIZ';
        case 1:
            return 'MUHASEBE ONAYLI';
        case 2:
            return 'İPTAL EDİLDİ';
        default:
            return '';
    }
}

// Kopyala/yapıştır ile latin harfleri/karakterleri dışında input girilmesi engelleniyor.
export const allowPasteLatinCharacters = (e) => {
    const pastedText = e.clipboardData.getData('text');

    const latinAlphabetRegex = /^[a-zA-ZıİiöÖüÜşŞğĞçÇ\s]*$/;

    if (!latinAlphabetRegex.test(pastedText)) {
        e.preventDefault();
        return;
    }
};

//onKeyDown sırasında latin harfleri dışında karakter girilmesi engelleniyor.
export const allowLatinCharacters = (e) => {
    const turkishVowels = /[ıiöüşğçİ0123456789]/i;

    if (!e.key.match(/[a-zA-Z]/) && e.key !== " " && !turkishVowels.test(e.key)) {
        e.preventDefault();
    }
};

//Girilen iki objenin birbirine eşit olup olmadığını getiren fonksiyon
export const areObjectsEqual = (obj1, obj2) => {
    for (let key in obj1) {
        if (obj1[key] !== obj2[key]) {
            return false;
        }
    }
    for (let key in obj2) {
        if (obj1[key] !== obj2[key]) {
            return false;
        }
    }
    return true;
}

export const removeHtmlTags = (inputText) => {
    let tempDiv = document.createElement('div');
    tempDiv.innerHTML = inputText;
    return tempDiv.textContent || tempDiv.innerText;
}

export const getBrandColorByBrandId = (brandId) => {
    switch (brandId){
        case 1:
            return 'bg-ko-color'
        case 2:
            return 'bg-az-color'
        case 3:
            return 'bg-al-color'
    }
}

export const PermissionTypes = [
    { id:0, name: "Bildirim İzni"},
    { id:1, name: "Mikrofon İzni"},
    { id:2, name: "Ders Kaydının Alınmasına Onay İzni"},
    { id:3, name: "Rehber Erişim İzni"}
]

export const getPermissionNames = (permissionTypeId) => {
    switch (permissionTypeId){
        case 0:
            return 'Bildirim İzni'
        case 1:
            return 'Mikrofon İzni'
        case 2:
            return 'Ders Kaydının Alınmasına Onay İzni'
        case 3:
            return 'Rehber Erişim İzni'
    }
}

export const convertSecondsToMinutesAndSeconds = (seconds, strings) => {
    if (seconds === null || typeof seconds !== 'number' || seconds < 0) {
        seconds = 0;
    }

    const minuteString = strings.table.minute;
    const secondString = strings.table.second;

    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    if (minutes > 0 && remainingSeconds > 0) {
        return `${minutes} ${minuteString} ${remainingSeconds} ${secondString}`;
    } else if (minutes > 0) {
        return `${minutes} ${minuteString}`;
    } else {
        return `${remainingSeconds} ${secondString}`;
    }
}

export const customDecodeURI = (content) => {
    let tmpContent = content.replace(/%0A/g, "<br>");

    return tmpContent;
}

//Markaya göre para işaretini getiren fonksiyon
export const getCurrencySymbolByBrandId = (brandId) => {
    switch (brandId) {
        case 1:
            return "₺";
        case 2:
            return "₼";
        case 3:
            return "$";
        default:
            return "₺"
    }
}

export const deleteSpacesInThePhoneNumber = (phoneNumber, dialCode) => {
    let modifiedPhoneNumber = phoneNumber
        .replaceAll(" ", "")
        .replace(/^0+/, '');
    return '+' + dialCode + modifiedPhoneNumber
}

//Pop-up açıp kapatma fonksiyonları
export const openPopup = (modal) => {
    modal?.current?.classList?.remove('scale-0');
    modal?.current?.classList?.remove('scale-100');
}

export const closePopup = (modal) => {
    modal?.current?.classList?.add('scale-0');
    modal?.current?.classList?.remove('scale-100');
}
//Pop-up açıp kapatma fonksiyonları

export const turkishToEnglishUpperCase = (str) => {
    const turkishChars = {
        'ç': 'C',
        'ö': 'O',
        'ğ': 'G',
        'ş': 'S',
        'ı': 'I',
        'ü': 'U',
        'Ç': 'C',
        'Ö': 'O',
        'Ğ': 'G',
        'Ş': 'S',
        'İ': 'I',
        'Ü': 'U'
    };

    return str.replace(/[çöğşıüÇÖĞŞİÜ]/g, function(match) {
        return turkishChars[match];
    }).toUpperCase();
}

export const generateOrderNumber = (memberName) => {
    const tempMemberName = turkishToEnglishUpperCase(memberName.replaceAll(" ", ""));
    // Tarih bilgisini alır
    const date = new Date();
    // Tarihi "ddMMyyyy" formatına dönüştürür
    const day = ('0' + date.getUTCDate()).slice(-2);
    const month = ('0' + (date.getUTCMonth() + 1)).slice(-2);
    const year = date.getUTCFullYear();
    const dateString = `${day}${month}${year}`;
    // Saati "HHmmss" formatına dönüştürür
    const hours = ('0' + date.getUTCHours()).slice(-2);
    const minutes = ('0' + date.getUTCMinutes()).slice(-2);
    const seconds = ('0' + date.getUTCSeconds()).slice(-2);
    const timeString = `${hours}${minutes}${seconds}`;
    // Random iki harf
    const randomLetters = String.fromCharCode(65 + Math.floor(Math.random() * 26)) + String.fromCharCode(65 + Math.floor(Math.random() * 26));
    // Random iki rakam
    const randomNumbers = ('0' + Math.floor(10 + Math.random() * 90)).slice(-2);

    return `${tempMemberName}${dateString}${timeString}${randomLetters}${randomNumbers}`;
}

export const parseSecurePaymentKeyToJson = (key) => {

    const binaryString = atob(key);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    const decodedToken = new TextDecoder('utf-8').decode(bytes);    

    const parsedToken = new URLSearchParams(decodedToken);
    const authCode = parsedToken.get("AuthCode");
    const response = parsedToken.get("Response");
    const errorMessage = parsedToken.get("ErrMsg");

    return {
        AuthCode: authCode,
        Response: response,
        ErrorMessage: errorMessage
    };
}

export const replaceTurkishChars = (str) => {
    const turkishChars = {
        'ı': 'i', 'İ': 'I',
        'ğ': 'g', 'Ğ': 'G',
        'ü': 'u', 'Ü': 'U',
        'ş': 's', 'Ş': 'S',
        'ö': 'o', 'Ö': 'O',
        'ç': 'c', 'Ç': 'C'
    };
    return str.replace(/[ıİğĞüÜşŞöÖçÇ]/g, match => turkishChars[match]);
}

/**
 * Converts timezone offset in minutes to readable format
 * @param {number} timezoneOffset - Timezone offset in minutes
 * @returns {string} Formatted timezone string (e.g. "+3 Saat", "-2.5 Saat")
 */
export const formatTimezoneOffset = (timezoneOffset, hourString) => {
  if (timezoneOffset === null || timezoneOffset === undefined) return "";
  
  // Convert minutes to hours
  const hours = Math.abs(timezoneOffset) / 60;
  
  // Determine if it's positive or negative offset
  const sign = timezoneOffset >= 0 ? "+" : "-";
  
  // Check if hours have decimal part
  const hasDecimal = hours % 1 !== 0;
  
  // Format the number
  const formattedHours = hasDecimal ? hours.toFixed(1) : hours;
  
  return `${sign}${formattedHours} ${hourString}`;
};
