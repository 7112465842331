import React, { useEffect, useState } from "react";
import { useLocalization } from "../../../../../../hooks/Utils/useLocalization";
import { convertSecondsToMinutesAndSeconds, ISODateToReadableDateWithSeconds } from "../../../../../../components/Utility";
import useMemberName from "../../../../../../hooks/Member/useMemberName";
import { useParams } from "react-router-dom";
import { GetTranscribesByEmployeeCallRecordIds } from "../../../../../../services/Employee/EmployeeService";

const ReadTranscribeModal = ({ data }) => {
    
    const strings = useLocalization();
    const { memberId } = useParams();
    const memberName = useMemberName(memberId);

    const [transcribe, setTranscribe] = useState("");

    useEffect(() => {
        GetTranscribesByEmployeeCallRecordIds({ employeeCallRecordIds: [data.callId] })
            .then(result => {
                if(result.status === 200) {
                    const resultContent = JSON.parse(result.content);
                    setTranscribe(resultContent[0].transcribe);
                }
            })
            .catch();
    }, [data.callId]);

    return (
        <div className="flex flex-col gap-3 p-4">
            <div className="flex items-center">
                <span className="material-symbols-outlined mr-2 text-[19px]">
                    description
                </span>
                <p className="font-bold">
                    {strings.member.member_detail.call_records.transcript}
                </p>
            </div>
            <hr />
            <div className="flex flex-col gap-2 bg-card p-3 rounded-md">
                <div className="flex items-center">
                    <span className="material-symbols-outlined mr-2 text-[19px]">
                        person
                    </span>
                    <p>{memberName}</p>
                </div>
                <div className="flex items-center">
                    <span className="material-symbols-outlined mr-2 text-[19px]">
                        person
                    </span>
                    <p>{data?.callerName}</p>
                </div>
                <div className="flex items-center">
                    <span className="material-symbols-outlined mr-2 text-[19px]">
                        timer
                    </span>
                    <p>{convertSecondsToMinutesAndSeconds(data?.callDuration, strings)}</p>
                </div>
                <div className="flex items-center">
                    <span className="material-symbols-outlined mr-2 text-[19px]">
                        calendar_month
                    </span>
                    <p>{ISODateToReadableDateWithSeconds(data?.callDate)}</p>
                </div>
            </div>
            <hr />
            <div className="h-[calc(100vh-350px)] overflow-y-auto">
                {transcribe.length > 0 ? (
                    <div className="whitespace-pre-line leading-relaxed">
                        {transcribe}
                    </div>
                ) : (
                    <p className="text-center">
                        {strings.member.member_detail.call_records.transcript_not_found}
                    </p>
                )}
            </div>
        </div>
    );
};

export default ReadTranscribeModal;