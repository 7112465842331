import React, { forwardRef } from 'react';
import { StorageKeys } from "../../consts/Constants";
import { useLocalization } from '../../hooks/Utils/useLocalization';
import { url } from "../../routes/utility";
import { PostponeReminder } from '../../services/Member/MemberFormService';
import { removeFromLocalStorage } from "../../utils";
import Popup from '../Popup';
import { showTopAlert } from '../SweetAlert';
import { closePopup } from '../Utility';

const NavbarReminderPopup = forwardRef((props, ref) => {

    const { alertInfo } = props;
    const strings = useLocalization();

    const minutes = [
        {
            text: strings.navbar.reminders.reminder_popup.minute.replace("#minute#", "15"),
            value: 15
        },
        {
            text: strings.navbar.reminders.reminder_popup.minute.replace("#minute#", "30"),
            value: 30
        },
        {
            text: strings.navbar.reminders.reminder_popup.hour.replace("#hour#", "1"),
            value: 60
        },
        {
            text: strings.navbar.reminders.reminder_popup.hour.replace("#hour#", "2"),
            value: 120
        },
        {
            text: strings.navbar.reminders.reminder_popup.hour.replace("#hour#", "4"),
            value: 240
        }
    ];

    const submitHandle = async (minutes) => {
        //Dakika 15 ile 240 aralığında olabilir
        //alertInfo değişkeninden id'yi alacağız
        if (alertInfo && (minutes > 14 && minutes < 241)) {
            let model = {
                memberFormActivityId: alertInfo.memberFormActivityId,
                minutes: minutes
            };

            const result = await PostponeReminder(model);

            if (result.status === 200) {
                showTopAlert(strings.member.member_detail.candidate_operations.reminder_update_success_message, "success");
                closePopup(ref);
                window.location.reload();
                removeFromLocalStorage(StorageKeys.ReminderLocalStorageKey, alertInfo.memberFormActivityId);
            } else {
                showTopAlert(strings.errors.an_error_occurred, "error");
            }
        }
    }

    return (
        <Popup
            ref={ref}
            showCancelButton={true}
        >            
            <div className="mt-2 text-lg font-bold text-center text-blue">{strings.navbar.reminders.reminder_popup.title}</div>

            <div className="p-4 flex flex-col items-center">
                <button
                    type="button"
                    className="text-center font-bold min-w-max text-blue hover:underline"
                    onClick={() =>
                        window.open(
                            url("member.detail.profile", { memberId: alertInfo?.memberId }),
                            '_blank'
                        )
                    }
                >
                    {alertInfo?.memberFullName}
                </button>
                <p className="text-sm">{alertInfo?.reminderContent}</p>
            </div>

            <hr className='mb-5 border-1' />

            <div className="flex justify-between gap-2 max-w-max mx-auto">
                {minutes.map((item, index) => (
                    <button
                        key={index}
                        className="button w-[100px] search-button text-sm text-white bg-red border-red focus:border-red"
                        onClick={() => submitHandle(item.value)}
                    >
                        {item.text}
                    </button>
                ))}
            </div>

            <div className='text-xs text-center mt-3 text-gray-500'>
                <span className='font-bold'>(*)</span> {strings.navbar.reminders.reminder_popup.description}
            </div>
        </Popup>
    )
});

export default NavbarReminderPopup