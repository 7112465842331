import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Loading,
  MemberInformations,
  PageTitle,
  Pagination,
  SearchCount,
  showTopAlert
} from "../../../../../../components";
import { stringToDate } from "../../../../../../components/Utility";
import { useLocalization } from "../../../../../../hooks/Utils/useLocalization";
import { MemberFormNotesFilter } from "../../../../../../services/Member/MemberFormService";
import FormNoteDetailSearch from "./FormNoteDetailSearch";
import FormNoteTable from "./FormNoteTable";

const FormNote = (props) => {

  const strings = useLocalization();
  const { outsideMemberId = "" } = props;
  const { memberId } = useParams() || false;
  const limit = outsideMemberId.length > 0 ? 5 : 10;

  const [start, setStart] = useState(0);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [data, setData] = useState([]);
  const [useMemberId, setUseMemberId] = useState("");
  const [detailSearch, setDetailSearch] = useState({
    beginDate: null,
    endDate: null,
    employee: null,
  });

  //Arama verileri değiştiğinde değerleri sıfırlıyoruz
  const resetValue = () => {
    setStart(start => 0);
    setCurrentPage(currentPage => 1);
    setLoading(loading => true);
  }

  //select değişiklikleri bu alandan yönetiliyor
  const changeDetail = (values) => {
    setDetailSearch({ ...detailSearch, ...values });
    resetValue();
  };

  const getMemberFormNote = async (memberId) => {
    const model = {
      start: start,
      length: limit,
      memberId: memberId,
      beginDate: detailSearch.beginDate ? stringToDate(detailSearch.beginDate) : null,
      endDate: detailSearch.endDate ? stringToDate(detailSearch.endDate, 23, 59, 59) : null,
      createUser: detailSearch.employee === -1 ? null : detailSearch.employee
    }

    const result = await MemberFormNotesFilter(model);

    if(result.status === 200){
      let resultContent = JSON.parse(result.content);
      setData(resultContent.data);
      setTotalRecord(resultContent.recordsTotal);
      setLoading(false);
    } else {
      showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
    }
  }

  useEffect(() => {
    if (totalRecord !== 0) {
      getMemberFormNote(useMemberId);
    }
  }, [start])

  useEffect(() => {
    let abortController = new AbortController();

    useMemberId && getMemberFormNote(useMemberId);

    return () => {
      abortController.abort();
    }

  }, [detailSearch, useMemberId])

  useEffect(() => {
      /*
         * Props'dan gelen bir memberid var ise sayfada kullanılan memberId buna set ediliyor.
         * Props'dan gönderilen bir memberid yok ise sayfada kullanılan memberId params'a göre set ediliyor
      */
      outsideMemberId.length > 0
        ? setUseMemberId(outsideMemberId)
        : setUseMemberId(memberId);
  }, []);

  return (
    <>
      <FormNoteDetailSearch changeDetail={changeDetail} />
      <div className="overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
        <div className="inline-block min-w-full rounded-lg overflow-hidden py-6">
          {!loading && <SearchCount count={totalRecord} />}
          <PageTitle title={strings.member.member_detail.header.content.candidate_notes} />
          <MemberInformations memberId={useMemberId} viewStatus={['name', 'phone', 'email']} />
          {loading ?
            (
              <Loading />
            )
            :
            (
              <>
                <FormNoteTable
                  data={data}
                />
                <Pagination
                  totalCount={totalRecord}
                  limit={limit}
                  start={start}
                  setStart={setStart}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  setLoading={setLoading}
                  loadScreen={true}
                />
              </>
            )
          }
        </div>
      </div>
    </>
  )
}

export default FormNote