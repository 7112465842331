import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Input, PageTitle } from '../../components';
import { DropdownSearchReadOnly } from '../../components/Dropdown';
import { getddMMyyyyDate } from '../../components/Utility';
import { ApplicationList, CampaignMemberType, CampaignPopulationType, CampaignTypeNames, CombinedDiscount, PaymentOptions } from '../../consts/Constants';
import { useLocalization } from '../../hooks/Utils/useLocalization';
import usePrimeData from '../../hooks/Utils/usePrimeData';
import { url } from '../../routes/utility';
import { GetCampaignDetailsByCampaignId } from '../../services/Accounting/CampaignService';
import { GetBankListByBrandId } from '../../services/Bank/BankService';
import { GetProductTitleByBrandId } from '../../services/Product/ProductService';

const DiscountCodeDetail = () => {

    const location = useLocation();
    const strings = useLocalization();
    const primeData = usePrimeData();
    const navigate = useNavigate();

    const discountId = location.state?.discountId || false;

    const [data, setData] = useState({});
    const [brand, setBrand] = useState({});
    const [campaignTypeName, setCampaignTypeName] = useState("");
    const [campaignDateRange, setCampaignDateRange] = useState("");
    const [productList, setProductList] = useState([]);
    const [channelList, setChannelList] = useState([]);
    const [memberTypeList, setMemberTypeList] = useState([]);
    const [memberList, setMemberList] = useState([]);
    const [salesTypeList, setSalesTypeList] = useState([]);
    const [allBankList, setAllBankList] = useState([]);
    const [bankList, setBankList] = useState([]);
    const [paymentOptionsList, setPaymentOptionsList] = useState([]);
    const [combinedDiscountList, setCombinedDiscountList] = useState([]);
    const [additionalLessonCount, setAdditionalLessonCount] = useState("");
    const [postponeLessonCount, setPostponeLessonCount] = useState("");
    const [missedLessonCount, setMissedLessonCount] = useState("");
    const [teacherHourChangeCount, setTeacherHourChangeCount] = useState("");
    const [giftExerciseList, setGiftExerciseList] = useState([]);
    const [giftPackage, setGiftPackage] = useState("");
    const [allProductList, setAllProductList] = useState([]);
    const [giftPackageDateRange, setGiftPackageDateRange] = useState("");

    //Kampanya oluşutulurken aynı kampanya kodunun olup olmadığını kontrol ediyoruz.
    const getCampaignDetailsByCampaignId = async (discountId) => {

        const result = await GetCampaignDetailsByCampaignId(discountId);

        if (result.status === 200) {
            let resultContent = JSON.parse(result.content)
            setData(data => resultContent);
        }
    }

    //Markalara ait ürünleri getiriyoruz
    const getProductTitleByBrandId = async (brandId) => {
        const result = await GetProductTitleByBrandId(brandId);

        if (result.status === 200) {
            let resultContent = JSON.parse(result.content);

            let arrayList = [];

            resultContent.map((item) => {
                let arrayItem = { id: item.value, name: item.text };
                arrayList.push(arrayItem);
            });

            if (arrayList.length > 0) {
                setAllProductList(arrayList);
            }
        }
    }

    //Banka pos listesi getiriliyor
    const getBankList = async (brandId) => {
        const result = await GetBankListByBrandId(brandId);

        let arrayList = [];
        if (result.status === 200) {
            const data = JSON.parse(result.content);
            data.map((item) => {
                let arrayItem = { id: parseInt(item.id), name: item.name + " / " + item.type };
                arrayList.push(arrayItem);
            })
        }
        setAllBankList(arrayList);
    }

    useEffect(() => {
        if (brand.id !== -1) {
            getBankList(brand.id);
            getProductTitleByBrandId(brand.id);
        }
    }, [brand])

    useEffect(() => {
        if (Object.keys(data).length > 0) {
            setBrand(brand => ApplicationList.find(application => application.id === data.brandId))
            setCampaignTypeName(campaignTypeName => CampaignTypeNames[data.campaignType])
            setCampaignDateRange(campaignDateRange => `${getddMMyyyyDate(new Date(data.startDate))} - ${getddMMyyyyDate(new Date(data.endDate))}`)

            if (data?.campaignContent) {
                const allChannelList = primeData.sourceTypeList
                const filteredChannels = allChannelList.filter(channel => JSON.parse(data.campaignContent).SourceType.includes(channel.id));
                setChannelList(channelList => filteredChannels)

                const allMemberTypeList = [...CampaignMemberType];
                const filteredMemberTypes = allMemberTypeList.filter(memberType => JSON.parse(data.campaignContent).CampaingAccountingType.includes(memberType.id));
                setMemberTypeList(memberTypeList => filteredMemberTypes)

                const allMemberList = [...CampaignPopulationType];
                const filteredMembers = allMemberList.filter(member => JSON.parse(data.campaignContent).DiscountMemberType.includes(member.id));
                setMemberList(memberList => filteredMembers)

                const allSalesTypeList = primeData.salesTypeList;
                const filteredSalesTypes = allSalesTypeList.filter(salesType => JSON.parse(data.campaignContent).SalesType.includes(salesType.id));
                setSalesTypeList(salesTypeList => filteredSalesTypes)

                const allPaymentOptionsList = [...PaymentOptions];
                const filteredPaymentOptions = allPaymentOptionsList.filter(paymentOption => JSON.parse(data.campaignContent).BankTax[0].MaxTax.includes(paymentOption.id));
                setPaymentOptionsList(paymentOptionsList => filteredPaymentOptions)

                const allCombinedDiscountList = [...CombinedDiscount];
                const filteredCombinedDiscounts = allCombinedDiscountList.filter(combinedDiscount => JSON.parse(data.campaignContent).CombinedDiscount.includes(combinedDiscount.id));
                setCombinedDiscountList(combinedDiscountList => filteredCombinedDiscounts)

                const additionalRights = JSON.parse(data?.campaignContent).AdditionalRight
                const additionalLessons = additionalRights.find(additionalRight => additionalRight.RightType === "AdditionalLesson")
                setAdditionalLessonCount(additionalLessonCount => additionalLessons.RightCount)
                const postponeLessons = additionalRights.find(additionalRight => additionalRight.RightType === "Postpone")
                setPostponeLessonCount(postponeLesson => postponeLessons.RightCount)
                const missedLessons = additionalRights.find(additionalRight => additionalRight.RightType === "Missed")
                setMissedLessonCount(missedLesson => missedLessons.RightCount)
                const teacherHourChanges = additionalRights.find(additionalRight => additionalRight.RightType === "TeacherHourChange")
                setTeacherHourChangeCount(teacherHourChange => teacherHourChanges.RightCount)

                const allGiftExerciseList = primeData.exerciseTypeList;
                const filteredGiftExercises = allGiftExerciseList.filter(giftExercise => JSON.parse(data?.campaignContent).GiftExercise.includes(giftExercise.id));
                setGiftExerciseList(giftExerciseList => filteredGiftExercises)

                if (JSON.parse(data?.campaignContent).GiftPackage) {
                    setGiftPackageDateRange(giftPackageDateRange => `${getddMMyyyyDate(new Date(JSON.parse(data?.campaignContent).GiftPackage.BeginDate))} - ${getddMMyyyyDate(new Date(JSON.parse(data?.campaignContent).GiftPackage.EndDate))}`)
                }
            }
        }
    }, [data])

    useEffect(() => {
        if (data.campaignContent) {
            const filteredBanks = allBankList.filter(bank => JSON.parse(data.campaignContent).BankTax[0]?.BankId.includes(bank.id));
            setBankList(bankList => filteredBanks)
        }
    }, [data, allBankList])

    useEffect(() => {
        const filteredProducts = allProductList.filter(product => data?.productIds.some(productId => productId.id === product.id));
        setProductList(productList => filteredProducts);

        if (data.campaignContent) {
            const tmpGiftPackage = allProductList.find(product => product.id === JSON.parse(data?.campaignContent)?.GiftPackage?.ProductId)
            setGiftPackage(giftPackage => tmpGiftPackage)
        }
    }, [data, allProductList])

    useEffect(() => {
        getCampaignDetailsByCampaignId(discountId);
    }, [discountId])

    return (
        <div className='w-full relative'>
            <button
                className="absolute top-[7px] right-4 flex items-center hover:text-blue focus:text-blue"
                onClick={() => navigate(url("accounting.discount"))}
            >
                <span id="back" className="material-symbols-outlined cursor-pointer">
                    arrow_back_ios
                </span>
                <label className="cursor-pointer font-bold" htmlFor="back">{strings.pagination.previous}</label>
            </button>
            <PageTitle title={strings.table.discount_details} />
            <div className="mt-5 lg:px-[10px] flex flex-col gap-2 max-w-[1150px] w-full sm:w-10/12 mx-auto">
                <div className="w-full relative">
                    <h5 className="font-bold text-[15px] pl-3 mb-3">{strings.accounting.discount_detail.discount_informations}</h5>
                    <hr />
                    <div className='mb-8'>
                        <div className="flex flex-col lg:flex-row lg:justify-between">
                            <div className="mt-5 w-full lg:w-[32.5%] flex flex-col px-[10px] pb-0">
                                <label className="text-[13px] font-bold mb-2" htmlFor="brand">{strings.table.brand}</label>
                                <Input
                                    id="brand"
                                    title={strings.detail_search_titles.brands}
                                    value={brand?.name || ''}
                                    classnames="md:!max-w-full"
                                    isDisabled={true}
                                />
                            </div>
                            <div className="mt-5 w-full lg:w-[32.5%]">
                                <div className="flex flex-col px-[10px] pb-0">
                                    <label className="text-[13px] font-bold mb-2" htmlFor="campaign_code">{strings.accounting.discount_detail.discount_code}</label>
                                    <div className="relative group">
                                        <Input
                                            id="campaign_code"
                                            type="text"
                                            value={data?.campaignCode || ''}
                                            isDisabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 w-full lg:w-[32.5%]">
                                <div className="flex flex-col px-[10px] pb-0">
                                    <label className="text-[13px] font-bold mb-2" htmlFor="campaign_title">{strings.accounting.discount_detail.discount_title}</label>
                                    <Input
                                        id="campaign_title"
                                        type="text"
                                        value={data?.name || ''}
                                        isDisabled={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col lg:flex-row lg:gap-x-3">
                            <div className="mt-5 w-full lg:w-[32.5%]">
                                <div className="flex flex-col px-[10px] pb-0">
                                    <label className="text-[13px] font-bold mb-2" htmlFor="discount_rate">
                                        {strings.accounting.discount_detail.discount_type}
                                    </label>
                                    <Input
                                        id="campaign_title"
                                        type="text"
                                        value={campaignTypeName || ''}
                                        isDisabled={true}
                                    />
                                </div>
                            </div>
                            <div className="mt-5 w-full lg:w-[32.5%]">
                                <div className="flex flex-col px-[10px] pb-0">
                                    <label className="text-[13px] font-bold mb-2" htmlFor="start_end_date">
                                        {strings.accounting.campaign_detail.start_end_dates}
                                    </label>
                                    <Input
                                        id="start_end_date"
                                        type="text"
                                        value={campaignDateRange || ''}
                                        isDisabled={true}
                                    />
                                </div>
                            </div>
                            <div className="mt-5 w-full lg:w-[32.5%]">
                                <div className="flex flex-col px-[10px] pb-0">
                                    <label className="text-[13px] font-bold mb-2" htmlFor="member_quato">
                                        {strings.accounting.add_campaign.limited_member}
                                    </label>
                                    <Input
                                        id="member_quato"
                                        type="text"
                                        value={data?.memberQuato || ''}
                                        isDisabled={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col lg:flex-row lg:gap-x-3">
                            <div className='mt-5 w-full lg:w-[32.5%]'>
                                <div className="flex flex-col px-[10px] pb-0">
                                    <label className="text-[13px] font-bold mb-2" htmlFor="member_quato">
                                        {strings.accounting.add_campaign.discount_rate} (%)
                                    </label>
                                    <Input
                                        id="discount_rate"
                                        type="text"
                                        value={data?.discountRate || ''}
                                        isDisabled={true}
                                    />
                                </div>
                            </div>
                            <div className="mt-5 w-full lg:w-[66%] flex flex-col px-[10px] pb-0">
                                <label className="text-[13px] font-bold mb-2" htmlFor="packages">{strings.detail_search_titles.selected_packages}</label>
                                <DropdownSearchReadOnly
                                    id="packages"
                                    title={strings.detail_search_titles.selected_packages}
                                    classnames="md:max-w-full"
                                    data={productList || []}
                                    inputPlaceholder={strings.detail_search_titles.search_package}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col px-[10px] pb-0 mt-5 w-full">
                            <label className="font-bold mb-2" htmlFor="description">
                                {strings.form_info.description}
                            </label>
                            <textarea
                                id="description"
                                className="border rounded-lg outline-0 focus:border-blue py-3 px-2 w-full"
                                type="text"
                                rows="2"
                                value={data?.description || ''}
                                disabled={true}
                            ></textarea>
                        </div>
                    </div>

                    {data?.campaignContent && (
                        <>
                            <div className="w-full relative">
                                <h5 className="font-bold text-[15px] pl-3 my-3">{strings.table.member_informations}</h5>
                                <hr />
                                <div className='mb-8'>
                                    <div className="flex flex-col lg:flex-row lg:gap-x-3">
                                        <div className="mt-5 w-full lg:w-[32.5%] flex flex-col px-[10px] pb-0">
                                            <label className="text-[13px] font-bold mb-2" htmlFor="channels">{strings.accounting.campaign_detail.channels}</label>
                                            <DropdownSearchReadOnly
                                                id="channels"
                                                title={strings.accounting.campaign_detail.channels}
                                                classnames="md:max-w-full"
                                                data={channelList || []}
                                                inputPlaceholder={strings.detail_search_titles.search_channel}
                                            />
                                        </div>
                                        <div className="mt-5 w-full lg:w-[32.5%] flex flex-col px-[10px] pb-0">
                                            <label className="text-[13px] font-bold mb-2" htmlFor="member_type">{strings.accounting.campaign_detail.member_types}</label>
                                            <DropdownSearchReadOnly
                                                id="member_type"
                                                title={strings.accounting.campaign_detail.member_types}
                                                classnames="md:max-w-full"
                                                data={memberTypeList || []}
                                                inputPlaceholder={strings.detail_search_titles.search_member_type}
                                            />
                                        </div>
                                        <div className="mt-5 w-full lg:w-[32.5%] flex flex-col px-[10px] pb-0">
                                            <label className="text-[13px] font-bold mb-2" htmlFor="members">{strings.accounting.campaign_detail.members}</label>
                                            <DropdownSearchReadOnly
                                                id="members"
                                                title={strings.accounting.campaign_detail.members}
                                                classnames="md:max-w-full"
                                                data={memberList || []}
                                                inputPlaceholder={strings.detail_search_titles.search_member}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-col lg:flex-row lg:gap-x-3">
                                        <div className="mt-5 w-full lg:w-[32.5%] flex flex-col px-[10px] pb-0">
                                            <label className="text-[13px] font-bold mb-2" htmlFor="sales_types">{strings.accounting.campaign_detail.sales_types}</label>
                                            <DropdownSearchReadOnly
                                                id="sales_types"
                                                title={strings.accounting.campaign_detail.sales_types}
                                                classnames="md:max-w-full"
                                                data={salesTypeList || []}
                                                isDisabled={false}
                                                inputPlaceholder={strings.detail_search_titles.search_sales_type}
                                            />
                                        </div>
                                        <div className="mt-5 w-full lg:w-[32.5%] flex flex-col px-[10px] pb-0">
                                            <label className="text-[13px] font-bold mb-2" htmlFor="banks">{strings.accounting.campaign_detail.banks}</label>
                                            <DropdownSearchReadOnly
                                                id="banks"
                                                title={strings.accounting.campaign_detail.banks}
                                                classnames="md:max-w-full"
                                                data={bankList || []}
                                                inputPlaceholder={strings.detail_search_titles.search_banks}
                                            />
                                        </div>
                                        <div className="mt-5 w-full lg:w-[32.5%] flex flex-col px-[10px] pb-0">
                                            <label className="text-[13px] font-bold mb-2" htmlFor="payment_options">{strings.accounting.campaign_detail.payment_options}</label>
                                            <DropdownSearchReadOnly
                                                id="payment_options"
                                                title={strings.accounting.campaign_detail.payment_options}
                                                classnames="md:max-w-full"
                                                data={paymentOptionsList || []}
                                                inputPlaceholder={strings.detail_search_titles.search_payment_options}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-col lg:flex-row lg:gap-x-3">
                                        <div className="mt-5 w-full lg:w-[32.5%] flex flex-col px-[10px] pb-0">
                                            <label className="text-[13px] font-bold mb-2" htmlFor="payment_options">{strings.detail_search_titles.avaliable_discounts}</label>
                                            <DropdownSearchReadOnly
                                                id="payment_options"
                                                title={strings.accounting.campaign_detail.discounts}
                                                classnames="md:!max-w-full"
                                                data={combinedDiscountList || []}
                                                inputPlaceholder={strings.detail_search_titles.search_discount}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {JSON.parse(data?.campaignContent).GiftPackage && (
                                <>
                                    <div className="w-full relative">
                                        <h5 className="font-bold text-[15px] pl-3 mb-3">{strings.accounting.add_campaign.selected_packages}</h5>
                                        <hr />
                                        <div className='mb-16'>
                                            <div className="flex flex-col lg:flex-row lg:justify-between">
                                                <div className="mt-5 w-full lg:w-[32.5%]">
                                                    <div className="flex flex-col px-[10px] pb-0">
                                                        <label className="text-[13px] font-bold mb-2" htmlFor="addditional_lesson_count">{strings.accounting.add_campaign.additional_lesson_count}</label>
                                                        <Input
                                                            id="addditional_lesson_count"
                                                            type="text"
                                                            value={additionalLessonCount || ''}
                                                            isDisabled={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mt-5 w-full lg:w-[32.5%]">
                                                    <div className="flex flex-col px-[10px] pb-0">
                                                        <label className="text-[13px] font-bold mb-2" htmlFor="postpone_lesson">{strings.accounting.add_campaign.postpone_lesson}</label>
                                                        <Input
                                                            id="postpone_lesson"
                                                            type="text"
                                                            value={postponeLessonCount || ''}
                                                            isDisabled={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mt-5 w-full lg:w-[32.5%]">
                                                    <div className="flex flex-col px-[10px] pb-0">
                                                        <label className="text-[13px] font-bold mb-2" htmlFor="missed_call">{strings.accounting.add_campaign.missed_call}</label>
                                                        <Input
                                                            id="missed_call"
                                                            type="text"
                                                            value={missedLessonCount || ''}
                                                            isDisabled={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex flex-col lg:flex-row lg:justify-between">
                                                <div className="mt-5 w-full lg:w-[32.5%]">
                                                    <div className="flex flex-col px-[10px] pb-0">
                                                        <label className="text-[13px] font-bold mb-2" htmlFor="teacher_time_change">{strings.member.member_detail.terms.teacher_time_change}</label>
                                                        <Input
                                                            id="teacher_time_change"
                                                            type="text"
                                                            value={teacherHourChangeCount || ''}
                                                            isDisabled={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mt-5 w-full lg:w-[32.5%]">
                                                    <div className="flex flex-col px-[10px] pb-0">
                                                        <label className="text-[13px] font-bold mb-2" htmlFor="gift_exercise">{strings.accounting.campaign_detail.gift_exercise}</label>
                                                        <DropdownSearchReadOnly
                                                            id="gift_exercise"
                                                            title={strings.accounting.campaign_detail.gift_exercise}
                                                            classnames="md:max-w-full"
                                                            data={giftExerciseList || []}
                                                            inputPlaceholder={strings.detail_search_titles.search_gift_exercise}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mt-5 w-full lg:w-[32.5%]"></div>
                                            </div>
                                        </div>
                                        <h5 className="font-bold text-[15px] pl-3 mb-3">{strings.accounting.add_campaign.gift_package}</h5>
                                        <hr />
                                        <div className='mb-8'>
                                            <div className="flex flex-col lg:flex-row lg:justify-between">
                                                <div className="flex flex-col px-[10px] pb-0 mt-5 w-full lg:w-[32.5%]">
                                                    <label className="text-[13px] font-bold mb-2" htmlFor="gift_packages">{strings.accounting.campaign_detail.gift_packages}</label>
                                                    <Input
                                                        id="gift_packages"
                                                        type="text"
                                                        value={giftPackage?.name || ''}
                                                        isDisabled={true}
                                                    />
                                                </div>
                                                <div className="mt-5 w-full lg:w-[32.5%] relative">
                                                    <div className="flex flex-col px-[10px] pb-0">
                                                        <label className="text-[13px] font-bold mb-2" htmlFor="start_end_date">
                                                            {strings.accounting.campaign_detail.start_end_dates}
                                                        </label>
                                                        <Input
                                                            id="start_end_date"
                                                            type="text"
                                                            value={giftPackageDateRange || ''}
                                                            isDisabled={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mt-5 w-full lg:w-[32.5%]">
                                                    <div className="flex flex-col px-[10px] pb-0">
                                                        <label className="text-[13px] font-bold mb-2" htmlFor="gift_discount_rate">
                                                            {strings.accounting.add_campaign.gift_package_discount_rate} (%)
                                                        </label>
                                                        <Input
                                                            id="gift_discount_rate"
                                                            type="text"
                                                            value={JSON.parse(data?.campaignContent).GiftPackage ? JSON.parse(data?.campaignContent).GiftPackage.DiscountRate : ''}
                                                            isDisabled={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mt-5 w-full lg:w-[32.5%]">
                                                <div className="flex flex-col px-[10px] pb-0">
                                                    <label className="text-[13px] font-bold mb-2" htmlFor="member_count">
                                                        {strings.accounting.add_campaign.member_count}
                                                    </label>
                                                    <Input
                                                        id="member_count"
                                                        type="text"
                                                        value={JSON.parse(data?.campaignContent).GiftPackage ? JSON.parse(data?.campaignContent).GiftPackage.Quantity : ''}
                                                        isDisabled={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    )}

                </div>
            </div>
        </div>
    )
}

export default DiscountCodeDetail