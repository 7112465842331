export const formatContent = (text) => {
    if (!text) return "";

    // ai'den </br> şeklinde gelen yazıları silip boşlukları düzenlemek için kullanılıyor
    let formattedText = text.replace(/\s*<\/br>/g, '\n');

    // ai'den gelen ** işaretleri arasındaki yazıları kalın yazı yapmak için kullanılıyor
    const parts = formattedText.split(/(\*\*.*?\*\*)/g);

    return parts.map((part, index) => {
        if (part.startsWith('**') && part.endsWith('**')) {
            return (
                <strong key={index} className="font-bold">
                    {part.slice(2, -2)}
                </strong>
            );
        }
        return <span key={index}>{part}</span>;
    });
};