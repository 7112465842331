export const Local = {
    alert: {
        title: {
            information: "Bilgilendirme Mesajı"
        },
        centered_alert_two_buttons: {
            title: "Emin Misiniz?",
            title_confirm: 'İşlem Onayı',
            confirm_text: "Tamam",
            cancel_text: "İptal",
            save_question: "Değişiklikleri kaydetmek istiyor musunuz?",
            irreversible_action: "Bu işlem geri alınamayan bir işlemdir."
        },
        top_alert: {
            inform: 'Lütfen bilgileri eksiksiz giriniz!',
            success: 'İşlem başarıyla tamamlandı!'
        }
    },
    form_info: {
        full_name: 'Ad Soyad',
        name: 'Ad',
        surname: 'Soyad',
        email: "E-Posta Adresi",
        other_email: "Diğer E-Posta Adresi",
        corporate_email: 'Kurumsal E-Posta Adresi',
        phone_number: "Telefon Numarası",
        corporate_phone_number: "Kurumsal Telefon Numarası",
        timezone: "Saat Dilimi",
        hour: "Saat",
        choose_brand: "Marka Seçiniz",
        choose_teacher: 'Eğitmen Seçiniz',
        choose_time: 'Saat Seçiniz',
        choose_gender: 'Cinsiyet Seçiniz',
        all_teacher_list: 'Tüm Eğitmen Listesi',
        has_free_lesson_list: 'Boş Saati Olan Eğitmenler',
        job: 'Meslek',
        choose_job: 'Meslek Seçiniz',
        birth_date: 'Doğum Tarihi',
        birth_place: 'Doğum Yeri',
        gender: 'Cinsiyet',
        date_placeholder: 'gg/aa/yyyy',
        profile_photo: 'Profil Resmi',
        team_leader: 'Takım Lideri',
        work_start_date: 'İşe Başlama Tarihi',
        end_date: 'İşten Ayrılma Tarihi',
        status: 'Durum',
        marital_status: 'Medeni Hali',
        image_url: 'Fotoğraf URL',
        university: 'Üniversite Adı',
        department: 'Bölüm',
        about: 'Hakkında',
        days: 'Günler',
        start_end_date: 'Başlangıç Bitiş Tarihi',
        start_end_date_hour: 'Başlangıç Bitiş Saati',
        start_date: 'Başlangıç Tarihi',
        application_name: 'Uygulama Adı',
        call_address: 'Arama Adresi',
        yes: 'Evet',
        no: 'Hayır',
        rate: 'Oran',
        moneybox_type: "Kumbara Tipi",
        description: 'Açıklama',
        active: 'Aktif',
        pasive: 'Pasif',
        removed: 'Kaldırıldı',
        register_type: 'Kayıt Türü',
        identity_number: 'TC Kimlik Numarası',
        is_abroad_taxpayer: 'Yurtdışı Mükellefi Mi?',
        company_name: 'Şirket Adı',
        tax_office: 'Vergi Dairesi',
        tax_office_upper: 'VERGİ DAİRESİ',
        tax_number: 'Vergi Numarası',
        tax_number_upper: 'VERGİ NUMARASI',
        company_address: 'Şirket Adresi',
        company: 'Şirket',
        bank: 'Banka',
        price: 'Tutar',
        kdv_rate: 'KDV Oranı',
        kdv_amount: 'KDV Tutarı',
        payment_type: 'Ödeme Türü',
        approve_code: 'Onay Kodu (Varsa)',
        title: 'Ünvan',
        invoice_type: 'Fatura Türü',
        company_title: 'Firma Ünvanı',
        invoice_date: 'Fatura Tarihi',
        payment_date: 'Ödeme Tarihi',
        dear: 'Sayın',
        city: 'Şehir',
        male: 'Erkek',
        female: 'Kadın',
        undefined: 'Belirtilmedi',
        validity_date: 'Geçerlilik Tarihi',
        choose_applications: "Uygulamalar",
        application_title: "Uygulama Bilgileri",
        application_image: "Uygulama Fotoğrafı",
        application_image_url: "Fotoğraf URL (varsa)",
        prompt: "Prompt",
        web_path: "Web Path",
        mobile_path: "Mobil Path",
        paid_member: "Ödeme Yapmış Kullanıcı",
        non_paid_member: "Ödeme Yapmamış Kullanıcı",
        demo_member: "Demo Kullanıcı",
        endpoint_title: "Endpoint Bilgileri",
        endpoint: "Endpoint (varsa)",
        endpoint_test: "Endpoint Test (varsa)",
        request_type: "İstek Tipi",
        request_body: "İstek Gövdesi",
        is_have_parameter: "Parametre Var mı?",
        parameters: "Parametreler",
        title_english: "Başlık (İngilizce)",
        title_translations: "Başlık Çevirileri",
        title_translation: "Başlık Çevirisi",
        general_informations: "Genel Bilgiler",
        language: "Dil",
        information: "Bilgi",
        use_case: "Kullanım Amaçları",
        demo_right_count: "Demo Ders Hakkı",
        choose_level: "Seviye Seçiniz",
    },
    count: '<span class="font-bold">#count#</span> adet kayıt bulundu.',
    detail_search_titles: {
        detail_search: 'Detaylı Arama',
        brands: 'Tüm Markalar',
        students: 'Tüm Öğrenciler',
        payments: 'Tüm Ödemeler',
        teachers: 'Tüm Eğitmenler',
        status: 'Tüm Durumlar',
        scores: 'Tüm Skorlar',
        forms: 'Tüm Formlar',
        resources: 'Tüm Kaynaklar',
        genders: 'Tüm Cinsiyetler',
        jobs: 'Tüm Meslekler',
        sales: 'Tüm Satış Temsilcileri',
        active_sales: 'Tüm Aktif Satış Temsilcileri',
        passive_sales: 'Tüm Pasif Satış Temsilcileri',
        sales_types: 'Tüm Satış Tipleri',
        all_productivity: 'Tüm Verimlilik Temsilcileri',
        employees: 'Tüm Kullanıcılar',
        categories: 'Tüm Kategoriler',
        log_types: 'Tüm Log Tipleri',
        teacher_message_types: 'Tüm Mesaj Tipleri',
        use_status: 'Tüm Kullanım Durumları',
        discount_ranges: 'Tüm İndirim Oranları',
        reminder_reasons: 'Tüm Hatırlatma Nedenleri',
        reminder_drop_pool: 'Tüm Havuza Bırakma Nedenleri',
        invoices: 'Tüm Faturalar',
        channels: 'Tüm Kanallar',
        member_types: 'Tüm Üye Tipleri',
        members: 'Tüm Üyeler',
        types: 'Tüm Bölümler',
        banks: 'Tüm Bankalar',
        payment_options: 'Tüm Taksit Seçenekleri',
        begin_end_hours: 'Tüm Başlangıç / Bitiş Saatleri',
        hours: "Tüm Saatler",
        packages: 'Tüm Paketler',
        selected_packages: 'Seçili Paketler',
        contact_permission: 'Tüm İletişim İzinleri',
        choose_all: 'Tümünü Seç',
        gift_exercise: 'Tüm Hediye Alıştırmalar',
        gift_packages: 'Tüm Hediye Paketler',
        cards: 'Tüm Kartlar',
        requests: 'Tüm Talepler',
        transfer_status: 'Tüm Transfer Durumları',
        minutes: 'Tüm Dakikalar',
        discounts: 'Tüm İndirimler',
        all_lesson_durations: "Tüm Ders Süreleri",
        all_product_day_counts: "Tüm Gün Sayıları",
        avaliable_discounts: 'Birlikte Kullanılabilecek İndirimler',
        add_teacher: 'Yedek Eğitmen / Sandy Filtresi Açık',
        remove_teacher: 'Yedek Eğitmen / Sandy Filtresi Kapalı',
        search_type: 'Bölüm Ara',
        search_time: 'Saat Ara',
        search_teacher: 'Eğitmen Ara',
        search_resource: 'Kaynak Ara',
        search_employee: 'Kullanıcı Ara',
        search_sales: 'Satış Temsilcisi Ara',
        search_productivity: 'Verimlilik Temsilcisi Ara',
        productivity: 'Verimlilik Temsilcisi',
        search_discount_rate: 'İndirim Oranı Ara',
        search_discount: 'İndirim Ara',
        search_task_status: 'Görev Durumu Ara',
        search_channel: 'Kanal Ara',
        search_member_type: 'Üye Tipi Ara',
        search_member: 'Üye Ara',
        search_sales_type: 'Satış Tipi Ara',
        search_banks: 'Banka Ara',
        search_payment_options: 'Taksit Seçeneği Ara',
        search_package: 'Paket Ara',
        search_contact: 'İletişim İzni Ara',
        search_gift_exercise: 'Hediye Alıştırma Ara',
        search_gift_package: 'Hediye Paket Ara',
        search_email_title: 'E-Posta Başlığı Ara',
        search_sms: 'SMS Başlığı Ara',
        selected_data_count: 'Seçilen Veri Sayısı',
        no_team_leader: 'Takım Lideri Yok',
        date_range: 'Tarih Aralığı Seçiniz',
        valid_date_range: 'Geçerlilik Tarihi Seçiniz',
        creation_date_range: 'Oluşturulma Tarihi Seçiniz',
        payment_date_range: 'Ödeme Tarih Aralığı Seçiniz',
        date_range_last_note: 'Son not tarih aralığı',
        date_range_reminder: 'Hatırlatma tarih aralığı',
        enter_your_phone_number: 'Telefon numaranızı giriniz.',
        lesson_count: "Tüm Kalan Ders Sayıları",
        lesson_duration: "Ders Süresi Seçiniz",
        product_day_count: "Gün Sayısı Seçiniz",
        not_answered: "Yanıtlanmadı",
        answered: "Yanıtlandı",
        first_choose_brand: 'Önce Marka Seçiniz!',
        first_choose_time_range: 'Önce Zaman Aralığı Seçiniz!',
        applications: 'Tüm Uygulamalar',
        choose_application: "Uygulama Seçiniz",
        search_application: "Uygulama Ara",
        choose_language: "Dil Seçiniz",
        choose_request_type: "İstek Tipi Seçiniz",
        choose_parameter_type: "Parametre Tipi Seçiniz",
        parameters: "Tüm Parametreler",
        search_parameters: "Parametre Ara",
        include_system: "Sistem Görevleri Dahil",
        out_system: "Sistem Görevleri Dahil Değil",
        include_selected_date_form: "Seçili Tarihte Havuza Eklenenleri Göster",
        extract_selected_date_form: "Seçili Tarihte Havuza Eklenenleri Gösterme",
        use_case: "Neden İngilizce öğrenmek istiyorsunuz?",
    },
    button: {
        save: 'Kaydet',
        save_upper: 'KAYDET',
        okey_upper: 'TAMAM',
        cancel: 'İptal',
        cancel_upper: 'İPTAL',
        confirm: 'Onayla',
        confirm_upper: 'ONAYLA',
        create: 'Oluştur',
        clear: 'Temizle',
        update: 'Güncelle',
        update_upper: 'GÜNCELLE',
        update_answer: 'Cevabı Güncelle',
        add: 'Ekle',
        add_upper: 'EKLE',
        delete: 'Sil',
        undo: 'Geri Al',
        send: 'Gönder',
        add_package: 'Paketi Ekle',
        postpone_upper: 'ERTELE',
        list: 'Listele',
        save_and_complete: 'Kaydet ve Tamamla',
        save_and_complete_upper: 'KAYDET VE TAMAMLA',
        delete_term: 'Dönem Sil',
        no_cancel: 'Hayır, vazgeç',
        yes_save: 'Evet, kaydet',
        browse: "Göz at",
        choose: 'Seç',
        choose_upper: 'SEÇ',
        change: 'Değiştir',
        list_upper: 'LİSTELE',
        go_back: "Geri Dön",
        edit: 'Düzenle',
        continue: 'Devam Et',
        create_campaign: 'Kampanya Oluştur',
        pay: 'ÖDEME YAP',
        complete_payment: 'ÖDEMEYİ TAMAMLA',
        create_term: 'DÖNEMİ OLUŞTUR',
        complete_registration: 'KAYDI TAMAMLA',
        get_candidate: 'Aday Getir',
        detail: "Detay",
        send_from_whatsapp_button: "WhatsApp Mesajı Gönder",
        send_email_button: "E-Posta Gönder",
        send_sms: 'SMS Gönder',
        try_again: "Tekrar Dene",
        update_payment_date: "Ödeme Tarihi Düzenle"
    },
    search_bar: {
        search: 'Ara',
        clear: 'Temizle',
        refresh: 'Yenile',
        search_student: 'Öğrenci aramak için telefon, isim, öğrenci numarası veya email giriniz',
        search_employee: 'Kullanıcı aramak için telefon, isim veya email giriniz',
        search_candidate: 'Öğrenci adayı aramak için telefon, isim veya email giriniz',
        search_task: 'Görev aramak için öğrenci adı veya telefon numarası giriniz',
        search_remider: 'Öğrenci adayı aramak için isim giriniz',
        search_discount_code: 'Aramak için öğrenci/çalışan ismi veya indirim kodu giriniz',
        search_campaign: 'Kampanya aramak için kampanya kodu giriniz',
        search_payment: 'Ödeme aramak için öğrenci ismi giriniz',
        search_invoice: 'Fatura aramak için telefon, isim veya email giriniz',
        search_current_card: 'Cari kart aramak için cari kart adı giriniz',
        search_ticket: 'Talep aramak için öğrenci adı veya talep içeriği giriniz'
    },
    pagination: {
        previous: "Geri",
        next: "İleri"
    },
    table: {
        brand: "Marka",
        student_informations: "Öğrenci Bilgileri",
        candidate_informations: "Aday Bilgileri",
        application_informations: "Başvuru Bilgileri",
        operation_informations: "İşlem Bilgileri",
        score: "Skor",
        users: "Kullanıcılar",
        apply_date: "Başvuru Tarihi:",
        last_note_date: "Son Not Tarihi:",
        update_date: "Güncelleme Tarihi:",
        application_information: "Başvuru Bilgileri",
        education_informations: "Eğitim Bilgileri",
        profile_photo: 'Profil Resmi',
        profile: 'Profil',
        online: 'Online',
        attended_demo_lesson: 'Demo Dersine Katıldı',
        employee_informations: 'Kullanıcı Bilgileri',
        total_student_count: 'Toplam Öğrenci Sayısı',
        corparate_informations: 'Kurumsal Bilgiler',
        teacher_informations: 'Eğitmen Bilgileri',
        working_hours: 'Çalışma Saatleri',
        working_informations: 'Çalışma Bilgileri',
        date: 'Tarih',
        member_informations: 'Üye Bilgileri',
        term_product_description: 'Dönem/Ürün - Açıklama',
        device_name: 'Cihaz Adı',
        form_message: 'Form Mesajı',
        candidate_note: "Aday Notu",
        candidate_note_title: "Aday Notu Başlığı",
        student_note: "Öğrenci Notu",
        operation_person: 'Görevli',
        performing_person: 'İşlemi Yapan Kişi',
        operation_person_upper: 'GÖREVLİ',
        operation_person_information: 'Görevli Bilgileri',
        task_due_date: 'Görevin Son Tarihi',
        operation_date: 'İşlem Tarihi',
        task_message: 'Görev Mesajı',
        task_title: 'Görev Başlığı',
        task_information: 'Görev Bilgileri',
        created_date: 'Oluşturulma Tarihi',
        completed_date: 'Tamamlanma Tarihi',
        utm_details: 'UTM Detayları',
        address: 'Adres',
        contact_information: 'İletişim Bilgisi',
        contact_type: 'İletişim Tipi',
        is_first_address: 'Durum',
        ticket: 'Talep',
        consultant: 'Danışman Bilgileri',
        transfer_status: 'Transfer Durumu',
        employee_status: 'Kullanıcı Durumu',
        operation: 'İşlem',
        ticket_date: 'Talep Tarihi',
        answer_date: 'Yanıt Tarihi',
        teacher_name: 'Öğretmen Adı',
        hour: 'Saat',
        minute: 'Dakika',
        second: 'Saniye',
        discount_code_details: 'İndirim Kodu Detayları',
        discount_details: 'İndirim Detayları',
        use_status: 'Kullanım Durumu',
        campaign_code: 'Kampanya Kodu',
        campaign_details: 'Kampanya Detayları',
        payment_status: 'Ödeme Durumu',
        person_informations: 'Kişi Bilgileri',
        payment_informations: 'Ödeme Bilgileri',
        accounting_approve_information: 'Muhasebe Onay Bilgisi',
        total_price: 'Toplam Tutar',
        invoice_number: 'Fatura Numarası',
        current_card_information: 'Cari Kart Bilgileri',
        invoice_status: 'Fatura Durum',
        monthly_income: 'Aylık Tutar',
        monthly_total_cost_rate: 'Aylık Toplam Maliyet Oranı',
        yearly_total_cost_rate: 'Yıllık Toplam Maliyet Oranı',
        total_payment: 'Toplam Ödeme Tutarı',
        package_payment_informations: 'Paket ve Ödeme Bilgileri',
        current_informations: 'Cari Bilgiler',
        order_invoice_informations: 'Sipariş/Fatura Bilgileri',
        number: 'Numara',
        service_product_name: 'Hizmet/Ürün Adı',
        amount: 'Adet',
        unit_price: 'Birim Fiyat',
        total: 'Toplam',
        duration: 'Süre',
        use_case_title: "Kullanım Başlığı",
        title: "Başlık",
        filter_for_result: 'Kayıtları Görüntülemek İçin Filtreleme Yapınız',
        all_applied_discounts: "Uygulanan Toplam İndirimler",
        applied_discounts: "Uygulanan İndirimler",
        click_for_show_all_discounts: "Tüm indirimleri görmek için tıklayınız",
        discount_name: "İndirim İsmi",
        name: "İsim",
        status: "Durum",
        show_candidate_notes: "Aday Notlarını Görüntüle",
        current_score: "Güncel Skor:",
        score_before_pool: "Havuza Atılmadan Önceki Skor:",
        note_date: "Not Tarihi"
    },
    errors: {
        //genel hatalar
        title: "Hata",
        an_error_occurred: "İşlem başarısız oldu!",
        an_error_occured_refresh_page: "İşlem başarısız oldu! Lütfen sayfayı yenileyiniz.",
        not_authorized: "Yetkiniz yok",
        not_authorized_for_system: "Sisteme giriş için yetkiniz yok!",
        no_records_found: "Herhangi Bir Kayıt Bulunamadı",
        no_ticket_found: 'Cevaplanmamış Talep Bulunamadı',
        no_active_task_found: 'Aktif Görev Bulunamadı',
        no_reminder_found: 'Bugüne Ait Hatırlatma Bulunamadı',
        not_authorized_for_this_page: "Bu sayfaya giriş için yetkiniz yok",
        not_authorized_for_this_area: "Bu alanı görmek için yetkiniz yok",
        not_allowed: "İşleme Kapalı",
        it_is_not_specified: "Belirtilmedi",
        it_is_not_specified_firm: "Firma Adı Belirtilmedi",
        it_is_not_specified_upper: 'BELİRTİLMEDİ',
        no_name: 'İsimsiz',
        last_note_not_found: 'Son not bulunamadı',
        reminder_date_not_found: 'Ertelenen tarih bulunamadı',
        negative_value_error: 'Lütfen pozitif bir değer giriniz.',
        value_cannot_high_hundred: 'Lütfen 0 ile 100 arasında bir değer giriniz.',
        error404_title: "Sayfa Bulunamadı",
        error404_content: "Aradığınız sayfa bulunamadı. Menüleri kullanarak dilediğiniz sayfaya ulaşabilirsiniz.",
        add_invoice_row_error: 'Lütfen fatura satırı ekleyiniz.',
        discount_not_found: 'İndirim Bulunamadı!',
        campaign_not_found: 'Kampanya Bulunamadı!',
        phone_number_not_found: 'Kullanıcının telefon numarası bulunamadı!',
        lesson_detail_not_found: 'Ders detayı bulunamadı!',
        //.... alanı gereklidir hataları
        email_error: "E-posta Adresi alanı gereklidir!",
        code_error: "Doğrulama kodu alanı gereklidir!",
        password_error: "Şifre alanı gereklidir!",
        passwords_cannot_be_different: "Şifreler aynı olmalıdır!",
        //format uygun değil hataları
        email_or_password_wrong: "E-posta veya şifre hatalı!",
        email_format_error: "E-posta formatı uygun değil.",
        phone_number_format_error: "Telefon numarası formatı uygun değil.",
        identity_number_format_error: 'TC kimlik numarası formatı uygun değil.',
        //.... boş geçilemez hataları
        input_field_null_error: " alanı boş geçilemez.",
        company_null_error: "Şirket alanı boş geçilemez.",
        payment_type_null_error: "Ödeme türü alanı boş geçilemez.",
        source_type_null_error: "Ödeme nereden yapıldı alanı boş geçilemez.",
        bank_null_error: "Banka alanı boş geçilemez.",
        total_price_null_error: "Tutar alanı boş geçilemez.",
        contact_type_null_error: "İletişim tipi boş geçilemez.",
        full_name_null_error: "Ad soyad alanı boş geçilemez.",
        invoice_type_null_error: "Fatura türü alanı boş geçilemez.",
        name_null_error: 'Ad alanı boş geçilemez.',
        surname_null_error: 'Soyad alanı boş geçilemez.',
        phone_number_null_error: "Telefon numarası alanı boş geçilemez.",
        corporate_phone_null_error: 'Kurumsal telefon alanı boş geçilemez.',
        brand_null_error: "Marka alanı boş geçilemez.",
        email_null_error: 'E-mail alanı boş geçilemez.',
        corporate_mail_null_error: 'Kurumsal e-mail alanı boş geçilemez.',
        type_null_error: 'Departman alanı boş geçilemez.',
        team_leader_null_error: 'Takım lideri alanı boş geçilemez.',
        start_date_null_error: 'Başlangıç tarihi alanı boş geçilemez.',
        timezone_null_error: 'Durum alanı boş geçilemez.',
        employee_status_null_error: 'Saat dilimi alanı boş geçilemez.',
        teacher_id_null_error: 'Eğitmen Id alanı boş geçilemez',
        gender_null_error: 'Cinsiyet alanı boş geçilemez.',
        status_null_error: 'Durum bilgisi alanı boş geçilemez.',
        description_null_error: 'Açıklama alanı boş geçilemez.',
        rate_null_error: 'Oran alanı boş geçilemez.',
        reference_code_null_error: 'Referans kodu alanı boş geçilemez.',
        campaign_code_null_error: 'Kampanya kodu alanı boş geçilemez.',
        campaign_title_null_error: 'Kampanya başlığı alanı boş geçilemez.',
        campaign_type_null_error: 'Kampanya tipi alanı boş geçilemez.',
        start_end_date_null_error: 'Başlangıç ve bitiş tarihi alanı boş geçilemez.',
        limited_member_null_error: 'Sınırlı sayıda üye alanı boş geçilemez.',
        channel_null_error: 'Kanal alanı boş geçilemez.',
        member_type_null_error: 'Üye tipi alanı boş geçilemez.',
        member_null_error: 'Üye alanı boş geçilemez',
        sales_type_null_error: 'Satış tipi alanı boş geçilemez.',
        payment_options_null_error: 'Taksit seçenekleri alanı boş geçilemez.',
        address_null_error: 'Adres bilgisi giriniz.',
        company_name_null_error: 'Şirket Adı bilgisi giriniz.',
        company_address_null_error: 'Şirket Adresi bilgisi giriniz.',
        tax_office_null_error: 'Vergi Dairesi bilgisi giriniz.',
        tax_number_null_error: 'Vergi Numarası bilgisi giriniz.',
        gift_package_null_error: 'Hediye paketler alanı boş geçilemez.',
        gift_package_discount_null_error: 'Hediye paket indirim oranı alanı boş geçilemez.',
        member_count_null_error: 'Üye miktarı alanı boş geçilemez.',
        order_number_null_error: 'Sipariş numarası alanı boş geçilemez.',
        select_product_null_error: 'Eğitim paketi alanı boş geçilemez.',
        select_product_time_null_error: 'Eğitim süresi alanı boş geçilemez.',
        weekly_lesson_count_null_error: 'Haftalık ders sayısı alanı boş geçilemez.',
        lesson_minute_null_error: 'Ders süresi alanı boş geçilemez.',
        payment_price_null_error: 'Ödeme tutarı alanı boş geçilemez',
        general_amount_null_error: 'Genel tutar alanı boş geçileme.',
        current_card_null_error: 'Cari kart alanı boş geçilemez.',
        invoice_description_null_error: 'Fatura açıklaması alanı boş geçilemez.',
        minute_null_error: 'Dakika alanı boş geçilemez.',
        general_null_error: 'Bu alan boş geçilemez',
        language_null_error: 'Dil alanı boş geçilemez',
        translation_title_null_error: 'Başlık çevirisi alanı boş geçilemez',
        information_null_error: 'Bilgi alanı boş geçilemez',
        title_eng_null_error: "Başlık (İngilizce) alanı boş geçilemez",
        prompt_null_error: "Prompt alanı boş geçilemez",
        request_type_null_error: "İstek tipi alanı boş geçilemez",
        parameters_null_error: "Parametreler alanı boş geçilemez",
        endpoint_null_error: "Endpoint alanı boş geçilemez",
        endpoint_test_null_error: "Endpoint test alanı boş geçilemez",
        application_null_error: "Uygulama alanı boş geçilemez",
        web_path_null_error: "Web path alanı boş geçilemez",
        mobile_path_null_error: "Mobil path alanı boş geçilemez",
        applications_null_error: "En az bir adet uygulama eklemeniz gerekmektedir!",
        reference_source_null_error: "Referans kaynağı alanı boş geçilemez",
        //... küçük olamaz hataları
        tax_rate_length_error: 'Kdv oranını doğru aralıklarda giriniz.',
        max_price_length_error: 'Girdiğiniz tutar maksimum tutardan büyük olamaz',
        name_min_length_error: 'Adınız minimum 2 karakter olmalıdır.',
        surname_min_length_error: 'Soyadınız minimum 2 karakter olmalıdır.',
        zero_rate_error: "Oran değeri 0 olduğu için bu işlemi gerçekleştiremezsiniz.",
        rate_from_zero_to_hundred_error: "Oran değeri 0-100 aralığından farklı olamaz.",
        rate_greater_than_total_rate_error: "Oran değeri toplam indirimden büyük olamaz.",
        discountprice_greater_than_generalamount_error: "İndirimli tutar genel tutardan büyük olamaz.",
        time_picker_reminder_error: "Seçilen erteleme zamanı, şu anki zamandan büyük olmalıdır.",
        //... çoklu seçim hataları
        same_campaign_type_error: 'Aynı kampanya/indirim tipinden yalnızca 1 tane seçebilirsiniz.',
        discount_reseted: 'Yeni kampanya seçildiği için indirimler sıfırlandı. Seçmiş olduğunuz kampanya uygulanmış durumdadır!',
        total_discount_reseted: 'Uygulanan tüm kampanya ve indirimler sıfırlandı!',
        // Servisten handle edilen hatalar
        extend_package_invalid_time_error: 'Seçmiş olduğunuz dönem başlangıç tarihi uzatmak istediğiniz dönemin bitiş tarihinden önce olamaz!',
        esd_time_frame_error: 'Ders saatine 2 saatten daha az süre kaldığı için işlem yapılamaz!',
        esd_two_hour_error: "Bugün için aktif bir ders bulunmaktadır. Saat değişikliğini ders saatinizden 2 saat geçtikten sonra yapabilirsiniz.",
        active_discount_exist: "Aktif bir indirim zaten mevcut!"
    },
    loading: "Yükleniyor",
    processing_upper: "İŞLEM GERÇEKLEŞTİRİLİYOR",
    sidebar: {
        home_page: "Ana Sayfa",
        academy: {
            title: "Akademi"
        },
        profile: {
            my_profile: "Profilim",
            logout: "Çıkış Yap"
        },
        member: {
            title: "Üye İşlemleri",
            students: "Öğrenciler",
            candidates: "Öğrenci Adayları",
            candidate_pool: "Aday Havuzu",
            new_candidates: "Yeni Gelen Adaylar",
            add_candidate_to_pool: "Havuza Aday Ekle"
        },
        accounting: {
            title: "Muhasebe İşlemleri",
            current_card: "Cari Kartlar",
            invoices: "Faturalar",
            payments: "Ödemeler",
            campaigns: "Kampanyalar",
            add_campaign: "Kampanya Ekle",
            campaign_detail: "Kampanya Detayları",
            discount_codes: "İndirim Kodları",
        },
        teacher: {
            title: "Eğitmen İşlemleri",
            teacher_program: "Eğitmen Programı",
            all_teacher_program: "Tüm Eğitmen Programları"
        },
        employee: {
            title: "Kullanıcı İşlemleri",
            list: "Kullanıcılar",
            add_employee: "Kullanıcı Ekle"
        },
        report: {
            title: "Rapor İşlemleri",
            candidate_pool: "Aday Havuzu Raporları",
            salesman: "Satış Temsilcisi Raporları",
            daily: "Günlük Raporlar",
            nps: "NPS Raporları",
            mentor_nps: "Mentor NPS Rapor",
            target_follow_analysis: "Hedef Takip Analizi",
            lesson_attend: "Ders Katılım Raporları",
            call_report: "Danışman Arama Raporları",
            reference_dashboard: "Referans Dashboard",
            inactive_reports: "Inaktif Raporlar",
            market_reports: "Market Raporları (Aktif Öğrenci)",
            new_registration: "Yeni Kayıtlar",
            new_registration_corporate: "Kurumsal Takımına Ait Yeni Kayıtlar",
            new_registration_operation: "Verimlilik Takımına Ait Yeni Kayıtlar",
            new_registration_operation_corporate: "Kurumsal Verimlilik Takımına Ait Yeni Kayıtlar",
            dropped_pool: "Havuza Bırakılan Kayıtlar",
            demo_dashboard: "Demo Dashboard",
            panel_events: "ClickIVO Prime Yönetim Paneli Eventleri",
            member_name: "Öğrenci Adı"
        },
        product_report: {
            title: "Ürün Rapor İşlemleri",
            product: "Rehber Erişim İzni Raporları",
            family_package_dashboard: "Aile Paketi Dashboard",
            app_comment_dashboard: "App Yorum Dashboard"
        },
        use_case: {
            title: "AI Kullanım Alanı İşlemleri",
            list: "AI Kullanım Alanları",
            create: "AI Kullanım Alanı Oluştur"
        },
        history: {
            title: "AI Kullanım Geçmişi İşlemleri",
            list: "AI Kullanım Geçmişi"
        }
    },
    academy: {
        general_operations: "Genel İşlemler",
        productivity_operations: "Verimlilik İşlemleri",
        sales_operations: "Satış İşlemleri",
        panel_usage: "Panel Kullanımı",
        student_screen_usage: "Öğrenci Ekranı Kullanımı",
        document: "Dokümanlar",
        sales: "Satış Sayfası Kullanımı",
        sales_and_productivity: "Satış & Verimlilik Eğitimleri"
    },
    page_title: {
        edit_employee: "Kullanıcı Güncelle"
    },
    navbar: {
        search: "Ara ...",
        tasks: {
            title: "Görevler",
            list_all: "Tüm Görevleri Gör"
        },
        tickets: {
            title: "Talepler",
            list_all: "Tüm Talepleri Gör"
        },
        reminders: {
            title: "Hatırlatmalar",
            list_all: "Tüm Hatırlatmaları Gör",
            reminder_popup: {
                title: "HATIRLATMA",
                minute: "#minute# Dakika",
                hour: "#hour# Saat",
                description: "Erteleme yapmak istediğiniz süreyi yukarıdan seçebilirsiniz."
            }
        }
    },
    auth: {
        login: {
            title: "Giriş",
            your_email: "E-posta Adresi",
            your_password: "Şifre",
            forgot_password: "Şifremi Unuttum",
            login_button: "Giriş Yap",
        },
        forgot_password: {
            title: "Şifremi Unuttum",
            your_email: "Kurumsal E-Posta Adresiniz",
            button: "Doğrulama Kodu Gönder",
            login: "Giriş Yap",
        },
        verify_code: {
            title: "Şifremi Unuttum",
            your_code: "Doğrulama Kodu",
            button: "Kontrol Et",
            login: "Giriş Yap",
        },
        update_password: {
            title: "Şifremi Güncelle",
            your_password: "Yeni Şifreniz",
            your_password2: "Yeni Şifreniz (Tekrar)",
            button: "Şifremi Güncelle",
            login: "Giriş Yap",
            success: {
                title: "İşlem Başarılı",
                message: "Şifreniz başarıyla değiştirilmiştir.",
            }
        },
    },
    dashboard: {
        welcome: "Hoşgeldin" 
    },
    member: {
        candidate: {
            get_candidate_success: 'Aday başarıyla getirildi!',
            search_drop_pool_reason: 'Havuza Bırakma Nedeni Ara',
            search_reminder_reason: 'Hatırlatma Nedeni Ara',
            member_has_already_active_sales: "Kişinin aktif satış temsilcisi bulunmaktadır!",
            limit_error: "Üzerinizdeki aday limitiniz dolduğu için daha fazla aday getir işlemi yapamazsınız!",
            undistributed_error: "Dağıtım durumu dağıtıma kapalı olduğundan aday getirilmedi!",
            brand_id_error: "Marka bulunamadı!",
            best_possible_member_error: "Havuzda hiç uygun aday bulunamadı!",
            member_form_error: "Form bulunamadı!"
        },
        add_candidate: {
            sure: 'Emin misiniz?',
            confirm_modal_text: 'Bu adayı havuza eklemek istiyor musununuz?',
            confirm_model_text_corporate: 'Bu adayı kurumsal olarak işaretleyip öğrenci adaylarına eklemek istiyor musunuz?',
            already_in_pool_modal_text: 'Aday zaten "aday havuzunda" ekli. Adayın skoru ile ilgili güncelleme yapılmıştır. #noteFieldStart#Adayın #infoText# ile ilgili güncelleme yapılmıştır.#noteFieldEnd#',
            already_registered_pool_modal_text: 'Aday "Adaylar/Öğrenciler" arasında olduğu için havuza eklenememiştir. #noteFieldStart#Adayın #infoText# ile ilgili güncelleme yapılmıştır.#noteFieldEnd# Profiline gitmek için "Tamam" butonuna tıklayınız.',
            note_updated: "notu",
            level_updated: "seviyesi",
            use_case_updated: "kullanım amaçları",
            and: "ve",
            click_okey_for_profile_root: "Profiline gitmek için \"Tamam\" butonuna tıklayınız.",
            check_corporate: "Kurumsal Olarak İşaretle",
            only_avaliable_for_ko: "Bu seçenek sadece Konuşarak Öğren markası için kullanılabilir!",
            success: {
                title: "İşlem Başarılı",
                message: "Aday başarıyla oluşturuldu!",
                message_corporate: "Kurumsal aday başarıyla oluşturuldu!"
            },
            levelTitle: "İngilizce Seviyenizi Seçin",
        },
        member_detail: {
            header: {
                member_profile: 'Üye Profili',
                sales_operations: 'Satış İşlemleri',
                candidate_operations: 'Aday İşlemleri',
                student_operations: 'Öğrenci İşlemleri',
                applications: 'Başvurular',
                call_records: 'Arama Kayıtları',
                reference: 'Referans',
                inform_operations: 'Bilgilendirme İşlemleri',
                tasks: 'Görevler',
                operations: 'İşlemler',
                accounting: 'Muhasebe',
                education_packages: 'Eğitim Paketleri',
                other: 'Diğer',
                content: {
                    demo_registration: 'Demo Kayıt',
                    first_registration: 'İlk Kayıt',
                    second_registration: 'İkinci Kayıt',
                    fourty_eight_hours_discount: 'Saatlik İndirim',
                    again: 'Yeniden Dene',
                    create_reference: 'Referans Oluştur',
                    moneybox_operations: 'Kumbara İşlemleri',
                    referenced_members: 'Referans Verdiği Üyeler',
                    add_referenced_member: 'Referans Veren Üye Ekle',
                    set_reminder: 'Hatırlatma Kur',
                    drop_pool: 'Havuza Bırak',
                    send_offer: 'Teklif Gönder',
                    send_email: 'E-Posta Gönder',
                    send_sms: 'SMS Gönder',
                    active_tasks: 'Mevcut Görevler',
                    completed_tasks: 'Geçmiş Görevler',
                    payments: 'Ödemeler',
                    current_card: 'Cari Kart',
                    invoices: 'Faturalar',
                    logs: 'Loglar',
                    notes: 'Notlar',
                    candidate_notes: 'Aday Notları',
                    student_notes: 'Öğrenci Notları',
                    task_notes: 'Görev Notları',
                    old_system_notes: 'Eski Sistem Notları',
                    users: 'Kullanıcılar',
                    tickets: 'Talepler',
                    call_types: 'Arama Tipleri',
                    contact_informations: 'İletişim Bilgileri',
                    add_student_request: 'Öğrenci Talepi Ekle',
                    add_student_transfer_status: 'Transfer Durumu Ekle',
                    move_brand: 'Marka Taşıma'
                }
            },
            profile: {
                profile_success_message: 'Üye güncelleme işlemi başarıyla tamamlandı.',
                profile_error_message: 'Üye güncelleme işlemi sırasında bir hata oluştu.',
                profile_note_error_message: 'Üyeye ait not bilgisi güncelleme işlemi sırasında bir hata oluştu.',
                profile_demo_right_error_message: 'Üyeye ait demo ders hakkı güncelleme işlemi sırasında bir hata oluştu.',
                search_job: 'Meslek Ara',
                copied: 'Kopyalandı',
                member_status: 'Öğrenci Durumu',
                active_student: 'Aktif Öğrenci',
                passive_student: 'Pasif Öğrenci',
                candidate_student: 'Öğrenci Adayı',
                corporate: 'Kurumsal',
                call_question: "#memberName# adlı öğrenciyi aramak istediğinize emin misiniz?",
                click2call_success: "Arama işlemi gerçekleşiyor. #callProvider# sayfanızı kontrol ediniz.",
                click2call_fail: "Arama işlemi başarısız oldu. Lütfen daha sonra tekrar deneyiniz.",
                call: "Alotech ile Arama Yap",
                call_3cx: "Esneks/3CX ile Arama Yap",
                whatsapp: "Whatsapp'tan Yaz",
                click_for_see_all_reasons: "Tüm Kullanım Amaçlarını Görmek İçin Tıklayınız",
                all_reason_to_learn_english: "Tüm Kullanım Amaçları",
                ai_level_collection: "AI ile Belirlenen Seviye",
                self_level_collection: "Kendi Seçtiği Seviye",
                weekly_streak: "Haftalık Seri",
                weekly_streak_league: "Haftalık Seri Ligi ve Sıralaması",
                profile_status: "Profil Görünürlüğü",
                public: "Profili Açık",
                private: "Profili Kilitli",
                nps_scores: "NPS Puanları",
                nps_type: "NPS Türü",
                nps_comment: "NPS Yorumu",
                nps_rate: "NPS Puanı",
                click_for_see_all_nps_scores: "Tüm NPS Puanlarını Görmek İçin Tıklayınız",
                contact: {
                    title: "Takip Ettiği veya Rehberde Olan Kişiler",
                    click_for_see_all_contact_members: "Tüm Takip Ettiği veya Rehberde Olan Kişileri Görmek İçin Tıklayınız",
                    following_members: "Takip Ettiği Kişiler",
                    contact_members_and_public: "Rehberinde Bulunan ve Profili Açık Kişiler",
                    referrer_members: "Öğrenciyi Referans Veren Kişiler",
                    added_contact_members: "Rehberinde Olduğu Kullanıcılar",
                    following: "Takip Edilen",
                    contact_and_public: "Öğrencinin Rehberinde ve Profili Açık",
                    exist_member_by_public_other_contacts: "Öğrenciyi Rehberine Ekleyen Kişi (Rehber İzni Var)",
                    exist_member_by_private_other_contacts: "Öğrenciyi Rehberine Ekleyen Kişi (Rehber İzni Yok)",
                    current_referer_member: "En Son Referans Veren Kişi",
                    old_referer_member: "Eski Referans Veren Kişi"
                },
                sales_analysis: {
                    title: "AI Satış Sistemi",
                    sales_analysis_title: "Demo Satış Sistemi Yorumu",
                    level_test_title: "Seviye Testi Satış Sistemi Yorumu",
                    interview_pro_title: "Mülakat Pro Satış Sistemi Yorumu",
                    sales_analysis_not_found: "Demo Satış Sistemi Yorumu Bulunamadı",
                    level_test_not_found: "Seviye Testi Satış Sistemi Yorumu Bulunamadı",
                    interview_pro_not_found: "Mülakat Pro Satış Sistemi Yorumu Bulunamadı",
                    show_detail: "Detayları Gör"
                },
                member_campaign: {
                    active_campaign: "Aktif İndirim",
                    hourly_discount: "Saatlik %#discountRate# İndirim",
                    advert: "%#discountRate# Reklam İndirimi",
                    other_campaign: "%#discountRate# Diğer Market İndirimi",
                    last_date: "Son Kullanma Tarihi"
                },
                family_package: {
                    title: "Aile Paketi",
                    family_members: "Aile Üyeleri",
                    family_activities: "Aile Aktiviteleri",
                    click_for_see_all_family_packages: "Aile Üyelerini Görmek İçin Tıklayınız",
                    not_has_family: "Aktif bir aile bulunamadı",
                    family_role: "Aile Rolü",
                    family_member: "Üye",
                    family_manager: "Yönetici",
                    invite_status_accepted: "Aile üyeliği daveti kabul edildi.",
                    invite_status_rejected: "Aile üyeliği daveti reddedildi.",
                    invite_status_removed: "Aileden çıkarıldı."
                }
            },
            create_reference: {
                reference_code: 'Referans Kodu',
                referring_member: 'Referans Veren Üye',
                total_discount_right: 'Toplam İndirim Hakkı',
                success_message: 'Referans oluşturma işlemi başarıyla tamamlandı!',
                click_for_detail: 'Oluşturulan referans profiline gitmek için tıklayınız',
                click_for_detail_candidate_pool: 'Referans oluşturuldu. Aday havuzu  sayfasına gitmek için tıklayınız.',
                existing_reference_error_message: 'Varolan öğrenci referans olarak eklenemez!',
                referrer_must_be_different_person_error_message: 'Referans veren farklı kişi olmalıdır!',
                search_text: "Aramak için isim, telefon veya email giriniz",
                select_reference_success_message: "Referans oluşturmak için öğrenci seçildi!",
                select_reference_source: "Referans Kaynağı Seçiniz",
                select_reference_source_title: "Referans Kaynağı"
            },
            moneybox: {
                title: "Kumbara İşlemi Ekle/Kullan",
                inform_student: 'İşlem sonunda öğrenciyi bilgilendir.',
                use_moneybox_discount: 'Kumbara İndirimi Ekle',
                add_moneybox_discount: 'Kumbara İndirimi Kullan',
                choose_discount_ratio: 'İndirim oranını seçin',
                success_message: 'Kumbara ekleme/kullanma işlemi başarıyla tamamlandı!',
                choose_discount_rate: 'İndirim Oranı Seçiniz',
                earned_title: "(+) Kazanılan",
                used_title: "(-) Kullanılan",
                self: "Kendi",
                reference: "Referans",
                family: "Aile"
            },
            add_refferrer: {
                title: 'REFERANS VEREN ÜYE EKLE',
                label: 'Referans veren üyenin referans kodunu bu alana ekleyiniz.',
                success_message: 'Referans veren üye başarıyla eklendi.',
                referrer_not_found_message: 'Referans veren üye bulunamadı!',
                reference_different_person_message: 'Referans veren farklı kişi olmalıdır!'
            },
            referenced: {
                title: 'Referans Verdiği Üyeler',
                no_referenced_message: 'Bu üye henüz referans vermemiş.',
                referenced_status_active: 'Henüz kayıt olmadı.',
                referenced_status_passive: 'Başkası tarafından referans verilmiş.',
                referenced_status_paid: 'Kayıt olmuş.',
            },
            operations: {
                no_log_data: 'Log verisi bulunamadı.',
                no_form_data: 'Form verisi bulunamadı.',
                no_task_data: 'Görev verisi bulunamadı.',
                source_type: {
                    web_application: 'Web',
                    mobile_application: "Mobil Uygulama",
                    landing_page: "Tanıtım Sayfası",
                    panel: "Panel"
                }
            },
            inform_operations: {
                //Sms
                sms_title: 'Sms Başlığı',
                sms_titles: 'SMS Başlığı Seçiniz',
                parameter: 'Parametreler',
                content: 'içerik',
                sms_content: 'SMS İçeriği',
                sms_success_message: 'SMS başarıyla gönderildi!',
                send_sms_question: 'SMS göndermek istediğinize emin misiniz?',
                //Email
                email_title: 'E-Posta Başlığı',
                email_titles: 'E-Posta Başlığı Seçiniz',
                email_success_message: 'E-Posta başarıyla gönderildi!',
                send_email_question: 'E-Posta göndermek istediğinize emin misiniz?',
                email_content: "E-Posta İçeriği",
                whatsapp_content: "WhatsApp Mesajı İçeriği",
                send_whatsapp_message: 'WhatsApp mesajı göndermek istediğinize emin misiniz?',
                member_email_not_found: 'Öğrencinin sisteme kayıtlı e-posta adresi bulunamadı! Üye Profili sayfasından e-posta adresi güncellemesi yapabilirsiniz.',
                //Offer
                sell_type: 'Satış Tipi',
                choose_sell_type: 'Satış Tipi Seçiniz',
                education_package: 'Eğitim Paketi',
                choose_education_package: 'Eğitim Paketi Seçiniz',
                search_education_package: 'Eğitim Paket Ara',
                weekly_lesson_count: 'Haftalık Ders Sayısı',
                choose_weekly_lesson_count: 'Haftalık Ders Sayısı Seçiniz',
                education_time: 'Eğitim Süresi',
                choose_education_time: 'Eğitim Süresi Seçiniz',
                lesson_time: 'Ders Süresi',
                choose_lesson_time: 'Ders Süresi Seçiniz',
                moneybox_discount: 'Kumbara İndirimi',
                choose_moneybox_discount: 'Kumbara İndirimi Seçiniz',
                reference_discount: 'Referans İndirimleri',
                choose_reference_discount: 'Referans İndirimi Seçiniz',
                other_discounts: 'Diğer İndirimler',
                choose_other_discount: 'İndirim Seçiniz',
                payment_price: 'Ödeme Tutarı',
                choose_payment_price: 'Ödeme Tutarı Seçiniz',
                discounts: 'İndirimler',
                additional_information_title: 'Ek Bilgilendirme',
                additional_information_one: 'Sistemimizde ders almaya başladığınızda sabit bir saatte kalma zorunluluğunuz yoktur. Sabah 06:00 ile gece 00:00 saatleri arasında uygun bulduğunuz zaman dilimlerinde hazırlamış olduğumuz öğrenci ekranlarından değişiklik sağlayabilirsiniz.',
                additional_information_two: ' Fiyatlara KDV dahildir.',
                additional_information_three: 'Kredi kartı üzerinden yapılan ödemelerde vade farkı olmaksızın taksit imkanı sunulmaktadır.',
                additional_information_four: 'Havale olarak yapılan ödemelerde taksit imkanı bulunmamaktadır.',
                additional_information_five: 'Banka havale bilgilerini gönder.',
                additional_information_six: 'Yapay zeka ile sınırsız ders imkanı sunulmaktadır.',
                other_email_placeholder: 'Diğer e-posta adresi',
                not_specified: 'Belirtilmedi',
                no_package_error: 'Eklenecek herhangi bir paket veya ek bilgilendirme bulunamadı!',
                choose_campaign: 'Pakete Özel Kampanya',
                choose_campaign_placeholder: 'Pakete özel kampanya seçiniz',
                other_email_information: 'Teklif göndermek için farklı bir e-posta adresi girebilirsiniz',
                discount_not_found: 'İndirim Bulunamadı!',
                moneybox_discount_not_found: 'Kumbara İndirimi Bulunamadı!',
                reference_discount_not_found: 'Referans İndirimi Bulunamadı!'
            },
            tasks: {
                task_date: 'Görev Tarihi',
                task_date_upper: 'GÖREV TARİHİ',
                postpone_task: 'Görev Erteleme',
                postpone_task_number: 'Görev Erteleme Sayısı',
                postpone_task_count_upper: 'GÖREV ERTELEME ADEDİ',
                start_task: 'Göreve Başla',
                task_note: 'Görev Notu',
                task_message: 'Görev Mesajı',
                postpone_task_upper: 'GÖREVİ ERTELE',
                close_task_upper: 'GÖREVİ KAPAT',
                notes_about_task: 'Göreve Ait Notlar',
                date_of_postponed_task: 'Görevin Ertelendiği Tarihler',
                postponed_date: 'Ertelenen Tarih',
                postpone_date: 'Ertelenecek Tarih',
                postpone_task_success: 'Görev başarıyla ertelendi.',
                postpone_task_error: 'Görev ertelenirken bir hata oluştu.',
                no_postponed_date: 'Henüz hiç ertelenen tarih bulunmuyor.',
                no_note_yet: 'Henüz hiç not girilmemiş.',
                postpone_task_question: 'Görevi #date# tarihine ertelemek istiyor musun?',
                close_reason: 'Kapatma Sebebi',
                close_question: 'Görevi kapatmak istiyor musun?',
                close_task_success: 'Görev başarıyla kapatıldı.',
                close_task_error: 'Görev kapatılırken bir hata oluştu.',
                total_task_number: 'Seçili Tarihe Ertelenen Görev Sayısı',
                not_found_completed_task: 'Tamamlanmış herhangi bir görev bulunamadı.',
                not_found_active_task: 'Aktif herhangi bir görev bulunmuyor.',
                not_found_task_note: 'Henüz not girilmemiş',
                not_found_task_postponed_date: 'Ertelenen tarih bulunmuyor.',
                choose_reason: 'Kapatma Nedeni Seçiniz',
                choose_reason_error: 'Lütfen bir görev kapatma nedeni seçiniz',
                task_notes_not_found: 'Görev notu girilmedi',
                ai_mentoring: 'AI Eğitmen Mentörlük ve Satış Sistemi Yorumu',
                ai_progress: 'AI Gelişim Araması Yorumu',
                ai_analysis: {
                    not_found_error: "Analiz bulunamadı.",
                    task_not_found: "Görev bulunamadı!",
                    task_type_does_not_support: "Görevin analiz edilmesi için görev tipi uygun değil!",
                    prompt_not_found: "Analiz için gerekli bilgiler bulunamadı!",
                    term_id_required: "Dönem id bulunamadı!",
                    any_caption_not_found: "Ses kaydı altyazısı bulunamadı!",
                    captions_not_merged: "Ses kaydı altyazıları birleştirilemedi!",
                    ai_analysis_gave_error: "AI ile analiz etme işlemi başarısız oldu!"
                },
                weekly_ai_analysis: {
                    not_found_error: "Görev analizi bulunamadı!",
                    ai_results_not_found: "Haftalık ders sonuç kontrolleri bulunamadı!",
                    ai_results_not_merged: "Haftalık ders sonuç kontrolleri doğru formatta birleştirilemedi!"
                }
            },
            call_records: {
                choose_for_analysis: "AI Analizi İçin Seç",
                caller_info: "Arama Bilgileri",
                call_date: "Arama Tarihi",
                call_status: "Arama Durumu",
                call_duration: "Arama Süresi",
                call_record: "Arama Kaydı",
                call_provider: "Hizmet Sağlayıcısı",
                call_record_not_found: "Arama Kaydı Bulunamadı",
                download_call_record: "Arama Kaydını İndir",
                read_transcript: "Transkripti İncele",
                transcript: "Transkript",
                selected_transcripts: "Seçili Transkriptler",
                transcript_not_found: "Transkript bulunamadı.",
                selected_records_count: "Seçili Kayıt Sayısı",
                prompt_placeholder: "Prompt girin (Transkriptler otomatik olarak prompt ile birlikte gönderilecektir)",
                analyze: "Analiz Et",
                analysis_result: "Analiz Sonuçları",
                answer: "CEVAPLANDI",
                no_answer: "CEVAPLANMADI",
                cancel: "REDDEDİLDİ",
                busy: "MEŞGUL",
                congestion: "BİLİNMEYEN NUMARA"
            },
            others: {
                //applications
                no_application_error: 'Başvuru verisi bulunamadı.',
                //employees
                sales: 'Satış',
                productivity: 'Verimlilik',
                referencer: 'Referansatör',
                corporate_sales: 'Kurumsal Satış',
                corporate_support: 'Kurumsal Destek',
                cannot_perform_this_assignment: 'Bu atama işlemi gerçekleştirilemez.',
                no_sales_man: 'Satış Danışmanı Yok',
                no_productivity: 'Verimlilik Danışmanı Yok',
                no_referencer: 'Referansatör Yok',
                no_corporate_sales: 'Kurumsal Satış Temsilcisi Yok',
                no_corporate_support: 'Kurumsal Destek Temsilcisi Yok',
                success_message: 'Kullanıcı başarıyla güncellendi.',
                distribute_error_message: "Kullanıcı dağıtıma kapalı olduğundan atama yapılamadı!",
                //call types
                application: 'Uygulama',
                choose_application: 'Uygulama Tipi Seçiniz',
                substructure: 'Altyapı',
                choose_substructure: 'Altyapı Seçiniz',
                device: 'Cihaz',
                device_permission: 'Cihaz İzinleri',
                permissions: 'Verilen İzinler',
                permission_type: 'İzin Türü',
                permission_status: 'İzin Durumu',
                lesson_record_permission: 'Ders Kaydı Onay İzni',
                show_permission: 'Verilen İzinleri Göster',
                app_version: 'Uygulama Versiyonu',
                device_name: 'Cihaz İsmi',
                choose_device: 'Cihaz Seçiniz',
                token_status: 'Token Durumu',
                token_active: 'Token Var',
                token_passive: 'Token Yok',
                join_lesson_token: 'Derse Katıl Tokeni Var',
                dont_want_call: 'Arama Almak İstemiyor',
                add_call_type: 'Arama Tipi Ekle',
                call_type_success: 'Arama tipi başarıyla eklendi.',
                choose_call_type: 'Arama Tipi Seçiniz',
                call_test: 'Arama Testi',
                run_call_test: 'Arama Testi Yap',
                active_success_message: 'Arama tipi başarıyla aktif hale getirildi!',
                passive_success_message: 'Arama tipi başarıyla pasif hale getirildi!',
                call_type_delete_question: 'Arama tipini silmek istiyor musunuz?',
                call_type_active_question: 'Arama tipini aktif duruma getirmek istiyor musunuz?',
                call_type_passive_question: 'Arama tipini pasif duruma getirmek istiyor musunuz?',
                call_type_delete_success_message: 'Arama tipi başarıyla silindi!',
                duplicate_error_message: 'Aynı cihaz için birden fazla arama tipi eklenemez!',
                //contact informations
                contact_permission: 'İletişim İzinleri',
                add_contact_information: 'İletişim Bilgisi Ekle',
                choose_contact_type: 'İletişim Tipi Seçiniz',
                set_first: 'Birincil Yap',
                set_enable: 'Aktif Yap',
                set_disable: 'Pasif Yap',
                set_able: 'Aktif Yap',
                phone: 'Telefon',
                whatsapp: 'WhatsApp',
                email: 'E-Mail',
                first: 'Birincil',
                other: 'Diğer',
                contact_information_success: 'İletişim bilgisi başarıyla eklendi!',
                contact_permission_success: 'İletişim izinleri başarıyla güncellendi!',
                contact_information_updated: 'İletişim bilgisi başarıyla güncellendi!',
                set_primary_updated: 'Birincil iletişim tipi bilgisi başarıyla güncellendi!',
                change_status_success: 'İletişim tipi durumu başarıyla güncellendi!',
                //add student request
                add_student_request: 'Öğrenci İsteği Ekle',
                add_request: 'Talep Ekle',
                choose_request: 'Talep Seçiniz',
                request_history: 'Daha önce eklenen öğrenci taleplerini aşağıda bulabilirsiniz.',
                no_request: 'Herhangi bir talep bulunamadı.',
                request_error: 'Lütfen bir talep seçiniz.',
                //add student transfer
                add_student_transfer: 'Transfer Durumu Ekle',
                choose_transfer_status: 'Transfer Durumu Seçiniz',
                transfer_history: 'Daha önce eklenen öğrenci transfer durumlarını aşağıda bulabilirsiniz.',
                no_transfer: 'Herhangi bir transfer durumu bulunamadı.',
                //Move Brand
                move_brand_success_message: "Marka taşıma işlemi başarıyla tamamlandı!",
                existing_member_error_message: "#brand# markasında #memberName# adlı öğrencinin telefon numarası ile bir kayıt bulunmaktadır!"
            },
            terms: {
                term: 'Dönem',
                extend: 'Uzatma',
                additional_service: 'Ek Hizmet',
                change_term: 'Paket Değişikliği',
                future_change_term: 'Gelecek Dönem Paket Değişikliği',
                go_second_registration: 'İkinci Kayıt Açınız',
                units: 'Üniteler',
                member_teacher_message: 'Eğitmene Mesaj Yaz',
                update_unit: 'Ünite Güncelle',
                lesson_archive: 'Ders Takvimi',
                lesson_add_delete: 'Ders Ekle/Sil',
                move_substitute_teacher: 'Yedek Eğitmene Taşı',
                delete_term: 'Dönem Sil',
                move_missed_call: 'Missed Call Taşıma',
                manuel_missed_call: 'Manuel Missed Call Alma',
                active_term: 'Aktif Dönem',
                deleted_term: 'Silinmiş Dönem',
                passive_term: 'Geçmiş Dönem',
                future_term: 'Gelecek Dönem',
                pending_term: 'Onay Bekleyen Dönem',
                term_id: 'Dönem Id',
                term_start_date: 'Dönem Başlangıç Tarihi',
                package_name: 'Paket İsmi',
                call_type: 'Arama Tipi',
                term_end_date: 'Dönem Bitiş Tarihi',
                student_name: 'Öğrenci Adı',
                total_lesson: 'Toplam Ders',
                teacher_time_change: 'Eğitmen/Saat Değişikliği',
                missed_call_count: 'Kaçırılan Ders Sayısı',
                postpone_lesson_right: 'Ders Erteleme Hakkı',
                attended_lesson_count: 'Katıldığı Ders Sayısı',
                remaining_lesson_count: 'Kalan Ders Sayısı',
                freeze_lesson_count: 'Ders Dondurma Hakkı',
                teacher_time_change_right: 'Eğitmen/Saat Değişikliği Hakkı',
                missed_call_right: 'Dersimi Kaçırdım Hakkı',
                term_status: 'Dönem Durumu',
                search_note_title: 'Not Başlığı Ara',
                level: 'Seviye',
                last_subject: 'İşlenen Son Konu',
                friday: 'Cuma Günü',
                previous_package_name: "Önceki Paket İsmi",
                previous_package_lesson_count: "Önceki Paketteki Ders Sayısı",
                previous_package_used_lesson_count: "Önceki Paketteki Katılınan Ders Sayısı",
                previous_package_remaining_lesson_count: "Önceki Paketteki Kalan Ders Sayısı",
                previous_package_missed_lesson_count: "Önceki Paketteki Kaçırılan Ders Sayısı",
                new_package_name: "Yeni Paket İsmi",
                new_package_lesson_count: "Yeni Paketteki Ders Sayısı",
                friday_error_messages: {
                    active_term_schedule_error: 'Cuma günleri için aktif dersiniz bulunmamaktadır!',
                    time_range_error: 'Bugüne aktif ders olmasından dolayı güncelleme aralığında değilsiniz!'
                },
                free_talk: "Free Talk",
                exercise: 'Alıştırmalar',
                lesson_history: 'Ders Geçmişi',
                frozen_lesson: 'Dondurulmuş Ders',
                delete_term_message: 'Bu işlem geri alınamayan bir işlemdir. Dönemi silmek istediğinize emin misiniz?',
                delete_term_success_message: 'Dönem başarıyla silindi!',
                move_substitute_teacher_message: 'Öğrenciyi yedek eğitmene taşımak istediğinize emin misiniz?',
                move_substitute_teacher_success_message: 'Öğrenci yedek eğitmene başarıyla taşındı!',
                click_for_show_call_type: 'Arama Tiplerini Görmek İçin Tıklayınız',
                call_type_not_added: 'Arama Tipi Eklenmedi',
                on_teacher_list: "Eğitmen Listesinde Var",
                no_teacher_list: "Eğitmen Listesinde Yok",
                no_lesson_today: "Bugün Dersi Yok",
                term_start_date_error_messages: {
                    begin_date_error: "Seçilen başlangıç tarihi bugünün tarihinden küçük olamaz!",
                    term_date_equal_error: "Dönem başlangıç tarihi ile seçilen tarih zaten aynı!",
                    all_lessons_active_error: "Başlangıç tarihi güncelleme için tüm dersler aktif olmalıdır!",
                    selected_time_expired_error: "Seçili tarihin ders saati yaklaştığından/geçtiğinden işlem yapılamaz!",
                    two_hours_time_error: "Günün ders saatine 2 saatten az zaman kaldı!"
                },
                automatic_demo: "Otomatik Demo",
                modal: {
                    call_type_title: 'Arama Tipi Seç',
                    call_type_button: 'Arama Tipi Olarak İşaretle',
                    select_teacher: 'Eğitmen Seçiniz',
                    select_start_end_hour: 'Başlangıç ve Bitiş Saati Seçiniz',
                    teacher_time_change_message: 'Lütfen eğitmen ve/veya saat aralığı seçerek arama işleminizi gerçekleştiriniz',
                    teacher_time_change_question: 'Ders bilgilerini  #teacher# / #time# olarak değiştirmek istediğinize emin misiniz?',
                    teacher_time_change_success: 'Eğitmen/Saat değişikliği başarıyla tamamlandı!',
                    current_lesson_information: 'Mevcut Ders Bilgileri',
                    term_begin_date_success: 'Dönem başlangıç tarihi başarıyla güncellendi!',
                    student_name_success: 'Öğrenci ismi başarıyla güncellendi!',
                    postpone_right_success: 'Ders erteleme hakkı başarıyla güncellendi!',
                    teacher_time_change_right_success: 'Eğitmen/Saat değişikliği hakkı başarıyla güncellendi!',
                    missed_call_right_success: 'Dersimi kaçırdım hakkı başarıyla güncellendi!',
                    call_type_success: 'Arama tipi başarıyla güncellendi!',
                    change_call_type_status_question: 'Arama tipi durumunu değiştirmek istiyor musunuz?',
                    search_additional_package: 'Ek Hizmet Paketi Ara',
                    teacher_not_found: 'Uygun eğitmen/saat bulunamadı. Farklı saat aralığı ve eğitmen ile yeniden deneyiniz.',
                    teacher_sandy_text: "Aradığınız saat diliminde uygun eğitmen bulamadıysanız Teacher Sandy'e öğrenciyi atabilirsiniz",
                    choose_start_end_hour: 'Başlangıç/Bitiş Saati Seçiniz!',
                    no_right_title: 'Eğitmen/Saat Değişikliği işlemini gerçekleştiremezsiniz.',
                    check_title: 'Neleri kontrol etmeliyim?',
                    not_avaliable_time_range: 'Ders saati değişikliği için uygun saat aralığında olmayabilirsiniz.',
                    not_right_for_change: 'Öğrencimizin ders saati değişikliği hakkı bulunmuyor olabilir.',
                    lesson_freeze: 'Öğrencimiz ders dondurmuş olabilir.',
                    is_friday_update_question: "Cuma günkü derslerinizi '#fridaylesson#' olarak güncellemek istediğinize emin misiniz?",
                    teacher_sandy_status_open: 'Öğrenci Teacher Sandy\'de Sabit',
                    teacher_sandy_status_close: 'Öğrenci Teacher Sandy\'de Sabit Değil',
                    teacher_sandy_info: "Bu seçenek öğrencinin kendi saatinin aynısı bulana kadar öğrenciyi Teacher Sandy'de tutar."
                },
                operation_modal: {
                    lesson_count: 'Ders Sayısı',
                    note_header: 'Not Başlığı',
                    operation_reason: 'İşlem Nedeni',
                    lesson_operations: 'Tüm Ders İşlemleri',
                    note_headers: 'Tüm Not Başlıkları',
                    books: 'Tüm Kitaplar',
                    units: 'Tüm Üniteler',
                    search_book: 'Kitap Ara',
                    search_unit: 'Ünite Ara',
                    book: 'Kitap',
                    unit: 'Ünite',
                    today: 'Bugün',
                    delete_lesson_success: 'Ders silme işlemi başarıyla tamamlandı!',
                    add_lesson_success: 'Ders ekleme işlemi başarıyla tamamlandı!',
                    add_lesson_wait_success: 'Ders ekleme işleminiz başarıyla sıraya eklendi. Ortalama 20 dakika içinde ekleme işlemi gerçekleşecektir.',
                    information_message: 'Bu bölümden yapacağınız işlemler öğrencinin ders programının sonundan başlayarak işleme alınır.',
                    change_only_today: 'Sadece Bugün İçin Değiştir',
                    update_unit_question: 'Üniteyi güncellemek istediğinize emin misiniz?',
                    update_unit_success: 'Ünite başarıyla güncellendi!',
                    update_unit_error_message: 'Öğrenci ders saati yakın olduğundan işlem yapılamadı!',
                    no_lesson_book_error_message: "Ünite güncelleme yaptığınız dersin kitabı oluşturulmamış olabilir, kontrol ediniz!",
                    additional_service: 'Ek Hizmetler',
                    choose_category: 'Kategori Seçiniz',
                    choose_additional_service_package: 'Ek Hizmet Paketi Seçiniz',
                    change_package: 'Paket Değişikliği',
                    current_package: 'Güncel Paket',
                    package_price_difference: 'İndirimsiz Paket Fiyat Farkı',
                    minus_package_price_error: 'Toplam ödeme tutarı negatif bir değer olduğu için paket değişikliği yapılamıyor. Bu işlem yerine yeni bir paket satışı yapmalısınız!',
                    discount_price: 'İndirim Tutarı',
                    lesson_detail: 'Ders Detayları',
                    note_detail: 'Not Detayları',
                    lesson_result: 'Ders Sonucu',
                    voice_record: 'Ses Kaydı',
                    listen: 'Dinleme',
                    speaking: 'Konuşma',
                    word: 'Kelime',
                    grammer: 'Dil Bilgisi',
                    student_missed_lesson: 'Öğrenci derse katılmadı',
                    teacher_comment_about_lesson: 'Eğitmeninizin derse ait yorumları',
                    teacher_suggestion_about_lesson: 'Eğitmeninizin derse ait önerileri',
                    remark_date: 'Remark tarihi',
                    call_teacher: 'Arayan eğitmen',
                    response_error: 'Veriler yüklenirken bir hata oluştu. Lütfen sayfayı yenileyiniz.',
                    teacher_suggestions: 'Eğitmeninizin derse ait önerileri',
                    mispronunciation: 'Hatalı Telaffuz Edilenler / Hatalı Kurulan Cümleler',
                    correct_pronunciation: 'Doğru Telaffuz Edilenler / Doğru Kurulan Cümleler',
                    speaking_grammar_usage: 'Dilbilgisi Kullanımı',
                    speaking_vocabulary_usage: 'Kelime Kullanımı',
                    speaking_pronunciation: 'Telaffuz',
                    listening_comprehension: 'Dinlediğini Anlama',
                    reading_vocabulary_awareness: 'Okuma Kelime Farkındalığı',
                    reading_pronunciation: 'Okuma Telaffuz',
                    click_for_lesson_detail: "Not Detayı İçin Tıklayınız",
                    letter_note_informations: {
                        speaking_grammar: {
                            one: "The speaker has a very basic understanding of grammar and struggles to form coherent sentences. Errors in grammar usage are frequent and may interfere with understanding.\nMany errors in grammar usage, including frequent errors in subject-verb agreement, verb tense, and word order. The number of errors could be in the range of 10-20 per minute of speech.",
                            two_three: "The speaker has a limited but functional understanding of grammar and can communicate basic information with some errors. Grammar mistakes may still be frequent and noticeable.\nSome errors in grammar usage, including errors in basic structures such as singular and plural forms, articles, and simple verb tenses. The number of errors could be in the range of 5-10 per minute of speech.",
                            four_five: "The speaker has a good understanding of grammar and can communicate effectively, though with occasional errors. The speaker can use complex sentence structures and express ideas with relative ease.\nOccasional errors in grammar usage, including errors in complex structures such as subordinate clauses and verb tense consistency. The number of errors could be in the range of 2-5 per minute of speech.",
                            six_seven: "The speaker has a near-native command of grammar and can communicate complex ideas with little difficulty. Errors in grammar usage are rare and do not interfere with understanding.\nFew errors in grammar usage, with occasional mistakes in complex structures such as passive voice and reported speech. The number of errors could be in the range of 1-2 per minute of speech.",
                            eight_nine: "The speaker has a native-like mastery of grammar and can use language flexibly and effectively for a range of purposes. The speaker can understand and use complex structures, idioms, and other nuanced language features.\nRare errors in grammar usage, with occasional mistakes in subtle nuances such as idiomatic expressions and sentence-level discourse markers. The number of errors could be less than 1 per minute of speech.",
                            ten: "The speaker has a mastery of grammar that exceeds that of most native speakers. The speaker can use language creatively and with a high degree of precision, using a wide range of registers and styles as appropriate to the context.\nVirtually no errors in grammar usage, with occasional slips in pronunciation or vocabulary choice. The number of errors could be less than 1 per several minutes of speech."
                        },
                        speaking_vocabulary_usage: {
                            one: "The speaker has a very basic vocabulary and struggles to express ideas with limited words. They may rely on frequent repetition of the same words or phrases.\nFrequent errors in word choice, pronunciation, and usage. The speaker may rely on a small set of words and struggle to convey even basic ideas. The number of errors could be in the range of 10-20 per minute of speech.",
                            two_three: "The speaker has a functional vocabulary and can communicate basic information with some errors. However, their vocabulary may be limited to everyday words and phrases, and they may struggle with more complex or specialized terminology.\nSome errors in word choice, pronunciation, and usage. The speaker may struggle with more advanced vocabulary and rely on basic words and phrases. The number of errors could be in the range of 5-10 per minute of speech.",
                            four_five: "The speaker has a good vocabulary and can communicate effectively in most situations. They can use a range of vocabulary to express ideas, but may still struggle with more advanced terminology.\nOccasional errors in word choice, pronunciation, and usage. The speaker can use a range of vocabulary to express ideas, but may still struggle with more advanced or specialized terminology. The number of errors could be in the range of 2-5 per minute of speech.",
                            six_seven: "The speaker has a broad vocabulary and can use language flexibly and precisely to express complex ideas. They can use specialized terminology and idiomatic expressions appropriately in a range of contexts.\nFew errors in word choice, pronunciation, and usage. The speaker can use language flexibly and precisely to express complex ideas, and can understand and use specialized terminology appropriately. The number of errors could be in the range of 1-2 per minute of speech.",
                            eight_nine: "The speaker has an extensive and nuanced vocabulary, and can use language creatively to achieve a range of communicative goals. They can understand and use complex vocabulary in a variety of registers and styles.\nRare errors in word choice, pronunciation, and usage. The speaker has an extensive vocabulary and can use language creatively and effectively, even in specialized or formal contexts. The number of errors could be less than 1 per minute of speech.",
                            ten: "The speaker has a near-native command of vocabulary, using language with an exceptional range of precision and nuance. They can understand and use rare or archaic vocabulary, as well as complex idiomatic expressions, with ease.\nVirtually no errors in word choice, pronunciation, and usage. The speaker has an exceptional range of vocabulary and can use language with an exceptional degree of precision and nuance. The number of errors could be less than 1 per several minutes of speech."
                        },
                        speaking_pronunciation: {
                            one: "The speaker is difficult to understand, with poor articulation and significant errors in pronunciation, stress, and intonation. They may struggle to produce even basic sounds and may not be able to be understood by native speakers.\nThe speaker may make numerous mistakes in pronunciation, such as mispronouncing many sounds, omitting or adding sounds, and using incorrect stress and intonation patterns.",
                            two_three: "The speaker can produce some sounds and words correctly, but may still have difficulty with more complex sounds and pronunciations. Their intonation and stress may be off, making it difficult to understand them in certain contexts.\nThe speaker may still make some noticeable errors in pronunciation, such as mispronouncing some sounds or having trouble with more complex sounds, but can generally be understood by others.",
                            four_five: "The speaker can produce most sounds and words accurately and has a good grasp of basic intonation and stress patterns. They may still make some errors, particularly with less common sounds or words, but can generally be understood by native speakers.\nThe speaker may make occasional errors in pronunciation, such as mispronouncing some less common words or having a slight accent, but can generally communicate fluently and accurately.",
                            six_seven: "The speaker can produce nearly all sounds and words accurately and has a good command of intonation and stress patterns, making them sound more natural and fluent. They may still have some slight accent or occasional errors, but overall can be easily understood by native speakers.\nThe speaker may make few errors in pronunciation, and can generally produce all English sounds with accuracy and natural intonation and stress. They may still have a slight accent, but it does not interfere with their communication.",
                            eight_nine: "The speaker can produce all sounds and words accurately, with near-native pronunciation and intonation. They are able to make fine distinctions in sound and can adapt their pronunciation to different dialects and contexts. They may still have a slight accent, but are generally indistinguishable from native speakers.\nThe speaker may make very few errors in pronunciation, and can produce all English sounds with near-native accuracy and natural intonation and stress. They may still have a slight accent, but it is difficult to detect.",
                            ten: "The speaker has native-level pronunciation and intonation, with no discernible accent. They are able to produce subtle distinctions in sound and can adapt their pronunciation to any dialect or context. They may also be able to imitate different accents or dialects with ease.\nThe speaker is unlikely to make any noticeable errors in pronunciation, and can produce all English sounds with native-like accuracy and natural intonation and stress. They may be mistaken for a native speaker."
                        },
                        listening_comprehension: {
                            one: "The listener has difficulty understanding even basic words and phrases in the language. They may need to rely on visual cues or context to understand the meaning of spoken language.\nThe listener may make numerous mistakes, such as not being able to identify basic vocabulary or verb forms, and having difficulty understanding even simple sentences or phrases.",
                            two_three: "The listener can understand simple words and phrases, but may struggle with more complex sentences or idiomatic expressions. They may need to ask for repetition or clarification in order to understand the meaning of spoken language.\nThe listener may make some mistakes, particularly with more complex language or idiomatic expressions, but can generally understand the meaning of spoken language with repetition or clarification.",
                            four_five: "The listener can understand most spoken language in familiar contexts, but may still struggle with more complex or abstract language, particularly when it is spoken quickly or with an unfamiliar accent.\nThey may also miss some details or nuances in the language. The listener may make occasional mistakes, such as missing some details or nuances in the language, particularly when it is spoken quickly or with an unfamiliar accent.",
                            six_seven: "The listener can understand most spoken language, including more complex and abstract language, spoken at a normal pace and with a variety of accents. They can also understand implied meanings and cultural references.\nThe listener may make few mistakes and can understand most spoken language, including more complex and abstract language, spoken at a normal pace and with a variety of accents.",
                            eight_nine: "The listener can understand spoken language with a high degree of accuracy and fluency, even in complex or technical contexts. They can also understand nuances in tone, register, and intonation.\nThe listener may make very few mistakes, and can understand spoken language with a high degree of accuracy and fluency, even in complex or technical contexts.",
                            ten: "Exceptional proficiency: The listener can understand spoken language with complete accuracy and fluency, even in the most challenging contexts, such as fast-paced conversations between multiple speakers or with strong regional accents. They can also understand nuances in meaning and cultural references at a level that is equivalent to that of a native speaker.\nThe listener is unlikely to make any mistakes and can understand spoken language with complete accuracy and fluency, even in the most challenging contexts."
                        },
                        reading_vocabulary_awareness: {
                            one: "The reader has a very limited vocabulary and struggles to understand even basic words in the language. They may need to rely heavily on a dictionary or translation tool to read simple texts.\nThe reader may make numerous mistakes, such as not recognizing basic words or being unable to infer the meaning of unfamiliar words from context.",
                            two_three: "The reader has a basic vocabulary and can understand simple texts with familiar vocabulary, but may struggle with more complex or technical vocabulary.\nThe reader may make some mistakes, particularly with less familiar vocabulary, but can generally understand most of the words in simple texts.",
                            four_five: "The reader has a moderate vocabulary and can understand most texts with familiar vocabulary, but may struggle with more advanced or specialized vocabulary.\nThe reader may make occasional mistakes, such as misunderstanding the meaning of more complex or technical vocabulary, but can generally understand most of the words in moderately difficult texts.",
                            six_seven: "The reader has an extensive vocabulary and can understand most texts with a high degree of accuracy, including those with advanced or specialized vocabulary.\nThe reader may make few mistakes, and can generally understand most of the words in difficult or specialized texts, but may occasionally need to look up unfamiliar vocabulary.",
                            eight_nine: "The reader has an exceptional vocabulary and can understand texts with a high degree of precision, even in very specialized fields or contexts.\nThe reader may make very few mistakes, and can understand most texts with a high degree of accuracy, including those with advanced or technical vocabulary.",
                            ten: "The reader has a mastery of vocabulary in the language equivalent to that of a native speaker, and can understand and use highly nuanced and idiomatic language with ease. They may also be able to accurately and precisely infer meaning from context, without needing to rely on a dictionary or other tools.\nThe reader is unlikely to make any mistakes, and can understand and use highly nuanced and idiomatic language with ease. They may also be able to accurately and precisely infer meaning from context, without needing to look up unfamiliar vocabulary."
                        },
                        reading_pronunciation: {
                            one: "The reader reads slowly and may struggle with basic comprehension, needing to sound out words and reread frequently. Their reading may be halting and choppy, with little or no attention to expression.\nThe reader may make numerous mistakes, such as mispronouncing many words, misreading or skipping words, and struggling to understand basic vocabulary and sentence structure.",
                            two_three: "The reader reads with some fluency and is able to understand simple texts with basic vocabulary. Their reading may still be slow and require occasional assistance.\nThe reader may make some mistakes, such as mispronouncing some words, stumbling over more complex vocabulary, and needing some guidance to understand the text.",
                            four_five: "The reader reads with moderate fluency and is able to understand moderately complex texts with some unfamiliar vocabulary. They may still need to reread some sections for better comprehension.\nThe reader may make occasional mistakes, such as mispronouncing some specialized vocabulary, missing some nuances or idioms, and occasionally needing to reread sections for better comprehension.",
                            six_seven: "The reader reads with good fluency and is able to understand difficult texts with specialized vocabulary. They can read smoothly and efficiently with little difficulty.\nThe reader may make few mistakes, and can generally understand and pronounce most words with accuracy, but may occasionally struggle with highly specialized or technical vocabulary.",
                            eight_nine: "The reader reads with exceptional fluency and can easily understand even the most difficult texts with advanced vocabulary. They can read with excellent speed and accuracy and comprehend at a high level.\nThe reader may make very few mistakes, and can accurately and fluently read and understand texts with advanced or specialized vocabulary, without needing to look up unfamiliar words.",
                            ten: "The reader reads with near-native fluency and can read and comprehend texts as well as or better than native speakers. They can easily read a wide range of texts with advanced vocabulary and high complexity.\nThe reader is unlikely to make any mistakes, and can accurately and fluently read and understand highly nuanced and idiomatic language with ease. They may also be able to infer meaning from context with a high degree of accuracy."
                        }
                    },
                    foreign_call_logs: {
                        title: "Yurtdışı Arama Logları",
                        call: "Çağrı #index#",
                        table_rows: {
                            call_type: "Arama Tipi",
                            caller_teacher: "Arayan Eğitmen",
                            call_note: "Arama Notu",
                            call_date: "Arama Tarihi",
                            download_speed: "Yükleme Hızı: #value#",
                            upload_speed: "İndirme Hızı: #value#",
                            excellent: "Mükemmel",
                            good: "İyi",
                            poor: "Kötü",
                            call_ended: "Çağrı Sonlandırıldı",
                            missed_call: "Çağrı Cevaplanmadı"
                        },
                        call_types: {
                            started: "Arama Başladı",
                            receiving: "Bildirim İletildi",
                            ringing: "Çalıyor",
                            ongoing: "Devam ediyor",
                            finished: "Tamamlandı"
                        }
                    },
                    member_teacher_message: {
                        title_1: "Mesaj",
                        title_2: "Mesaj Tipi",
                        title_3: "Mesaj Zamanı",
                        title_4: "İşlemler",
                        message_type_1: "Öğrenci Mesajı",
                        message_type_2: "Danışman Mesajı",
                        message_type_3: "Sistem Mesajı",
                        active_success_message: 'Mesaj durumu başarıyla aktif hale getirildi!',
                        passive_success_message: 'Mesaj durumu başarıyla pasif hale getirildi!',
                        active_question: 'Mesaj durumunu aktif hale getirmek istiyor musunuz?',
                        passive_question: 'Mesaj durumunu pasif hale getirmek istiyor musunuz?',
                        success: "Ekleme işlemi başarıyla gerçekleştirildi."
                    }
                }
            },
            tickets: {
                title: 'Tickets',
                not_answered: 'Henüz Yanıtlanmadı.',
                no_ticket: 'Henüz talep gönderilmedi.'
            },
            candidate_operations: {
                note: 'Not',
                //drop pool
                choose_reason: 'Havuza Bırakma Nedeni Seçiniz',
                drop_pool_pool_error: 'Havuza bırakma nedeni seçilmelidir.',
                drop_pool_success: 'Adayı havuza bırakma işlemi başarıyla gerçekleşti!',
                drop_pool_error: 'Adayı havuza bırakma işlemi gerçekleştirilemedi!',
                drop_pool_question: 'Adayı havuza bırakma işlemini gerçekleştirmek üzeresiniz. Bu işlem geri alınamaz bir işlemdir.',
                search_drop_pool_reason: 'Havuza Bırakma Nedeni Ara',
                //set reminder
                reminder_reason: 'Hatırlatma Nedeni',
                choose_reminder_reason: 'Hatırlatma Nedeni Seçiniz',
                update_reminder: "Hatırlatma Güncelle",
                postpone_date: 'Erteleme Tarihi',
                postpone_time: 'Erteleme Zamanı',
                set_reminder_error: 'Hatırlatma nedeni seçilmelidir.',
                set_reminder_success: 'Hatırlatma kurma işlemi başarıyla gerçekleşti!',
                set_reminder_updated_success: "Mevcut hatırlatma başarıyla güncellendi!",
                set_reminder_question: 'Hatırlatma kurma işlemini gerçekleştirmek üzeresiniz.',
                duplicate_reminder_error: 'Öğrencinin mevcut bir hatırlatması var! Mevcut hatırlatmayı güncelleyebilirsiniz.',
                call_date_past_error: "Arama tarihi geçmiş zamana ayarlanamaz.",
                already_date_and_time_error: "Bu tarih ve saate kurulu bir hatırlatmanız var. Farklı bir zaman seçiniz.",
                member_form_not_found_error: "Bu üyeye ait form bulunamadı!",
                cannot_delete_past_reminder_error: "Geçmiş tarihli hatırlatmaları silemezsiniz!",
                past_date_reminders: "Güncellenmiş Hatırlatmalar",
                active_reminders: "Mevcut Hatırlatma",
                reminder_update_success_message: "Hatırlatma başarıyla güncellendi!",
                reminder_delete_confirm_message: "Hatırlatmayı silmek istediğinize emin misiniz?",
                reminder_delete_success_message: "Hatırlatma başarıyla silindi!",
                reminder_delete_error_message: "Tarihi geçmiş hatırlatma silinemez!",
                active_reminder: "Aktif",
                passive_reminder: "Süresi Geçmiş"
            },
            sales_operations: {
                //demo
                demo_title: "Paket Seçim İşlemleri / Demo Kayıt",
                weekly_lesson_time: 'Haftalık Ders Süresi',
                daily_lesson_time: 'Günlük Ders Süresi',
                package_lesson_count: 'Paket Ders Sayısı',
                choose_weekly_lesson_time: 'Haftalık Ders Süresi Seçiniz',
                choose_daily_lesson_time: 'Günlük Ders Süresi Seçiniz',
                demo_registration_wait_success_message: 'Demo kayıt başarıyla oluşturuldu. Ortalama 20 dakika içinde paketin dersleri güncellenecektir.',
                demo_registration_success_message: 'Demo kayıt başarıyla oluşturuldu.',
                //48 hours discount
                discount_type: 'İndirim Türü',
                choose_discount_type: 'İndirim Türü Seçiniz',
                send_code: 'KODU GÖNDER',
                discount_success_message: 'Kod başarıyla gönderildi.',
                //first registiration
                registiration_title: 'Paket Seçim İşlemleri',
                discount_campaign_apply: 'İndirim veya Kampanya Uygula',
                choose_discount: 'İndirim Seçiniz',
                choose_special_campaign: 'Özel Kampanya Seçiniz',
                no_call_right: 'Aranmadım Hakkı',
                freeze_lesson_right: 'Ders Dondurma Hakkı',
                max_payment_option_count: 'Maksimum Taksit Sayısı',
                discount_price: 'İndirimsiz Tutar',
                no_discount_price: "İndirimli Tutar (KDV'siz)",
                kdv: 'KDV',
                discount: 'indirim',
                credit_card: 'Kredi Kartı',
                payment_operations: 'Ödeme İşlemleri',
                transfer: 'Havale',
                card_name: 'Kart Üzerindeki İsim',
                card_number: 'Kart Numarası',
                card_expiration_date: 'Son Kullanma Tarihi',
                card_security_code: 'Güvenlik Kodu',
                bank_options: 'Banka Seçenekleri',
                choose_company: 'Şirket Seçiniz',
                choose_bank: 'Banka Seçiniz',
                kdv_include: 'KDV dahildir',
                cvv_tooltip: 'Kartın arkasındaki son 3 rakam',
                payment_options: 'Taksit Seçenekleri',
                payment_option: 'Taksit',
                one_shot: 'Tek Çekim',
                complete_payment: 'Ödemeyi Tamamla',
                day_year: 'GG/YY',
                recurring_payment: 'Tekrarlı Ödeme',
                shopping_credit: 'Alışveriş Kredisi',
                complete_order: 'Siparişi Tamamla',
                term_operations: 'Dönem İşlemleri',
                want_corporate_payment: 'Kurumsal ödeme yapmak istiyorum',
                order_success: 'Ödeme işlemi başarıyla gerçekleştirilmiştir. Lütfen kalan işlemleri gerçekleştiriniz.',
                add_term_success: 'Dönem başarıyla oluşturuldu.',
                registration_success: 'Tüm işlemler başarıyla tamamlandı.',
                total_lesson: "Toplam #count# Ders",
                total_lesson_count_gift: "#count# Ders (+#giftCount# Ders Hediye)",
                total_lesson_count: "Toplam Ders Sayısı",
                per_lesson_price_without_discount: "İndirimsiz Ders Başı Ücret",
                per_lesson_price: "İndirimli Ders Başı Ücret",
                max_installment_title: "#price# X #maxInstallment# Aya Varan Taksitlerle",
                order_failed: "Ödeme başarıyla gerçekleştirildi fakat sistem tarafından beklenmedik bir hata oluştu. Lütfen teknik ekip ile iletişime geçiniz.",
                secure_payment_loading_text: "Ödeme gerçekleştiriliyor. Lütfen sayfayı yenilemeyiniz",
                turkish_lira_price: "Türk Lirası Fiyatı"
            },
            accounting: {
                //payments
                edit_payment: 'Ödeme Düzenleme',
                payment_approve_status: 'Ödeme Onay Durumu',
                user_do_task: 'Üye',
                choose_payment_type: 'Ödeme Türü Seçiniz',
                choose_user_do_task: 'İşlemi Gerçekleştiren Kişi Seçiniz',
                choose_payment_option: 'Taksit Sayısı Seçiniz',
                payment_edit_success: 'Ödeme düzenleme işlemi başarıyla tamamlandı!',
                create_order: 'Sipariş Oluştur',
                order_amount: 'Sipariş Tutarı',
                successfull_payment: 'Başarılı Ödeme',
                unsuccessfull_payment: 'Başarısız Ödeme',
                payment_cancelled: 'Ödeme iptal edildi.',
                successfull_payment_amount: 'Başarılı Ödeme Tutarı',
                not_approved_payment_amount: 'Onaylanmayan Ödeme Tutarı',
                source: 'Kaynak',
                reference_payment: 'Referans Ödemesi',
                add_order: 'Sipariş Oluştur',
                order_number: 'Sipariş Numarası',
                discount_price: 'İndirimli Tutar',
                general_amount: 'Genel Tutar',
                amount_note: '(Kuruşları ayırmak için nokta (.) kullanınız. 160.24 gibi)',
                underpayment_amount: 'Eksik Ödeme Tutarı',
                order_completed: 'Sipariş Tamamlandı',
                add_payment: 'Ödeme Ekle',
                add_payment_title: 'Ödeme Ekleme',
                add_invoice: 'Fatura Ekle',
                create_order_success_message: 'Sipariş oluşturma işlemi başarıyla tamamlandı!',
                max_amount: 'Girilebilecek En Fazla Tutar',
                partial_payment: 'Parçalı Ödeme Mi?',
                payment_channel: 'Ödeme Nereden Yapıldı?',
                bank_message: 'Banka Mesajı (Varsa)',
                choose_channel: 'Kanal Seçiniz',
                payment_approved: 'Ödeme Onaylandı',
                order_approve_success: 'Sipariş başarıyla tamamlandı!',
                approve_order_error: 'Tüm Ödemeler Onaylanmadı',
                add_payment_success: 'Ödeme ekleme işlemi başarıyla tamamlandı!',
                approve_order_question: 'Siparişi tamamlamak istiyor musunuz?',
                approve_payment_question: 'Ödemeyi onaylamak istiyor musunuz?',
                delete_payment_question: 'Ödemeyi silmek istiyor musunuz?',
                undo_delete_question: 'Silme işlemini geri almak istiyor musunuz?',
                delete_payment_success: 'Ödeme silme işlemi başarıyla tamamlandı!',
                undo_delete_success: 'Silme işlemi başarıyla geri alındı!',
                approve_payment_success: 'Ödeme başarıyla onaylandı!',
                undo_delete_error: 'Başarılı ödeme tutarı sipariş tutarını aşamaz!',
                //current card
                create_current_card: 'Cari Kart Oluştur',
                edit_current_card: 'Cari Kart Güncelle',
                choose_invoice_type: 'Fatura Türü Seçiniz',
                choose_previous_current_card: 'Önceki Bilgilerden Seçebilirsiniz',
                add_new_current_card: 'Yeni Bilgi Eklemek İstiyorum',
                create_current_card_success: 'Cari kart oluşturma işlemi başarıyla tamamlandı!',
                update_current_card_success: 'Cari kart güncelleme işlemi başarıyla tamamlandı!',
                current_card_delete_question: 'Cari kartı silmek istediğinizden emin misiniz?',
                current_card_deleted: 'Cari kart başarıyla silindi!',
                //invoices
                invoice_preview: 'Fatura Önizleme',
                invoice_delete_question: 'Faturayı silmek istediğinizden emin misiniz?',
                invoice_deleted: 'Fatura başarıyla silindi!',
                invoice_note: 'Fatura Notu',
                goods_service_total_amount: 'Mal Hizmet Toplam Tutarı',
                calculated_kdv_real: 'Hesaplanan KDV Gerçek',
                tax_includes_total_amount: 'Vergiler Dahil Toplam Tutar',
                edit_invoice: 'Fatura Düzenleme',
                edit_invoice_success: 'Fatura düzenleme işlemi başarıyla tamamlandı!',
                choose_current_card: 'Cari Kart Seçiniz',
                current_card_type: 'Cari Kart Tipi',
                add_invoice_success_message: 'Fatura başarıyla oluşturuldu!',
                edit_invoice_success_message: 'Fatura başarıyla güncellendi!',
                current_card_area: 'Cari kart seçildiğinde bu alan dolacaktır.',
                press_button_for_add_row: 'Satır Eklemek İçin Butona Basınız',
                add_new_row: 'Yeni Satır Ekle',
                invoice_description: 'Fatura Açıklaması',
                add_invoice_description: 'Fatura Açıklaması Ekle',
                person: "Şahıs",
                company: "Firma",
                is_abroad: "Yurtdışı mükellefi",
                edit_payment_date: "Ödeme Tarihi",
                edit_payment_time: "Ödeme Zamanı",
                payment_date_edited_successfully: "Ödeme tarihi başarıyla güncellendi!"
            }
        },
        candidate_pool: {
            whatsapp: "WhatsApp’dan Dönüş",
            redirect_member_detail: "Öğrencinin detay sayfasına yönlendiriliyorsunuz.",
            transfer_to_candidate_confirmation: "Öğrenci Adaylarına taşımak istiyor musunuz?",
            all: "Tümü",
            score: {
                page_title: "Skorlar",
                td_title1: "Log Adı",
                td_title2: "İşlemi Gerçekleştiren",
                td_title3: "Skor",
                td_title4: "İşlem Tarihi",
            },
            log: {
                page_title: "Log / Kayıtlar",
                td_title1: "Satış Temsilcisi",
                td_title2: "Havuza Bırakma Nedeni",
                td_title3: "Havuza Bırakma Notu",
                td_title4: "İşlem Tarihi",
            }
        }
    },
    teacher: {
        teacher_title: 'Eğitmen',
        teacher_list: {
            search_text: 'Eğitmen aramak için eğitmen ismi giriniz',
            detail: 'Detay',
            education_program: 'Eğitim Programı',
            start_date_upper: 'BAŞLANGIÇ TARİHİ',
            end_date_upper: 'ÇIKIŞ TARİHİ',
            move_students: 'Öğrencileri Taşı',
        },
        teacher_program: {
            lesson_text: '<span class=\"text-blue\">Ders</span>',
            empty_text: '<span class=\"text-base-text\">Boş</span> ',
            blocked_text: '<span class=\"text-warning\">Bloke</span>',
            holiday_text: '<span class=\"text-[#6861ce]\">Tatil</span>',
            temporary_blocked_text: '<span class=\"text-red\">Geçiçi Bloke</span>',
            missed_call_text: '<span class=\"text-danger\">Dersini Kaçırmış</span>',
            teacher_absent_list: "Eğitmen Devamsızlıkları",
            teacher_absent_not_found: "Eğitmen Devamsızlığı Bulunamadı",
            missed_lesson: 'Dersi Kaçırdı',
            postponed: 'Erteledi',
            dont_call: 'Aranmadım',
            lesson_blocked: 'Eğitmenin bu saat aralığı siz kaldırana kadar bloke edilecektir.',
            lesson_temprorary_blocked: 'Eğitmenin bu saat aralığı sadece #lessonDay# tarihine kadar bloke edilecektir.',
            blocked: 'Bloke',
            temporary_blocked: 'Geçiçi Bloke',
            blocked_temprorary_blocked: 'Bloke / Geçiçi Bloke Et',
            weekly_lesson_count: 'Haftalık Ders Sayısı',
            student_no: 'Öğrenci Numarası',
            lesson_time: 'Ders Süresi',
            lesson_hour: 'Ders Saati',
            remaining_lesson_count: 'Kalan Ders Sayısı',
            select_teacher_to_see: 'Eğitmen Programını Görüntülemek İçin Bir Eğitmen Seçiniz',
            select_teacher_to_see_absent: 'Eğitmen Devamsızlıklarını Görüntülemek İçin Bir Eğitmen Seçiniz',
            shift_not_found: 'Eğitmen Çalışma Saati Bulunamadı',
            days: {
                monday: 'Pazartesi',
                tuesday: 'Salı',
                wednesday: 'Çarşamba',
                thursday: 'Perşembe',
                friday: 'Cuma',
                saturday: 'Cumartesi',
                sunday: 'Pazar'
            }
        },
        add_break: {
            block_success_message: 'Çalışma saati başarıyla bloke edildi!',
            temporary_block_success_message: 'Çalışma saati başarıyla geçiçi olarak bloke edildi!',
            block_delete_confirm_question: 'Blokeyi silmek istediğinize emin misiniz?',
            temporary_block_delete_confirm_question: 'Geçiçi blokeyi silmek istediğinize emin misiniz?',
            block_delete_success_message: 'Bloke başarıyla silindi!',
            temporary_block_delete_success_message: 'Geçiçi bloke başarıyla silindi!',
        },
        all_teacher_program: {
            select_time_range: "Zaman Aralığı Seçiniz",
            select_time_range_to_see: 'Tüm Eğitmen Programını Görüntülemek İçin Bir Zaman Aralığı Seçiniz',
            teacher_name: "Eğitmen Adı",
            has_lesson: "Dersi Var",
            no_lesson: "Dersi Yok",
            blocked: "Bloke"
        }
    },
    employee: {
        employee_list: {
            login: 'Hesaba Giriş',
            create_account: 'Öğrenci Hesabı Oluştur',
            detail: 'Detay',
            drop_member_pool: 'Adayları Havuza Bırak',
            distribute_students: 'Öğrencileri Dağıt',
            open_distribute: 'Dağıtıma Aç',
            close_distribute: 'Dağıtıma Kapat',
            sure: 'Emin misiniz?',
            confirm_modal_text: 'Bu kullanıcının öğrenci kaydını yapmak istiyor musunuz?',
            confirm_drop_pool: 'Adayları havuza bıraktığınızda kullanıcıdaki tüm adaylar silinecektir.',
            confirm_open_distribute: 'Dağıtıma açılan personele öğrenci atamaya başlayabilirsiniz.',
            confirm_close_distribute: 'Dağıtıma kapatılan personele öğrenci atayamazsınız.',
            change_distribute_success: 'Dağıtım durumu başarıyla değiştirildi',
            drop_pool_success: 'Havuza bırakma işlemi başarıyla tamamlandı',
            select_student: "Öğrenci Seçiniz",
            select_employee: "Kullanıcı Seçiniz",
            already_registerd_employee: 'Bu kullanıcı ile daha önce kayıt yapılmış!',
            employee_member_account_success: 'Kayıt işlemi başarıyla tamamlandı!',
            member_distribute_success: 'Öğrenci dağıtma işlemi başarıyla tamamlandı!',
            distributed_student_count: 'Dağıtılacak Öğrenci Sayısı',
            student_count_error: 'Dağıtılacak öğrenci sayısı sıfır olamaz.',
            employees_list_error: 'En az bir kullanıcı seçilmelidir.',
            push_employees_to_pool_error_message: "#employeeName# üzerinde herhangi bir aday bulunamadı!"
        },
        create_employee: {
            choose_status: 'Durum Seçiniz',
            choose_employee_type: 'Kullanıcı Türü Seçiniz',
            choose_team_leader: 'Takım Lideri Seçiniz',
            team_leaders: 'Tüm Takım Liderleri',
            corporate_mail_approve: 'E-Postaları kurumsal e-posta adresim ile almak istiyorum.',
            success: {
                title: "İşlem Başarılı",
                message: "Kullanıcı başarıyla oluşturulmuştur.",
                update_message: "Kullanıcı başarıyla güncellenmiştir.",
            },
        },
        detail: {
            employee_id: "EMPLOYEE ID",
            brand_upper: 'MARKA',
            position: 'POZİSYON',
            email: 'E-POSTA',
            corporate_phone: 'KURUMSAL TELEFON',
            timezone: 'SAAT DİLİMİ (DAKİKA)',
            corporate_email: 'KURUMSAL E-POSTA',
            start_date_upper: 'İŞE BAŞLANGIÇ TARİHİ',
            end_date: 'İŞTEN AYRILMA TARİHİ',
            last_update_date: 'EN SON GÜNCELLEME TARİHİ',
            manager: 'YÖNETİCİ',
            employee_details: 'Kullanıcı Detayları'
        },
        update_employee: {
            passive_error_message: "Kullanıcı üzerinde öğrenci olduğu için pasif olarak güncellenemedi!"
        }
    },
    report: {
        number_of_get_candidate: "Getirilen Aday Sayısı",
        number_of_pool_drop: "Havuza Bırakma Sayısı",
        total_candidate_count: "Toplam Aday Sayısı",
        reminder_count: "Hatırlatma Sayısı",
        salesman_reports: {
            all_candidates: "Tüm Adaylar",
            all_get_candidates: "Getirilen Tüm Adaylar",
            dropped_pool_candidates: "Havuza Bırakılan Adaylar",
            reminder_candidates: "Hatırlatma Kurulan Adaylar",
            total_data_count: "Toplam Veri Sayısı"
        },
        nps: {
            information_title: "NPS Hakkında Bilgi",
            information_one: "Net Promoter Score, müşteri sadakatini ölçmek için kullanılan bir göstergedir. 0 ile 100 arasında bir sayı ile ifade edilir.",
            information_two: "NPS, müşterilerin bir şirkete veya ürüne tavsiye etme olasılığını ölçmek için kullanılan tek bir soruya dayanır. Bu soru genellikle şu şekilde sorulur:",
            information_three: '"Bu şirketi/ürünü bir arkadaşınıza veya meslektaşınıza tavsiye etme olasılığınız nedir?"',
            information_four: "Müşteriler, bu soruya 0 ile 10 arasında bir sayı ile yanıt verir. 0 ile 6 arasında bir puan veren müşteriler \"detractor\" olarak adlandırılır. Bu müşteriler, şirketi veya ürünü başkalarına tavsiye etme olasılığı düşüktür. 7 ile 8 arasında bir puan veren müşteriler \"passive\" olarak adlandırılır. Bu müşteriler, şirketi veya ürünü başkalarına tavsiye etme olasılığı ortalamadır. 9 veya 10 arasında bir puan veren müşteriler ise \"promoter\" olarak adlandırılır. Bu müşteriler, şirketi veya ürünü başkalarına tavsiye etme olasılığı yüksektir.",
            information_five: "NPS, bir şirketin müşteri sadakatini ölçmek için oldukça etkili bir göstergedir. NPS yüksek olan şirketler, genellikle daha yüksek gelir ve müşteri tutma oranlarına sahiptir.",
            information_six: "Aşağıdaki raporda öğrencilerimizle gerçekleştirdiğimiz NPS anketinin sonuçlarını görebilirsiniz. Rapor size yüklenmiyorsa yetki almak için <i class='underline text-blue'>sates52@gmail.com</i> adresine e-posta gönderebilirsiniz.",
        },
        lesson_attend: {
            information_title: "Ders Katılım Raporu Hakkında Bilgi",
            information_one: "Öğrencilerimizin derse katılımlarını bu rapor ile takip edebilirsiniz. Erişim talep etmek için <i class='underline text-blue'>sates52@gmail.com</i> adresine e-posta gönderebilirsiniz."
        },
        target_analysis: {
            information_title: "Hedef Takip Analizi Hakkında Bilgi",
            information_one: "Şirketimizin hedef takip analizini bu rapor ile görüntüleyebilirsiniz. Erişim talep etmek için <i class='underline text-blue'>sates52@gmail.com</i> adresine e-posta gönderebilirsiniz."
        },
        call_report: {
            information_title: "Danışman Arama Raporu Hakkında Bilgi",
            information_one: "Şirketimizin danışman arama verilerini bu rapor ile görüntüleyebilirsiniz. Erişim talep etmek için <i class='underline text-blue'>sates52@gmail.com</i> adresine e-posta gönderebilirsiniz."
        },
        reference_dashboard: {
            information_title: "Referans Dashboard'u Hakkında Bilgi",
            information_one: "Referans akışımızla ilgili yeni bir dashboard oluşturduk.",
            information_two: "Bu dashboard içerisinde ilk olarak referans verme ekranına gelen kullanıcıların günlük sayılarını görüyoruz.",
            information_three: "İkinci grafik bu ekranda paylaşma butonuna tıklayan kullanıcıları gösteriyor.",
            information_four: "Üçüncü grafik ile referans linkleriyle internet sitemizi ziyaret eden kullanıcıları gösteriyor.",
            information_five: "Dördüncü grafikte referans linkliyle Konuşarak Öğren’e üye olan kullanıcıları görüyoruz.",
            information_six: "Beşinci grafik referans ile gerçekleşen satışları gösterecek. Veritabanı üzerinden bu verileri getireceğiz.",
            information_seven: "Altıncı grafikte ise seçtiğimiz zaman aralığında gerçekleşen referans satışlarının kaç tane referans veren öğrenci ile gerçekleştiğini göreceğiz. Böylece referans vermenin ilk adımından son adımına kadar tüm süreci görmüş olacağız.",
            information_eight: "Rapor size yüklenmiyorsa, erişim talep etmek için <i class='underline text-blue'>sates52@gmail.com</i> adresine e-posta gönderebilirsiniz."
        },
        inactive_reports: {
            information_title: "Inaktif Raporlar Hakkında Bilgi",
            information_one: "Rapor size yüklenmiyorsa, erişim talep etmek için <i class='underline text-blue'>sates52@gmail.com</i> adresine e-posta gönderebilirsiniz."
        },
        market_reports: {
            information_title: "Market Raporları (Aktif Öğrenci) Hakkında Bilgi",
            information_one: "Rapor size yüklenmiyorsa, erişim talep etmek için <i class='underline text-blue'>sates52@gmail.com</i> adresine e-posta gönderebilirsiniz."
        },
        demo_dashboard: {
            information_title: "Demo Dashboard Hakkında Bilgi",
            information_one: "Dashboard size yüklenmiyorsa, erişim talep etmek için <i class='underline text-blue'>sates52@gmail.com</i> adresine e-posta gönderebilirsiniz."
        },
        panel_events: {
            information_title: "ClickIVO Prime Yönetim Paneli Eventleri Hakkında Bilgi",
            information_one: "Yönetim Paneli Eventleri raporu size yüklenmiyorsa, erişim talep etmek için <i class='underline text-blue'>sates52@gmail.com</i> adresine e-posta gönderebilirsiniz."
        },
        candidate_reports: {
            returned_from_whatsapp_error_messages: {
                member_not_found: "Kullanıcı bulunamadı!",
                employee_not_found: "Çalışan bilgisi bulunamadı!",
                employee_status_must_be_active: "Çalışan hesabı pasif durumda!",
                salesperson_not_assigned: "Limit veya dağıtım durumu sebebiyle satışçı ataması yapılamadı!"
            }
        }
    },
    ticket: {
        detail: {
            sure: "İşlemene onay vermeniz durumunda talep cevabı iletilecektir.",
            select_quick_answer: "Hızlı cevap seçiniz",
            update_success: "Cevabınız başarıyla iletilmiştir.",
            read_answer: "Cevap aşağıdaki tarihte okunmuştur.",
            search_quick_answer: 'Hızlı Cevap Ara',
            edit_ticket_answer: "Talep Cevabını Düzenle"
        },
        ai_answer: {
            prompt_title: "Talep Cevabı İçin Yapay Zeka Desteği",
            button_text: "Yapay Zeka Desteği Al",
            prompt_placeholder: "Prompt Girin (Talep otomatik olarak prompt ile birlikte gönderilecektir)",
            prompt_null_error: "Prompt alanı boş bırakılamaz!"
        }
    },
    accounting: {
        current_card: {
            show_invoice: 'Faturayı Görüntüle',
            edit_success_message: 'Cari kart düzenleme işlemi başarıyla tamamlandı!',
            no_invoice_data: 'Fatura verisi bulunamadı',
            invoice_information: 'Fatura Bilgileri',
            person_upper: 'ŞAHIS',
            corpotate_upper: 'KURUMSAL',
            firm_upper: 'FİRMA',
            total_invoice_price: 'Toplam Fatura Tutarı'
        },
        campaign: {
            campaign_status_active: 'Kampanya aktif duruma getirildi!',
            campaign_status_passive: 'Kampanya pasif duruma getirildi!',
            discount_upper: 'İNDİRİM',
            valid_between: '#beginDate# - #endDate# tarihleri arasında geçerlidir.',
            change_campaing_status_question: 'Kampanya durumunu değiştirmek istiyor musunuz?'
        },
        payment: {
            payment_success_count: '<span class=\"font-bold text-green\">#count#</span> ödeme başarıyla gerçekleşti',
            payment_error_count: '<span class=\"font-bold text-red\">#count#</span> ödeme başarısız oldu',
        },
        invoice: {
            invoice_success_count: '<span class=\"font-bold text-green\">#count#</span> fatura onaylandı',
            invoice_error_count: '<span class=\"font-bold text-red\">#count#</span> fatura onaylanmadı',
            invoice_approved_alert: 'Fatura başarıyla onaylandı!',
            invoice_canceled_alert: 'Fatura onayı başarıyla kaldırıldı!',
            invoice_approved: 'Fatura Onaylandı',
            invoice_canceled: 'Fatura Onaylanmadı',
            net_amount_upper: 'NET TUTAR'
        },
        add_campaign: {
            campaign_informations: 'Kampanya Bilgileri',
            campaign_title: 'Kampanya Başlığı',
            campaign_type: 'Kampanya Tipi',
            campaign_types: 'Tüm Kampanya Tipleri',
            choose_start_end_date: 'Başlangıç ve Bitiş Tarihi Seçiniz',
            limited_member: 'Sınırlı Sayıda Üye',
            selected_packages: 'Seçili Paket(ler) İçin Uygulanacak İndirim ve Ek Haklar',
            additional_lesson_count: 'Ek Ders Sayısı',
            postpone_lesson: 'Ders Erteleme',
            missed_call: 'Dersimi Kaçırdım',
            discount_rate: 'İndirim Oranı',
            discount_rate_tooltip_1: 'İndirim oranı paketlerin indirimsiz fiyatına uygulanacaktır.',
            discount_rate_tooltip_2: 'Ek haklar paket içeriklerine ek olarak verilecektir.',
            gift_package: 'Hediye Paket',
            gift_package_discount_rate: 'Hediye Paket İndirim Oranı',
            member_count: 'Üye Miktarı',
            success_message: 'Kampanya başarıyla oluşturuldu!',
            add_campaign_question: 'Tüm bilgileri doldurdunuz. İşlemi tamamlamak için kampanya oluştur butonuna tıklayınız!',
            campaign_code_exist: 'Bu kampanya kodu sistemde mevcut!',
            market_detail_type: "Market Tipi",
            choose_market_detail_type: "Market Tipi Seçiniz"
        },
        discount_detail: {
            discount_informations: 'İndirim Bilgileri',
            discount_title: 'İndirim Başlığı',
            discount_type: 'İndirim Tipi',
            discount_code: 'İndirim Kodu',
        },
        campaign_detail: {
            start_end_dates: 'Başlangıç ve Bitiş Tarihleri',
            channels: 'Seçili Kanallar',
            member_types: 'Seçili Üye Tipleri',
            members: 'Seçili Üyeler',
            types: 'Seçili Bölümler',
            sales_types: 'Seçili Satış Tipleri',
            banks: 'Seçili Bankalar',
            payment_options: 'Seçili Taksit Seçenekleri',
            discounts: 'Seçili İndirimler',
            gift_exercise: 'Seçili Hediye Alıştırmalar',
            gift_packages: 'Seçili Hediye Paketler',
        },
        discount_code: {
            'not_used': 'KULLANILMADI',
            'used': 'KULLANILDI',
            change_discount_status_question: 'İndirim durumunu değiştirmek istiyor musunuz?',
            discount_status_active: 'İndirim aktif duruma getirildi!',
            discount_status_passive: 'İndirim pasif duruma getirildi!'
        }
    },
    task: {
        task_subjects: 'Tüm Görev Konuları',
        search_task_subject: 'Görev Konusu Ara',
        task_status: 'Tüm Görev Durumları',
    },
    use_case: {
        create: {
            prompt_tooltip_text: "Endpoint'ten cevap alınacaksa propmt metni girerken #endpoint# kullanınız.",
            success_message: "Kullanım alanı başarıyla oluşturuldu!",
        },
        list: {
            change_use_case_status_question: "Kullanım alanı durumunu değiştirmek istiyor musunuz?",
            use_case_status_active: 'Kullanım alanı aktif duruma getirildi!',
            use_case_status_passive: 'Kullanım alanı pasif duruma getirildi!'
        },
        update: {
            title: "AI Kullanım Alanını Güncelle",
            add_new_translation: "Yeni Çeviri Ekle",
            add_new_application: "Yeni Uygulama Ekle",
            at_least_one_application: "Son Uygulama Silinemez",
            translation_not_found: "Başlık Çevirisi Bulunamadı",
            success_message: "Kullanım alanı başarıyla güncellendi!"
        }
    },
    history: {
        list: {
            search_placeholder: "Kullanım geçmiş bilgisi aramak için öğrenci id(memberId) veya öğrenci adı giriniz"
        },
        detail: {
            title: "Kullanım Geçmişi Detay",
            assistant: "Asistan",
            system: "Sistem"
        }
    }
}