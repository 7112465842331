import React, { useEffect, useState } from 'react';
import { Loading, PageTitle, SearchBar, SearchCount, showTopAlert } from '../../components';
import NotAuthorized from '../../components/NotAuthorized';
import Pagination from '../../components/Pagination';
import { stringToDate } from '../../components/Utility';
import useAuth from '../../hooks/Log/useAuth';
import { useLocalization } from '../../hooks/Utils/useLocalization';
import { CampaignFilter } from '../../services/Accounting/CampaignService';
import { CampaignDetailSearch, CampaignTable } from './components';
import {EmployeeStatusType} from "../../consts/Constants";
import {getButtonPermission} from "../../rights/Utility";

const Campaign = () => {

    const strings = useLocalization();

    const { employeeTypeId, employee, getPermission, checkPermission } = useAuth();
    const roles = getPermission();
    const permission = checkPermission(roles, employeeTypeId);
    const adminRoles = getButtonPermission({ group: "admin", name: "adminRole" });
    const hasAdminRole = checkPermission(adminRoles, employeeTypeId);

    const limit = parseInt(process.env.REACT_APP_TABLE_ROW_LIMIT);
    const [start, setStart] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecord, setTotalRecord] = useState(0);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    const [detailSearch, setDetailSearch] = useState({
        validBeginDate: null,
        validEndDate: null,
        creationBeginDate: null,
        creationEndDate: null,
        brandId: hasAdminRole ? -1 : +employee?.BrandId,
        status: -1,
        discountBeginRate: 0,
        discountEndRate: 0,
        searchText: ''
    });

    //Arama verileri değiştiğinde değerleri sıfırlıyoruz
    const resetValue = () => {
        setStart(start => 0);
        setCurrentPage(currentPage => 1);
        setLoading(loading => true);
    }

    //select değişiklikleri bu alandan yönetiliyor
    const changeDetail = (values) => {
        setDetailSearch({ ...detailSearch, ...values });
        resetValue();
    }

    //input değişiklikleri bu alandan yönetiliyor
    const changeValue = (value) => {
        setDetailSearch({ ...detailSearch, searchText: value });
        resetValue();
    }

    const getCampaign = async () => {
        const model = {
            start: start,
            length: limit,
            startDate: detailSearch.validBeginDate ? stringToDate(detailSearch.validBeginDate) : null,
            endDate: detailSearch.validEndDate ? stringToDate(detailSearch.validEndDate, 23, 59, 59) : null,
            creationBeginDate: detailSearch.creationBeginDate ? stringToDate(detailSearch.creationBeginDate) : null,
            creationEndDate: detailSearch.creationEndDate ? stringToDate(detailSearch.creationEndDate, 23, 59, 59) : null,
            brandId: detailSearch.brandId,
            status: detailSearch.status,
            beginDiscountRate: detailSearch.discountBeginRate,
            endDiscountRate: detailSearch.discountEndRate,
            campaignCode: detailSearch.searchText.length !== 0 ? detailSearch.searchText : null
        }

        const result = await CampaignFilter(model);

        if(result.status === 200){
            let resultContent = JSON.parse(result.content);

            setData(data => resultContent.data);
            setTotalRecord(totalRecord => resultContent.recordsTotal);
            setLoading(loading => false);
        } else {
            showTopAlert(strings.errors.an_error_occurred, "error");
        }
    }

    //sayfa değiştikçe bilgileri yeniden çağırıyoruz
    useEffect(() => {
        if (totalRecord !== 0) {
            getCampaign();
        }
    }, [start])

    useEffect(() => {

        let abortController = new AbortController();

        // Kullanıcının sayfa görüntüleme izni yoksa servise gitmeyip performans kaybını önlüyoruz
        permission && getCampaign();

        return () => {
            abortController.abort();
        }
    }, [detailSearch, permission])

    return (
        <>
            {permission && (
                <>
                    <CampaignDetailSearch
                        changeDetail={changeDetail}
                        loading={loading}
                        hasAdminRole={hasAdminRole}
                    />
                    <SearchBar
                        changeValue={changeValue}
                        placeHolder={strings.search_bar.search_campaign}
                        isDisabled={loading}
                    />
                    {!loading && <SearchCount count={totalRecord} />}
                </>
            )}
            <PageTitle title={strings.sidebar.accounting.campaigns} />
            {permission ? (
                loading ?
                (
                    <Loading />
                )
                :
                (
                    <>
                        <CampaignTable data={data} />
                        <Pagination
                            totalCount={totalRecord}
                            limit={limit}
                            start={start}
                            setStart={setStart}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            setLoading={setLoading}
                            loadScreen={true}
                        />
                    </>
                )
            ) : (<NotAuthorized />)}


        </>
    )
}

export default Campaign
