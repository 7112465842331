import React, { useState, useEffect } from "react";
import { Button, LoadingSpinner, showTopAlert } from "../../../components";
import { useLocalization } from "../../../hooks/Utils/useLocalization";
import { UpdateAnswer } from "../../../services/Ticket/TicketService";
import { Link } from "react-router-dom";
import { url } from "../../../routes/utility";

const TicketAiAnswerModal = ({ 
    aiAnswer, 
    prompt, 
    setPrompt, 
    handleGetAiAnswer, 
    aiLoading, 
    promptError,
    ticketMessage,
    onClose,
    ticketId,
    employeeId,
    refreshTicket,
    member
}) => {
    const strings = useLocalization();
    const [editableAnswer, setEditableAnswer] = useState(`Değerli öğrencimiz, ${aiAnswer}`);
    const [sending, setSending] = useState(false);
    const [localPromptError, setLocalPromptError] = useState('');

    useEffect(() => {
        setEditableAnswer(`Değerli öğrencimiz, ${aiAnswer}`);
    }, [aiAnswer]);

    const handleSendAnswer = async () => {
        setSending(true);
        const model = {
            ticketId: ticketId,
            answer: editableAnswer,
            answeredUser: employeeId
        };

        const result = await UpdateAnswer(model);
        if (result.status === 200) {
            showTopAlert(strings.ticket.detail.update_success, "success");
            refreshTicket();
            onClose();
        } else {
            showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
        }
        setSending(false);
    };

    const handleLocalGetAiAnswer = () => {
        if (!prompt.trim()) {
            setLocalPromptError(strings.ticket.ai_answer.prompt_null_error);
            return;
        }
        setLocalPromptError('');
        handleGetAiAnswer();
    };

    return (
        <div className="flex flex-col gap-3 p-3 sm:p-5 h-full">
            <div className="flex flex-col lg:flex-row gap-4 flex-1 min-h-0">
                {/* Sol Taraf - AI Cevabı */}
                <div className="lg:w-[55%] flex flex-col gap-3 min-h-0">
                    <p className="font-semibold text-[14px]">{strings.ticket.detail.edit_ticket_answer}</p>       
                    <div className="flex-1 min-h-0">
                        <textarea
                            className="w-full h-full border border-input-border focus:border-blue/30 focus:bg-[#fafafa] rounded-lg px-4 py-2 outline-none text-sm transition-colors"
                            value={editableAnswer}
                            rows="25"
                            onChange={(e) => setEditableAnswer(e.target.value)}
                        />
                    </div>
                    <div className="flex items-center justify-between">
                        <p className="font-bold text-[14px]">
                            {strings.ticket.ai_answer.result}
                        </p>
                        <Button
                            classnames="button search-button text-white bg-blue border-blue px-5"
                            icon={sending ? null : "send"}
                            text={sending ? <LoadingSpinner /> : null}
                            action={handleSendAnswer}
                            disabled={sending}
                        />
                    </div>
                </div>

                {/* Sağ Taraf - Talep ve Prompt */}
                <div className="lg:w-[45%] flex flex-col gap-3">
                    <p className="font-semibold text-[14px]">{strings.ticket.ai_answer.prompt_title}</p>    
                    {/* Ticket mesajı için yeni tasarım */}
                    <div className="flex flex-col gap-2">
                        <p className="font-semibold text-[14px]">{strings.ticket.detail.request}</p>
                        <div className="bg-card rounded-lg border border-input-border">
                            <div className="p-3 border-b border-input-border bg-[#fafafa]">
                                <p className="text-sm font-medium text-gray-600">
                                    {member && (
                                        <Link
                                            to={url("member.detail.profile", { memberId: member.id })}
                                            target="_blank"
                                            state={{ id: member.id, status: member.status }}
                                            className="font-bold text-blue hover:underline focus:underline min-w-max"
                                        >
                                            {member.fullName}
                                        </Link>
                                    )}
                                </p>
                            </div>
                            <div className="p-4 max-h-[300px] overflow-y-auto">
                                <p className="text-sm text-base-text whitespace-pre-line">
                                    {ticketMessage}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col gap-3">
                        <textarea 
                            rows="6" 
                            className="w-full border border-input-border focus:border-blue/30 focus:bg-[#fafafa] rounded-lg px-4 py-2 outline-none text-sm transition-colors"
                            placeholder={strings.ticket.ai_answer.prompt_placeholder}
                            value={prompt} 
                            onChange={(e) => setPrompt(e.target.value)}
                        />
                        {localPromptError && (
                            <span className="text-danger field-validation-valid">
                                {localPromptError}
                            </span>
                        )}
                        <button
                            type="button"
                            className="button search-button text-white bg-green hover:bg-green/90 border-green focus:!border-green/70 px-5 h-[44px] rounded-full transition-colors disabled:opacity-50"
                            onClick={handleLocalGetAiAnswer}
                            disabled={aiLoading}
                        >
                            <div className="flex items-center justify-center gap-2">
                                {aiLoading ? (
                                    <LoadingSpinner />
                                ) : (
                                    <>
                                        <span className="material-symbols-outlined">
                                            smart_toy
                                        </span>
                                        <span>{strings.ticket.ai_answer.button_text}</span>
                                    </>
                                )}
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TicketAiAnswerModal;