import { PageTitle } from "../../components";
import { useLocalization } from "../../hooks/Utils/useLocalization";
import React from "react";
import useAuth from '../../hooks/Log/useAuth';
import NotAuthorized from "../../components/NotAuthorized";

const PhoneNumbersReport = () => {

    const strings = useLocalization();
    const { checkPermission, getPermission, employeeTypeId } = useAuth();
    const roles = getPermission();
    const permisson = checkPermission(roles, employeeTypeId);

    return (
        <>
            <PageTitle title={strings.sidebar.product_report.product} />

            {permisson ? (
                <div className="flex justify-center mt-5">
                    <iframe title="product" width="1725" height="1725" src="https://lookerstudio.google.com/embed/reporting/81e53584-e238-481b-8d13-8c01f42b0066/page/p_umvnudp1id" frameBorder="0" allowFullScreen></iframe>
                </div>
            ) : (<NotAuthorized />)}

        </>
    )
}

export default PhoneNumbersReport;