import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Loading, MemberInformations, Modal, PageTitle, SearchCount } from "../../../../../components";
import { getDateWithDayDifference } from "../../../../../components/Utility";
import { Brands } from "../../../../../consts/Constants";
import useAuth from "../../../../../hooks/Log/useAuth";
import useMemberBrand from "../../../../../hooks/Member/useMemberBrand";
import useMemberPhoneNumber from "../../../../../hooks/Member/useMemberPhoneNumber";
import { useLocalization } from "../../../../../hooks/Utils/useLocalization";
import { url } from "../../../../../routes/utility";
import { GetEmployeeCallRecords } from "../../../../../services/Employee/EmployeeService";
import { GetRecordingPath } from "../../../../../services/Esneks/EsneksService";
import CallRecordsDetailSearch from "./CallRecordsDetailSearch";
import CallRecordsTable from "./CallRecordsTable";
import AnalyzeTranscribeModal from "./components/AnalyzeTranscribeModal";
import ReadTranscribeModal from "./components/ReadTranscribeModal";
import classNames from "classnames";
import { getButtonPermission } from "../../../../../rights/Utility";

const CallRecords = () => {

    const strings = useLocalization();
    const navigate = useNavigate();
    const { memberId } = useParams() || false;
    const { employee, employeeTypeId, checkPermission } = useAuth();
    const memberBrandId = useMemberBrand(memberId);
    const memberPhoneNumber = useMemberPhoneNumber(memberId);

    const analyzeTranscribeButtonRoles = getButtonPermission({ group: "memberDetailCallRecords", name: "analyzeTranscribe" });
    const analyzeTranscribePermission = checkPermission(analyzeTranscribeButtonRoles, employeeTypeId);

    const [loading, setLoading] = useState(true);
    const [totalRecord, setTotalRecord] = useState(0);
    const [data, setData] = useState([]);
    // Son 30 Gün Seçeneği Seçili getiriliyor
    const [detailSearch, setDetailSearch] = useState({
        beginDate: new Date(getDateWithDayDifference(new Date(), 30).setHours(0, 0, 0, 0)).toISOString().split('.')[0],
        endDate: new Date(new Date().setHours(23, 59, 59, 59)).toISOString().split('.')[0],
        corporateEmail: "",
        employeeId: ""
    });
    const [esneksRecordingPath, setEsneksRecordingPath] = useState("");
    const [modal, setModal] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [selectedRecords, setSelectedRecords] = useState([]);

    //dropdown değişiklikleri bu alandan yönetiliyor
    const changeDetail = (values) => {
        setDetailSearch({ ...detailSearch, ...values });
    };

    const openModal = (key, data) => {
        setModalContent({ key: key, data: data });
        setModal(true);
    }

    const closeModal = () => {
        setModalContent({});
        setModal(false);
    }

    //Öğreciye ait arama bilgileri getiriliyor.
    const getCallRecords = async (detailSearch, memberPhoneNumber) => {
        setLoading(true);

        const model = {                
            startDate: detailSearch.beginDate,
            endDate: detailSearch.endDate,
            agentUsername : detailSearch.corporateEmail ?? "",
            employeeId: detailSearch.employeeId ?? "",
            memberId: memberId,
            memberPhoneNumber : memberPhoneNumber,
            timeZone: +employee?.TimeZone
        };

        const result = await GetEmployeeCallRecords(model);

        if(result.status === 200){
            setData(result.content);
            setTotalRecord(result.content.length);
        }

        setLoading(false);
    }

    useEffect(() => {
        let abortController = new AbortController();

        if(memberPhoneNumber.length > 0)
            getCallRecords(detailSearch, memberPhoneNumber);

        return () => {
            abortController.abort();
        }

    }, [detailSearch, memberPhoneNumber])

    //KO olmayan markalardan sayfaya girmeye çalışan olursa sayfadan atıyoruz.
    useEffect(() => {
        if(memberBrandId !== -1 && memberBrandId !== Brands.KO)
            navigate(url("member.detail.profile", { memberId: memberId }));
    }, [memberBrandId]);

    useEffect(() => {
        GetRecordingPath()
            .then(result => {
                if(result.status === 200)
                    setEsneksRecordingPath(result.content);
                else {
                    const defaultBaseDir = "/var/lib/3cxpbx/Instance1/Data/Recordings/";
                    setEsneksRecordingPath(defaultBaseDir);
                } 
            })
            .catch()
    }, []);

    const handleRecordSelection = (recordInfo) => {
        setSelectedRecords(prevRecords => {
            if (recordInfo.isSelected) {
                // Kayıt seçildiyse ekle
                return [...prevRecords, {
                    id: recordInfo.id,
                    callerName: recordInfo.callerName,
                    callDate: recordInfo.callDate,
                    callDuration: recordInfo.callDuration
                }];
            } else {
                // Kayıt seçimi kaldırıldıysa çıkar
                return prevRecords.filter(record => record.id !== recordInfo.id);
            }
        });
    };

    return (
        <>
            <CallRecordsDetailSearch
                changeDetail={changeDetail}
                loading={loading}
            />

            <div className="overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
                <div className="inline-block min-w-full rounded-lg overflow-hidden py-6">
                    {!loading && <SearchCount count={totalRecord} />}

                    <PageTitle title={strings.member.member_detail.header.call_records} />

                    <div className="flex justify-between items-center">
                        <div className="max-w-max">
                            <MemberInformations memberId={memberId} />
                        </div>

                        <div className="group">
                            <button 
                                onClick={() => openModal("analyzeTranscribe", selectedRecords)}
                                disabled={selectedRecords.length === 0 || !analyzeTranscribePermission}
                                className={classNames("button search-button gap-3 text-white bg-blue border-blue px-10 relative",{
                                "opacity-50 cursor-not-allowed pointer-events-none": selectedRecords.length === 0 || !analyzeTranscribePermission
                                })}
                            >
                                {strings.member.member_detail.call_records.analyze}
                                {!analyzeTranscribePermission && 
                                <div className="tooltip-top">
                                    {strings.errors.not_authorized}
                                </div>
                                }
                            </button>
                        </div>
                    </div>

                    {loading && <Loading />}

                    {!loading && 
                        <CallRecordsTable 
                            data={data} 
                            esneksRecordingPath={esneksRecordingPath}
                            openModal={openModal}
                            onSelectRecord={handleRecordSelection}
                        />
                    }
                </div>
            </div>


            {(Object.keys(modalContent).length !== 0) && (
                <Modal modal={modal} setModal={setModal} classes={
                    {
                        modal: 
                            modalContent.key === "readTranscribe" ? 'h-[90vh] max-w-[800px]' 
                            : modalContent.key === "analyzeTranscribe" ? 'h-[90vh] max-w-[1440px]'
                            : ''
                    }}>
                    {modalContent.key === "readTranscribe" && <ReadTranscribeModal data={modalContent.data} />}
                    {modalContent.key === "analyzeTranscribe" && <AnalyzeTranscribeModal data={modalContent.data} />}
                </Modal>
            )}
        </>
    );
}


export default CallRecords;